/**
 * zustand 使用文档：https://github.com/pmndrs/zustand
 */
import { ApiGetUserInfo, ApiUserInfo } from 'api/user'
import { routeList, RouterItem } from 'router'
import { checkLogin, filterRouters } from 'utils'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

export interface workBeachState {
    userInfo: ApiUserInfo['return'] | null
    getUserInfo: () => void
    /** 当前帐号权限路由 */
    routers: Array<RouterItem>
}

const useStore = create<workBeachState, any>(
    devtools(
        set => ({
            userInfo: null,
            getUserInfo: () => {
                if (checkLogin()) {
                    /** 用户个人信息 */
                    ApiGetUserInfo().then(res => {
                        set({
                            userInfo: res,
                            routers: filterRouters(routeList, 'user')
                        })
                    })
                }
            },
            routers: []
        }),
        { anonymousActionType: 'useStore', enabled: import.meta.env.DEV }
    )
)

export default useStore
