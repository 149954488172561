import './global.scss'

import { NotFound } from 'components/NotFound/NotFound'
// import { NotFound } from 'components'
// import Captcha from 'components/Captcha/Captcha'
import { locale } from 'config'
import { useEffect } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { routeList } from 'router/index'
import { StoreProvider } from 'store/StoreProvider'
import { changeLanguage, pixelReport } from 'utils'
import { fixRem } from 'utils/rem'
import { renderRoutes } from 'utils/router'

const AppRoutes = () => {
    fixRem()

    useEffect(() => {
        changeLanguage(locale)
        pixelReport('ALL_PAGE')
    }, [window?.location?.pathname])

    return (
        <Router>
            <Routes>
                {renderRoutes(routeList)}
                <Route
                    path="/"
                    element={
                        <Navigate
                            to={locale}
                            replace
                        />
                    }
                />
                <Route
                    path={`/${locale}/n`}
                    element={
                        <Navigate
                            to={`/${locale}`}
                            replace
                        />
                    }
                />
                <Route
                    path="*"
                    element={<NotFound />}
                />
            </Routes>
            {/* <OpenApp /> */}
        </Router>
    )
}

const App = (): JSX.Element => {
    return (
        <StoreProvider>
            {/** 提前加载行为验证脚本 */}
            {/* <Captcha
                account=""
                callback={() => 0}
                disabled
            /> */}
            <AppRoutes />
        </StoreProvider>
    )
}

export default App
