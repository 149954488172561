import { useCallback } from 'react'
import create from 'zustand'

import i18n from '../locale/index'

export interface INameSpaceStore {
    namespaces: string[]
    setNamespaces: (namespaces: string[]) => void
}

export const useNameSpaceStore = create<INameSpaceStore>(set => ({
    namespaces: ['resource', 'errCode'],
    setNamespaces: (namespaces) => set({ namespaces })
}))

export const useNameSpaces = () => {
    const { namespaces, setNamespaces } = useNameSpaceStore()

    const loadNamespaces = useCallback((space: string | string[]) => {
        i18n.loadNamespaces(space).then(() => {
            if (Array.isArray(space)) {
                if (namespaces.length === space.length) {
                    setNamespaces(space)
                } else {
                    setNamespaces([...namespaces, ...space])
                }
            } else {
                setNamespaces([...namespaces, space])
            }
        })
    }, [setNamespaces])

    return {
        namespaces,
        loadNamespaces
    }
}
