import React from 'react'

const Navcoupon = /* #__PURE__ */ (function () {
    const component = function (props: any) {
        const { color, size, ...otherProps } = props
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="dui-icon"
                color={color}
                {...otherProps}
            >
                <g clipPath="url(#clip0_10168_58978)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.2744 20.25H22.1077C22.614 20.25 23.0244 19.8396 23.0244 19.3334V14.2917C21.7588 14.2917 20.7327 13.2657 20.7327 12C20.7327 10.7344 21.7588 9.70833 23.0244 9.70833V4.66667C23.0244 4.16041 22.614 3.75 22.1077 3.75H20.2744C20.7807 3.75 21.1911 4.16041 21.1911 4.66667V9.70833C19.9254 9.70833 18.8994 10.7344 18.8994 12C18.8994 13.2657 19.9254 14.2917 21.1911 14.2917V19.3334C21.1911 19.8396 20.7807 20.25 20.2744 20.25Z"
                        fill="#FF9000"
                    />
                    <path
                        d="M1.92114 9.70833V4.66667C1.92114 4.16041 2.33155 3.75 2.83781 3.75H19.3378C19.8441 3.75 20.2545 4.16041 20.2545 4.66667V9.70833C18.9889 9.70833 17.9628 10.7344 17.9628 12C17.9628 13.2657 18.9889 14.2917 20.2545 14.2917V19.3334C20.2545 19.8396 19.8441 20.25 19.3378 20.25H2.83781C2.33155 20.25 1.92114 19.8396 1.92114 19.3334V14.2917C3.18679 14.2917 4.21281 13.2657 4.21281 12C4.21281 10.7344 3.18679 9.70833 1.92114 9.70833Z"
                        fill="#3561FF"
                    />
                    <path
                        d="M8.33777 11.0834V9.25H13.8378V11.0834H8.33777Z"
                        fill="white"
                    />
                    <path
                        d="M8.33777 14.75V12.9167H13.8378V14.75H8.33777Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_10168_58978">
                        <rect
                            width="22"
                            height="22"
                            fill="white"
                            transform="translate(1 1)"
                        />
                    </clipPath>
                </defs>
            </svg>
        )
    }

    component.defaultProps = {
        color: '#0C0F0F',
        size: '24'
    }

    return component
})()

export default Navcoupon
