import { Checkbox, Popover } from '@douyinfe/semi-ui'
import { FC, useMemo } from 'react'
import { sprintf } from 'sprintf-js'

import styles from './AgreementBox.module.scss'

interface IAgreementBoxProps {
    checked?: boolean
    onChange?: (b: boolean) => any
}

const AgreementBox: FC<IAgreementBoxProps> = props => {
    // memo
    const termsOfService = useMemo(() => {
        const link = document.createElement('a')
        link.textContent = t('Terms of Service')
        link.classList.add(styles.consentAgreementBoxInfoBoxProtocolLink)
        link.setAttribute('href', 'https://parobit.zendesk.com/hc/en-us/articles/40534744821017--Contract-List-Terms-of-Use')
        link.setAttribute('target', '_blank')
        return link
    }, [styles.consentAgreementBoxInfoBoxProtocolLink])

    const privacyPolicy = useMemo(() => {
        const link = document.createElement('a')
        link.textContent = t('Privacy Policy')
        link.classList.add(styles.consentAgreementBoxInfoBoxProtocolLink)
        link.setAttribute('href', 'https://parobit.zendesk.com/hc/en-us/articles/40534829617177--Contract-List-Privacy-policy')
        link.setAttribute('target', '_blank')
        return link
    }, [styles.consentAgreementBoxInfoBoxProtocolLink])

    const riskWarning = useMemo(() => {
        const link = document.createElement('a')
        link.textContent = t('Risk warning')
        link.classList.add(styles.consentAgreementBoxInfoBoxProtocolLink)
        link.setAttribute('href', 'https://parobit.zendesk.com/hc/en-us/articles/40534892790681--Contract-List-Risk-warning')
        link.setAttribute('target', '_blank')
        return link
    }, [styles.consentAgreementBoxInfoBoxProtocolLink])

    const antiMoneyLaundering = useMemo(() => {
        const link = document.createElement('a')
        link.textContent = t('Anti Money Laundering')
        link.classList.add(styles.consentAgreementBoxInfoBoxProtocolLink)
        link.setAttribute('href', 'https://parobit.zendesk.com/hc/en-us/articles/40534931024281--Contract-List-Anti-Money-Laundering-Know-Your-Customer-AML-KYC-Policy')
        link.setAttribute('target', '_blank')
        return link
    }, [styles.consentAgreementBoxInfoBoxProtocolLink])

    const restrictedCountry = useMemo(() => {
        const span = document.createElement('span')
        span.textContent = t('these countries or regions')
        span.classList.add(styles.consentAgreementBoxInfoBoxCountry)
        return span
    }, [styles.consentAgreementBoxInfoBoxCountry])

    return (
        <div className={styles.consentAgreementBox}>
            <Checkbox
                className={styles.consentAgreementBoxCheckBox}
                checked={props.checked}
                onChange={e => props.onChange?.(Boolean(e.target.checked))}
            ></Checkbox>
            <div className={styles.consentAgreementBoxInfoBox}>
                <div dangerouslySetInnerHTML={{ __html: sprintf(t('I have read and agree to Parobit %s, %s,%s, %s ') /** customize */, termsOfService.outerHTML, privacyPolicy.outerHTML, riskWarning.outerHTML, antiMoneyLaundering.outerHTML) }}></div>
                <Popover
                    position={'top'}
                    content={
                        <div
                            style={{
                                width: '320px',
                                maxWidth: '80vw',
                                padding: '12px',
                                boxSizing: 'border-box'
                            }}
                        >
                            {t('NoServiceStateTip') /** Cuba/Democratic Republic of the Congo/Iran/Iraq/China/North Korea/Singapore/Sudan/Syria/United States. */}
                        </div>
                    }
                >
                    <div
                        style={{ marginTop: '4px' }}
                        dangerouslySetInnerHTML={{ __html: sprintf(t('userDeclare') /** I declare that I am not a citizen of %s. */, restrictedCountry.outerHTML) }}
                    ></div>
                </Popover>
            </div>
        </div>
    )
}

export default AgreementBox
