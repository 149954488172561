import { langVersion } from 'locale/langVersion'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export type LocaleType = `${string}-${string}`

export interface DynamicLangState {
    [key: LocaleType]: Record<string, string> | undefined;
    dynamicLangVersion: string;
    setDynamicLang: (locale: LocaleType, lang: Record<string, string>) => void;
    setDynamicLangVersion: (version: string) => void;
}

const dynamicLangStore = create<DynamicLangState, any>(
    devtools(
        persist(
            set => ({
                dynamicLangVersion: langVersion,
                setDynamicLang: (locale: LocaleType, lang: Record<string, string>) => {
                    set({
                        [locale]: lang
                    })
                },
                setDynamicLangVersion: (version: string) => {
                    set({
                        dynamicLangVersion: version
                    })
                }
            }),
            {
                name: 'dynamic-lang',
                getStorage: () => localStorage
            }
        )
    )
)

export default dynamicLangStore
