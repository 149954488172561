import { Button, Collapse, Divider, Icon } from '@douyinfe/semi-ui'
import { locale } from 'config'
import { useLanguageList } from 'hooks'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ArrowRight, Copy as IconCopy } from 'react-dui-icons'
import { useNavigate, useParams } from 'react-router-dom'
import useStore from 'store/user'
import { checkLogin, isPc, toLogout } from 'utils'
import { fixZendeskLang } from 'utils/zendesk'

import { LangList } from '../LangDropDown/LangList'
import { RateList } from '../LangDropDown/RateList'
import style from './HeaderMenuSide.module.scss'

interface IIconArrowRightProps {
    className?: string
    size: number
}
const IconArrowRight = ({ className, size = 20 }: IIconArrowRightProps) => {
    return (
        <div className={classNames(className)}>
            <Icon svg={<ArrowRight size={size} />} />
            {/* <Icon svg={<ArrowRight />} rotate={180} /> */}
        </div>
    )
}

const HeaderMenuSide = (_props: { navMenu?: any[] | undefined }): JSX.Element => {
    let timer: NodeJS.Timeout | undefined
    window.addEventListener('blur', () => {
        clearTimeout(timer)
    })

    const navigate = useNavigate()
    const hasLogin = checkLogin()
    const userInfo = useStore(state => state.userInfo)
    const getUserInfo = useStore(state => state.getUserInfo)
    // const [langList, setLangList] = useState<ApiLang['return']>(storage.getItem('langList', 'session', 'common') || [])
    // const [curName, setCurName] = useState('')
    // const [curFiat, setCurFiat] = useState<ApiRateList['return'][0]>({
    //     lang: '',
    //     abbr: '',
    //     name: '',
    //     symbol: '',
    //     rate: '',
    //     usdtRate: ''
    // })
    const [langIsShow, setLangIsShow] = useState(false)
    const [fiatIsShow, setFiatIsShow] = useState(false)
    const { coin, basecoin } = useParams<{ locale: string; coin: string; basecoin: string }>()
    const {
        // languageList,
        // rateList,
        currentFiat: curFiat,
        currentLanguage: curName
        // onFiatChange
    } = useLanguageList()
    const h5NavMenu = [
        {
            name: t('P2P'),
            to: `https://otc.parobit.com/${fixZendeskLang()}/otc/trade/buy-USDT`
        },
        {
            name: t('Market') /** 行情 */,
            to: `/${locale}/markets`
        },
        {
            name: t('Trade') /** 交易 */,
            to: !basecoin ? `/${locale}/trade/USDT/BTC` : `/${locale}/trade/${coin}/${basecoin}`
        },

        {
            name: t('Derivatives') /** 合约交易 */,
            to: `/${locale}/swap/BTCUSDTPERP`,
            isHot: true
        }
    ]
    const navMenu2 = [
        {
            name: t('Download'),
            to: `/${locale}/download`,
            extra: '',
            onClick: () => {
                window.location.href = `/${locale}/download`
            }
        },
        {
            name: t('Language'),
            to: '',
            isShowArrow: true,
            extra: curName?.name || '',
            onClick: () => {
                setLangIsShow(!langIsShow)
            }
        },
        {
            name: t('Currency'),
            to: '',
            isShowArrow: true,
            extra: curFiat?.abbr || '',
            onClick: () => {
                setFiatIsShow(!fiatIsShow)
            }
        }
    ]

    const showName = (): string => {
        return userInfo?.nickname || (userInfo?.phone ? userInfo.phone.substr(0, 3) + '****' + userInfo.phone.substr(-4) : userInfo?.email || 'undefined')
    }

    useEffect(() => {
        getUserInfo()
    }, [])

    const onCopy = () => {}

    // @ts-ignore
    return (
        <section className={style.root}>
            {langIsShow && <LangList />}
            {fiatIsShow && <RateList />}
            {!langIsShow && !fiatIsShow && hasLogin && (
                <div className={style.user}>
                    <div
                        className={style.name}
                        onClick={() => isPc() && navigate(`/${locale}/user`)}
                    >
                        {showName()}
                    </div>
                    <CopyToClipboard
                        onCopy={onCopy}
                        text={Cookies.get('m_showID')}
                        className={style.id}
                    >
                        <Button
                            theme="borderless"
                            size="small"
                        >
                            UID: {Cookies.get('m_showID') + ' '}
                            <span>
                                <IconCopy size={12} />
                            </span>
                        </Button>
                    </CopyToClipboard>
                </div>
            )}
            {!langIsShow && !fiatIsShow && !hasLogin && (
                <div className={style.buttonGroup}>
                    <Button
                        block
                        className={style.login}
                        onClick={() => navigate(`/${locale}/login?backUrl=${window.location.pathname}`)}
                    >
                        {t('Log in') /** 登录 */}
                    </Button>
                    <Button
                        theme="solid"
                        block
                        className={style.sign}
                        onClick={() => navigate(`/${locale}/register?backUrl=${window.location.pathname}`)}
                    >
                        {t('Sign up') /** 注册 */}
                    </Button>
                </div>
            )}
            {/* 渲染菜单 */}
            {!langIsShow && !fiatIsShow && (
                <div>
                    <Collapse
                        className={style.myCollapse}
                        accordion
                    >
                        {h5NavMenu?.map((item, index) => (
                            <Collapse.Panel
                                className={style.collapsePanel}
                                header={item.name}
                                showArrow={false}
                                itemKey={String(index)}
                                key={index}
                                // @ts-ignore
                                onClick={() => {
                                    window.location.href = item.to
                                }}
                            ></Collapse.Panel>
                        ))}
                    </Collapse>

                    <Divider margin="16px" />

                    <Collapse
                        className={style.myCollapse}
                        expandIcon={<IconArrowRight size={16} />}
                        accordion
                    >
                        {hasLogin && (
                            <Collapse.Panel
                                className={style.collapsePanel}
                                header={t('Balance')}
                                showArrow={false}
                                itemKey="balance"
                                // @ts-ignore
                                onClick={() => {
                                    navigate(`/${locale}/user/mybalance/spot`)
                                }}
                            ></Collapse.Panel>
                        )}
                        {navMenu2?.map((item, index) => (
                            <Collapse.Panel
                                className={style.collapsePanel}
                                header={item.name}
                                showArrow={!!item?.isShowArrow}
                                extra={<span className={style.extra}>{item?.extra + ''}</span>}
                                itemKey={String(index)}
                                key={index}
                                // @ts-ignore
                                onClick={item.onClick}
                            ></Collapse.Panel>
                        ))}
                    </Collapse>

                    {checkLogin() && (
                        <Button
                            theme="light"
                            type="primary"
                            onClick={() => {
                                toLogout()
                            }}
                            className={style.logout}
                        >
                            {t('Logout')}
                        </Button>
                    )}
                </div>
            )}
        </section>
    )
}

export default HeaderMenuSide
