import { Dropdown } from '@douyinfe/semi-ui'
import { DropdownProps } from '@douyinfe/semi-ui/lib/es/dropdown'
import { PopoverProps } from '@douyinfe/semi-ui/lib/es/popover'
import { ReactNode, useState } from 'react'

import style from './DropDownContainer.module.scss'

interface DropDownContainerProps extends DropdownProps {
    content: ReactNode
    children: ReactNode
    placement?: PopoverProps['position']
    onVisible?: Function
    trigger?: 'hover' | 'focus' | 'click' | 'custom'
}

/** 对 semi-ui [Dropdown 下拉框] 的二次封装  */
export const DropDownContainer = ({ content, children, placement = 'bottomLeft', onVisible, trigger = 'click', ...other }: DropDownContainerProps): JSX.Element => {
    const [show, setShow] = useState(false)

    const onVisibleChange = (visible: boolean) => {
        setShow(visible)
        onVisible && onVisible(visible)
    }

    return (
        <Dropdown
            visible={show}
            trigger={trigger}
            onVisibleChange={onVisibleChange}
            position={placement}
            content={content}
            className={style.popover}
            clickToHide={true}
            {...other}
        >
            <div>{children}</div>
        </Dropdown>
    )
}
