import { post } from 'utils'

export interface ApiMarketsCurrencyListType {
    currencyId: string
    currencyMark: string
    currencyLogo: string
    currencyName: string
    currencyBuyFee: string
    currencySellFee: string
    baseid: number
    blockType: number
    zoneType: string
    zoneSort: string
    basermb: string
    base2Usdt: string
    '24HChangeRate': string
    '24HChangeStatus': 0 | 1
    '24HDoneNum': string
    newPrice: string
    shift2Rmb: string
    maxPrice: string
    minPrice: string
    indexLook: string
    basemark: string
    innovateFlag: number
}

export interface ApiMarketsListType {
    title: string
    list: ApiMarketsCurrencyListType[]
}

export interface ApiMarketsType {
    api: 'market'
    method: 'post'
    return: {
        list: ApiMarketsListType[]
    }
}

export const ApiMarket = async () => {
    return await post<ApiMarketsType>('market')
}
