import BigNumber from 'bignumber.js'
import { locale } from 'config'
import { imgOrigin } from 'config/domain.config'
import moment from 'moment'

import { numberSize } from './NumberSize'

// 获取当前系统语言
export const getLocale = (): string => window.location.pathname.split('/')[1] || locale

/**
 * 判断多语言是否为中文
 * @param locale 多语言
 */
export const isChinese = (locale?: string): boolean => ['zh-cn', 'zh-hk'].includes(locale ?? getLocale())
export const isKorea = (locale?: string): boolean => ['ko-kr'].includes(locale ?? getLocale())
/** 判断当前系统是否为iOS或安卓 */
export const getSystem = (): 'Android' | 'iOS' | '' => {
    const u = navigator.userAgent

    if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
        return 'Android'
    } else if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        return 'iOS'
    } else {
        return ''
    }
}

/**
 * 返回剩余时间字符串
 * @param time 结束时间戳 （毫秒）
 * @returns string
 */

export const formatTime = (time: number): string => {
    const ts = moment.duration(time - new Date().getTime())
    const day = ts.days()
    const hours = ts.hours()
    const minutes = ts.minutes()

    return day > 0 ? `in ${day} days` : hours > 0 ? `in ${hours} hours` : minutes > 0 ? `in ${minutes} minutes` : t('NFT_0725_D282') /** 过期 */
}

/**  根据imgUrl 返回实际 图片URL */
export const imgUrl = (url: string): string => {
    if (url.indexOf('http') !== -1) {
        return url
    } else {
        return `${imgOrigin}/${url}`
    }
}

/**
 * 为url头部拼接当前语言
 * *url必须以/开头，并且开头只能有1个/
 * 其他情况均原样返回
 * @param uri 需要处理的url
 */
export const dealJumpUri = (uri: string): string => {
    return /^\/[^\\/]/.test(uri) ? `/${getLocale()}${uri}` : uri
}

/**
 * 判断平台是否为PC
 */
export const isPc: () => boolean = () => {
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // Android端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端

    return !isAndroid && !isiOS && window.getComputedStyle(document.documentElement).fontSize !== '50px'
}

type RegExpTarget = 'email' | 'password' | 'phone' | 'CNphone' | 'code' | 'invite-code'
/**
 * 获取常用正则对象
 * @param target 需要获取的正则
 */
export const getRegExp = (target: RegExpTarget): RegExp => {
    switch (target) {
        case 'email':
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
        case 'password':
            // 8-20位，不可为纯数字或纯字母，可包含特殊字符
            return /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,20})$/
        case 'phone':
            return /^0[1-9]{1}[\d]{4,12}$|^[1-9]{1}[\d]{5,13}$/
        case 'CNphone':
            // 大陆手机号
            return /^1[3-9]\d{9}$/
        case 'code':
            return /^[0-9]{6}$/
        case 'invite-code':
            // 邀请码
            return /^[0-9a-zA-Z]{6}$/
    }
}

export const loadScript = (url: string): Promise<void> => {
    const allScripts = Array.from(document.querySelectorAll('script'))?.map(item => item.src)
    if (/^\//.test(url) ? allScripts.includes(window.location.origin + url) : allScripts.includes(url)) {
        return Promise.resolve()
    }

    const script = document.createElement('script')
    script.src = url
    script.type = 'text/javascript'
    script.async = true
    document.body.appendChild(script)

    return new Promise((resolve, reject) => {
        script.onload = () => resolve()
        script.onerror = () => reject(new Error(`Request Error: ${url}`))
    })
}
/**
 * 对账号脱敏
 *
 * 对于邮箱账号，如果前半部分小于3位，则只显示第一位，否则显示前3位，其余显示为*号
 *
 * 对于电话账号，如果小于7位，不做处理，否则只显示前3位和后4位，其余显示为*号
 */
export const encodeAccount = (value: string) => {
    if (verify(value, 'email')) {
        let prefix = value.match(/(^.*)(@)/)?.[1] || ''
        const suffix = value.match(/(@)(.*$)/)?.[2] || ''
        if (prefix?.length <= 3) {
            prefix = prefix[0] + new Array(prefix?.length - 1).fill('*').join()
        } else {
            prefix = prefix[0] + prefix[1] + prefix[2] + new Array(prefix?.length - 3).fill('*').join('')
        }
        return `${prefix}@${suffix}`
    } else {
        if (value?.length < 7) {
            return value
        } else {
            const prefix = value.slice(0, 3)
            const suffix = value.slice(-4)
            const stars = new Array(value?.length - 7).fill('*').join('')
            return prefix + stars + suffix
        }
    }
}

/** 验证格式 */
export const verify = (value: string | undefined, type: 'email' | 'password' | 'phone' | 'CNphone' | 'invite-code'): boolean => {
    if (typeof value === 'string') {
        return getRegExp(type).test(value)
    } else {
        return false
    }
}

/**
 * 获取当前路由
 */
export const getCurrentRouter = (): string => {
    return window.location.pathname
}

/**
 * 判断当前页面是首页
 * @returns true or false
 */
export const isHomePage = () => {
    const pathname = getCurrentRouter()
    return pathname === `/${locale}/n` || pathname === `/${locale}/`
}

/**
 * 格式化数据，不进行四舍五入，例： 5123456789.126345 -> 5,123,456,789.126345
 * @param value 转换的值
 * @param len 保留位数，小数位不支持显示','，'auto'表示不改变小数位数
 * @param retain 是否保留小数位数的0
 * @param withSymbol 正数前是否有`+`号
 * @param nanOutput value转换为number后为NaN时，返回的值
 */
export function formatNum(value: string | number, len: number | 'auto' = 0, retain = true, withSymbol = false, nanOutput = '--') {
    if (value === '') return value

    const originalDecimalLength = value?.toString()?.split('.')[1]?.length || 0
    const decimalLength = Math.min(len === 'auto' ? originalDecimalLength : len, 12)
    const valueNum = Number(value)
    if (isNaN(valueNum)) return nanOutput

    const option: Intl.NumberFormatOptions = {
        style: 'decimal',
        maximumFractionDigits: decimalLength
    }
    if (retain) {
        option.minimumFractionDigits = decimalLength
    }
    const intl = new Intl.NumberFormat('en-US', option)
    let symbol = ''
    if (withSymbol && valueNum) {
        symbol = valueNum > 0 ? '+' : ''
    }
    return symbol + intl.format(+numberSize(value, decimalLength))
}
/** 只能输入2位小数 */
export const fixPriceReg = (num: number | string) => {
    let retNum = String(num)
    retNum = retNum?.replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    retNum = retNum?.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    retNum = retNum?.replace('.', '$#$')?.replace(/\./g, '')?.replace('$#$', '.')
    console.log('retNum', retNum)

    // retNum = retNum?.replace(/^(\\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
    if (retNum.indexOf('.') < 0 && retNum !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        retNum = String(parseFloat(retNum))
    }
    return String(retNum)
}
// 向下取整
export const numberFloor = (num: number, size = 2) => {
    const n = new BigNumber(num)
    const result = n.times(new BigNumber(10).pow(size)).integerValue(BigNumber.ROUND_FLOOR).dividedBy(new BigNumber(10).pow(size))
    console.log('numberFloor', result)

    // const newNum = Math.floor(num * Math.pow(10, size)) / Math.pow(10, size)
    return String(result)
}

export const changeLanguage = (lang: string) => {
    document.documentElement.setAttribute('lang', lang)
}
