// import { isPc } from './tools'

export const fixRem = () => {
    const designWidth = 750
    const rem2px = 100
    // const root = document.documentElement
    const html = document.querySelector('html')
    // const htmlFontSize = parseFloat(window.getComputedStyle(root, null).getPropertyValue('font-size'))

    function refreshRem() {
        if (html) {
            html.style.fontSize = Math.min((html.getBoundingClientRect().width / designWidth) * rem2px, 100) + 'px'
        }
    }

    refreshRem()
    window.addEventListener('resize', function () {
        refreshRem()
    })

    // if (isPc()) {
    //     document.body.className = 'pc'
    // } else {
    //     document.body.className = 'mobile'
    //     refreshRem()
    //     window.addEventListener('resize', function () {
    //         refreshRem()
    //     })
    // }
}
