import { useEffect, useState } from 'react'
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | string

// Hook
export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<Size>('xxl')
    const [windowWidth, setWindowWidth] = useState<number>(1280)
    const [clientHeight, setClientHeight] = useState<number>(667)
    const [isMobile, setIsMobile] = useState<boolean>(false)

    useEffect(() => {
        function handleResize() {
            const cHeight = document.documentElement.clientHeight ?? 667
            const sw = document.documentElement.scrollWidth

            let size = 'xxl'
            if (sw >= 1600) {
                size = 'xxl'
            } else if (sw >= 1200) {
                size = 'xl'
            } else if (sw >= 992) {
                size = 'lg'
            } else if (sw >= 768) {
                size = 'md'
            } else if (sw >= 575) {
                size = 'sm'
            } else {
                size = 'xs'
            }

            setClientHeight(cHeight)
            // 带滚动条的宽高
            setWindowWidth(sw)
            setWindowSize(size)
            setIsMobile(sw <= 768)
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])
    return { windowSize, isMobile, windowWidth, clientHeight }
}
