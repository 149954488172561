/*
    用于h5端的底部弹出框
*/

import React from 'react'
import { Close } from 'react-dui-icons'

import style from './ActionSheet.module.scss'

interface Props {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    children?: React.ReactNode
    closeable?: boolean
}
export const ActionSheet = (props: Props) => {
    const { visible, setVisible, children, closeable = true } = props

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        setVisible(false)
    }

    return (
        <>
            <div className={classNames(style.actionSheet, visible && style.show)}>
                {
                    !closeable &&
                    <div className={style.close} onClick={handleClose}>
                        <Close />
                    </div>
                }
                {children}
            </div>
            {visible && <div className={style.mask} onClick={handleClose} />}
        </>

    )
}
