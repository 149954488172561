// import Cookies from 'js-cookie'
import { ApiUserInfo } from 'api'
import { locale } from 'config'
import { checkLogin, post } from 'utils'

const getScript = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
        const po = document.createElement('script')
        po.type = 'text/javascript'
        po.async = true
        po.id = 'ze-snippet'
        po.src = 'https://static.zdassets.com/ekr/snippet.js?key=7c5a515a-a35f-4718-8f00-37f7470d17d5'
        const s = document.getElementsByTagName('script')[0]
        s.parentNode && s?.parentNode?.insertBefore(po, s)
        let index = 0
        const timer = setInterval(() => {
            index++
            if (document.getElementById('ze-snippet') && window.zE) {
                clearInterval(timer)
                resolve()
            }
            if (index === 15) {
                // 超时
                clearInterval(timer)
                // eslint-disable-next-line prefer-promise-reject-errors
                reject()
            }
        }, 1000)
    })
}

/**
 * 插入在线客服script
 */
export const zETag = (callback: any): void => {
    const zeScript = document.getElementById('ze-snippet')
    if (zeScript && window?.zE) {
        zEfun(() => {
            callback()
        })
        return
    }

    getScript()
        .then(() => {
            zEfun(() => {
                callback()
            })
        })
        .catch(() => {
            // 超时
        })
}
/**
 *
 * @param locale 传入当前页面的系统语言 zh-cn用zendesk的简中机翻 zh-hk用繁中机翻
 * 其余使用英语en-US
 */
export const setLocaleLang = (locale: string): void => {
    switch (locale) {
        case 'zh-cn':
            window?.zE?.setLocale?.('zh-cn')
            break
        case 'zh-hk':
            window?.zE?.setLocale?.('zh-tw')
            break
        default:
            window?.zE?.setLocale?.('en-US')
            break
    }
}
/**
 * 打开在线客服
 */
export const zEOpen = (): void => {
    if (!window.zE) return
    window?.zE?.('messenger', 'show')
    window?.zE?.('messenger', 'open')
}

/** 显示在线客服 */
export const zEShow = (): void => {
    if (!window.zE) return
    window?.zE?.('messenger', 'show')
}

/** 隐藏在线客服 */
export const zEHide = (): void => {
    if (!window.zE) return
    window?.zE?.('messenger', 'hide')
}
/** 清除在线客服表单数据 */
export const zEClear = (): void => {
    if (!window.zE) return
    window?.zE?.('messenger', 'clear')
}

export const zEClose = (): void => {
    if (!window.zE) return
    window?.zE?.('messenger', 'close')
}

// 弹出小窗口
export const zEPopout = (): void => {
    if (!window.zE) return
    window?.zE?.('messenger', 'popout')
}

// 直接拉起chat窗口
export const zEOpenChat = (): void => {
    try {
        if (!window.zE) return
        window?.zE?.('messenger', 'show')
    } catch (error) {
        //
    }
}

/**
 *
 * 初始化在线客服
 */
export const zEfun = (callback: Function): void => {
    if (checkLogin()) {
        post<ApiUserInfo>('user/info').then(res => {
            window?.zE?.('messenger', 'identify', {
                name: (res.userProve === 2 ? res.name : t("Haven't passed KYC")) /** 未实名 */ + '/' + res.showUid,
                email: res.email,
                organization: ''
            })

            window.zESettings = {
                webWidget: {
                    color: {
                        theme: '#01b5ab'
                    },
                    contactForm: {
                        fields: [
                            { id: 360003475094, prefill: { '*': res.showUid } },
                            { id: 360003513913, prefill: { '*': res.regType === 1 ? res.phone : res.email } }
                        ]
                    }
                }
            }
        })
    } else {
        window?.zE?.('webWidget:on', 'chat:connected', function () {
            window?.zE?.('messenger', 'chat:end')
            window?.zE?.('webWidget:on', 'chat:end', function () {
                window?.zE?.('webWidget:on', 'chat:start', function () {
                    window?.zE?.('messenger', 'chat:end')
                    // Notification.error(t('App_BalanceIndexNoLogin_LoginFirst') /** 请先登录 */, 1, (): void => {
                    //     window.location.href = `/${getLocale()}/login`
                    // })
                })
            })
        })
    }
    window?.zE?.(() => {
        setLocaleLang(locale)
        if (locale !== 'zh-cn' && locale !== 'zh-hk') {
            window?.zE?.setLocale('en-US')
            window?.zE?.('messenger', 'helpCenter:setSuggestions', { search: '2FA' })
        } else {
            window?.zE?.('messenger', 'helpCenter:setSuggestions', { search: t('Web_TopAndBottomNavigation_2FactorAuthentication') /** 双重认证 */ })
        }
    })
    window?.zE?.('webWidget:on', 'close', function () {
        window?.zE?.('messenger', 'hide')
    })
    window.zESettings = {
        webWidget: {
            launcher: {
                label: {
                    'en-US': 'Contact Customer Service',
                    'zh-cn': t('Web_Index_Contact') /** 联系客服 */,
                    'zh-tw': t('Web_Index_Contact') /** 联系客服 */,
                    fr: "Besoin d'aide?"
                }
            },
            helpCenter: {
                title: {
                    '*': 'Parobit',
                    fr: "Recherche d'aide"
                }
            },
            contactForm: {
                title: {
                    '*': 'Parobit'
                }
            },
            chat: {
                title: {
                    '*': 'Parobit',
                    pl: 'Czat na żywo'
                }
            },
            talk: {
                title: {
                    '*': 'Parobit'
                }
            },
            answerBot: {
                title: {
                    '*': 'Parobit'
                }
            }
        }
    }
    callback()
}
