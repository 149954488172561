import { get } from 'utils'

export interface IApiGetMinsKline {
  api: 'kline/mins-kline',
  method: 'get',
  params: {
    pairs: string
  }
  return: {
    list: {
      [key: string]: string[];
    };
  }
}

export const ApiGetMinsKline = async (params: IApiGetMinsKline['params']) => {
  return await get<IApiGetMinsKline>('kline/mins-kline', { ...params })
}
