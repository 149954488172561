import 'nprogress/nprogress.css'
import './global.scss'

import { ErrorBoundary, Loading } from 'components'
import { Suspense } from 'react'
// import { hydrate, render } from 'react-dom'
import ReactDOM from 'react-dom/client'

import App from './App'
import I18n from './locale/I18n'

// const I18n = lazy(() => import('./locale/I18n'))

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    // <React.StrictMode>
    <Suspense fallback={<Loading />}>
        <I18n>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </I18n>
    </Suspense>
    // </React.StrictMode>
)

// const Root = () => {
//     return (
//         <Suspense fallback={<Loading />}>
//             <I18n>
//                 <ErrorBoundary>
//                     <App />
//                 </ErrorBoundary>
//             </I18n>
//         </Suspense>
//     )
// }

// const rootElement = document.getElementById('root')
// if (rootElement !== null) {
//     rootElement.hasChildNodes() ? hydrate(<Root />, rootElement) : render(<Root />, rootElement)
// }
