import { post } from 'utils'
export interface ApiThirdRegisterInterface {
    api: 'third/register'
    method: 'post'
    params: {
        regId: string
        regSign: string
        pwd: string
        account: string
        captcha: string
        countryCode?: number | string
        inviteCode?: string
    }
    return: {
        accessToken: string
        expires: number
        signSecret: string
        showUid: number
    }
}

export const ApiThirdRegister = async (params: ApiThirdRegisterInterface['params']) => {
    return await post<ApiThirdRegisterInterface>('third/register', { ...params })
}
