import { post } from '../../utils'

export interface ApiMessageLatestUnreadType {
    api: 'message/latest-unread'
    method: 'post'
    return: {
        list: {
            messageId: number
            categoryId: number
            contentId: number
            from_type: number
            fromId: number
            status: string
            dateline: string
            datelineTimeStamp: 0
            lastupdate: string
            contentTitle: string
            contentText: string
            articleId: number
        }[]
    }
}

export const ApiGetMsgUnread = async () => {
    return await post<ApiMessageLatestUnreadType>('message/latest-unread')
}
