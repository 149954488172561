import { Button, Col, Row } from '@douyinfe/semi-ui'
import { Header } from 'components'
import DownloadPop from 'components/DownloadPop/DownloadPop'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import { locale } from 'config'
import useBackUrl from 'hooks/useBackUrl'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ExclamationCircleOutlinedFill } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'
import useSecondaryVerification from 'store/useSecondaryVerification'
import { encodeAccount, post, safeSprintf } from 'utils'
import { storeToken } from 'utils/token'
import { api } from 'views/Auth/Register/api/api'
import ApiOclrLoginRegisterParams from 'views/Auth/Register/api/ApiOclrLoginRegisterParams'
import FormItem from 'views/Auth/Register/components/FormItem/FormItem'
import { ApiOclrSendCaptchaParams } from 'views/Auth/Register/types/oclrSendCaptcha'

import styles from './ValidateLogon.module.scss'

const ValidateLogon = (): JSX.Element => {
    const navigate = useNavigate()
    const [verificationCode, setVerificationCode] = useState('')
    const [verificationCodeOk, setVerificationCodeOk] = useState(false)
    const [otp, setOtp] = useState('')
    const [captchaId, setCaptchaId] = useState('')
    const { secondCaptchaData } = useSecondaryVerification()
    const captchaRef = useRef<{ captchaClick(): any }>({
        captchaClick: () => {}
    })
    const { aop, backUrl } = useBackUrl()

    const inpLabel = useMemo(() => {
        if (secondCaptchaData?.sendType === 'email') {
            return `${t('Email')} ${t('Verification Code')}`
        } else {
            return `${t('Telephone')} ${t('Verification Code')}`
        }
    }, [secondCaptchaData?.needGoogle, secondCaptchaData?.oclrMethod])

    const btnDisabled = useMemo(() => {
        if (secondCaptchaData?.needVerificationCode) {
            if (secondCaptchaData?.needGoogle) {
                return !(captchaId !== '' && otp?.length === 6 && verificationCodeOk)
            } else {
                return !(captchaId !== '' && verificationCodeOk)
            }
        } else {
            if (secondCaptchaData?.needGoogle) {
                return !(otp?.length === 6)
            }
        }
    }, [secondCaptchaData?.needGoogle, verificationCode, captchaId, otp, secondCaptchaData?.needVerificationCode])

    const displayAccount = useMemo(() => {
        if (secondCaptchaData?.needSendAccount) {
            return secondCaptchaData.needSendAccount || ''
        } else {
            return secondCaptchaData?.account || ''
        }
    }, [secondCaptchaData, secondCaptchaData?.needSendAccount, secondCaptchaData?.account])

    const verAccount = useMemo(() => {
        return secondCaptchaData?.account || ''
    }, [secondCaptchaData, secondCaptchaData?.needSendAccount, secondCaptchaData?.account])

    const verData = useMemo(() => {
        const data = {} as ApiOclrSendCaptchaParams
        data.account = secondCaptchaData?.account || ''
        data.oclrMethod = secondCaptchaData?.oclrMethod || 'email'
        data.captchaId = ''
        data.areaCode = secondCaptchaData?.areaCode
        data.oclrScene = secondCaptchaData?.oclrScene || 'login'
        data.cOtpType = secondCaptchaData?.cOtpType
        return data
    }, [secondCaptchaData, verAccount])

    const onClick = () => {
        const clone = JSON.parse(JSON.stringify(secondCaptchaData))
        delete clone.needGoogle
        delete clone.needSendPhone
        delete clone.needSendEmail
        delete clone.needSendAccount
        delete clone.sentType
        const data: ApiOclrLoginRegisterParams = clone as unknown as ApiOclrLoginRegisterParams
        data.oclrScene = 'login'
        if (secondCaptchaData?.needVerificationCode) {
            data.captcha = verificationCode
        } else {
            data.captcha = secondCaptchaData?.captcha || ''
        }
        if (secondCaptchaData?.needGoogle) {
            data.googleCaptcha = otp
        }
        // @ts-ignore
        post(api.oclrLoginRegister, data).then((res: any) => {
            if (res?.errcode) {
                const errCodeStr = String((res as any).errcode)
                if (errCodeStr === '200084' || errCodeStr === '200085' || errCodeStr === '200086') {
                    /** 200084: 账号或密码错误 200085: 验证码错误 200086: 谷歌验证码错误 */
                    const text = safeSprintf(t(errCodeStr, { ns: 'errCode' }), res.data.args[0])
                    Toast.error({ content: text, showClose: false })
                }
            } else {
                storeToken(res)
                Toast.success({ content: t('Login succeeded'), showClose: false })
                aop()
            }
        })
    }

    useEffect(() => {
        if (!secondCaptchaData?.account) {
            navigate(`/${locale}/login?backUrl=${backUrl}`)
        } else {
            captchaRef.current?.captchaClick()
        }
    }, [])

    return (
        <div className={styles.validateLogon}>
            <Header showReg={false} />
            <Row
                type={'flex'}
                justify={'center'}
            >
                <DownloadPop></DownloadPop>
                <Col
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <div className={styles.validateLogonTip}>
                        <ExclamationCircleOutlinedFill
                            style={{ marginRight: '8px' }}
                            size={16}
                            className={styles.validateLogonTipIcon}
                        ></ExclamationCircleOutlinedFill>
                        <div>{t('The device or geographic location may have changed from the last time. For your account security, please re-verify')}</div>
                    </div>
                </Col>
                <Col
                    style={{ marginTop: '50px' }}
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={0}
                    sm={0}
                    xs={0}
                    span={0}
                ></Col>
                <Col
                    style={{ marginTop: '20px' }}
                    xxl={8}
                    xl={8}
                    lg={8}
                    md={22}
                    sm={22}
                    xs={22}
                >
                    <div className={styles.validateLogonTitle}>
                        {t('Enter the verification code')}
                        <div className={styles.validateLogonTitleAccount}>{encodeAccount(displayAccount)}</div>
                    </div>
                    {secondCaptchaData?.needVerificationCode && (
                        <FormItem
                            captchaRef={captchaRef}
                            verificationData={verData}
                            account={secondCaptchaData.account || ''}
                            onChange={(val, valid) => {
                                setVerificationCode(val)
                                setVerificationCodeOk(valid)
                            }}
                            label={inpLabel}
                            captchaCb={id => setCaptchaId(id)}
                            type={'verification'}
                        ></FormItem>
                    )}
                    <FormItem
                        style={{ display: secondCaptchaData?.needGoogle ? 'block' : 'none' }}
                        type={'otp'}
                        onChange={v => setOtp(v)}
                        label={`Google ${t('Verification Code')}`}
                    ></FormItem>
                    <Button
                        style={{ marginBottom: '16px', marginTop: '24px' }}
                        theme="solid"
                        type={'primary'}
                        block
                        onClick={onClick}
                        disabled={btnDisabled}
                    >
                        {t('Submit')}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default ValidateLogon
