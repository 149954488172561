import { imgOrigin } from 'config'
import currencyLogoStore from 'store/currencyLogoStore'

/**
 * 统一获取币种图标
 */
export const useCurrencyLogo = () => {
    const [currencyLogoList] = currencyLogoStore(state => [state.currencyLogoList])
    const defaultIcon = '/main_static/images/user/default_coin.png'
    const usdtIcon = '/main_static/images/user/usdt.png'

    const getLogoByMark = (mark: string, aspectRatio?: [number, number]) => {
        const logoStyle = {
            width: aspectRatio?.[0] || 16,
            height: aspectRatio?.[1] || 16,
            borderRadius: '50%'
        }

        if (mark === 'USDT') {
            return (
                <img
                    src={`${usdtIcon}`}
                    style={logoStyle}
                />
            )
        }

        for (const category of currencyLogoList) {
            for (const currency of category.list) {
                if (currency.currencyMark === mark) {
                    return (
                        <img
                            src={`${imgOrigin}${currency.currencyLogo}`}
                            style={logoStyle}
                            loading="lazy"
                        />
                    )
                    // return currency.currencyLogo
                }
            }
        }

        return (
            <img
                src={defaultIcon}
                style={logoStyle}
            />
        )
    }

    return [getLogoByMark]
}
