import { Checkbox, Input, Toast } from '@douyinfe/semi-ui'
import { CheckboxEvent } from '@douyinfe/semi-ui/lib/es/checkbox'
import { ApiDepositInternalVerify } from 'api/pay/ApiDepositInternalVerify'
import ExclamationModal from 'components/ExclamationModal/ExclamationModal'
import { locale } from 'config'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate, formatNum, safeSprintf, storage } from 'utils'

import style from './InternalTransfer.module.scss'

interface Props {
    depositId: string
    currencyMark: string
    num: string
    verifyEndTime: number
    onSuccess?: () => void
}
export const InternalTransfer = forwardRef((props: Props, ref) => {
    const { depositId, currencyMark, num, verifyEndTime, onSuccess } = props
    const [visible, setVisible] = useState(false)
    const [code, setCode] = useState(Array(6).fill(''))
    const [checked, setChecked] = useState(false)
    const inputRefs = useRef<(HTMLInputElement | null)[]>([])
    const navigator = useNavigate()

    const handleInputChange = (value: string, index: number) => {
        const newCode = [...code]
        newCode[index] = value
        setCode(newCode)
        if (value && index < 5) {
            inputRefs.current[index + 1]?.focus()
        }
    }

    const handleChangeCheckbox = (e: CheckboxEvent) => {
        setChecked(e.target.checked as boolean)
    }

    const handleReceive = () => {
        ApiDepositInternalVerify({ depositId, verifyCode: code.join('') }).then(res => {
            if (!res?.errcode) {
                Toast.success({
                    content: t('Receive success'),
                    showClose: false
                })
                setVisible(false)
                navigator(`/${locale}/user/log`)
                onSuccess?.()
            }
        })
    }

    useEffect(() => {
        const hideTime = storage?.getItem(`hide-insite-transfer-${depositId}`)
        if (hideTime) {
            const isLessThan24H = Date.now() < hideTime + 24 * 60 * 60 * 1000
            const isSameDate = new Date().getDate() === new Date(hideTime).getDate()
            setVisible(!(isLessThan24H && isSameDate))
        } else {
            setVisible(true)
        }
    }, [])

    useEffect(() => {
        if (!visible && depositId) {
            if (checked) {
                storage.setItem(`hide-insite-transfer-${depositId}`, Date.now())
            }
        }
    }, [visible, checked, depositId])

    useImperativeHandle(ref, () => {
        return {
            setVisible: (value: boolean) => {
                setVisible(value)
            }
        }
    })

    return (
        <ExclamationModal
            title={t('Receive payment')}
            visible={visible}
            setVisible={setVisible}
            icon={null}
            onOk={handleReceive}
            showCancel={false}
            okText={t('Receive payment')}
        >
            <div className={style.content}>
                <p>{t('You have a internal tranfer.')}</p>
                <h4>
                    {formatNum(num, 'auto')} {currencyMark}
                </h4>
                <p>{t('Please enter the 6-figure payee code')}</p>
                <div className={style.inputContainer}>
                    {code.map((value, index) => (
                        <Input
                            key={index}
                            value={value}
                            maxLength={1}
                            onChange={value => handleInputChange(value, index)}
                            ref={el => {
                                inputRefs.current[index] = el
                            }}
                        />
                    ))}
                </div>
                <small
                    dangerouslySetInnerHTML={{
                        __html: safeSprintf(t('Please receive the payment before %s The payment will be returned when time out'), `<a>${formatDate(verifyEndTime * 1000, 'YY/MM/DD h:m:s')}</a>`)
                    }}
                />
                <Checkbox onChange={handleChangeCheckbox}>{t('Don’t show me again today')}</Checkbox>
            </div>
        </ExclamationModal>
    )
})

export default InternalTransfer
