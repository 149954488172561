import { isDev, isPreEnv, isTestEnv } from 'config'
import { sprintf } from 'sprintf-js'

export const safeSprintf = (str: string, ...args: Array<string | number | undefined>): string => {
    try {
        const result = sprintf(str, ...args)
        return result
    } catch (e) {
        if (isTestEnv || isPreEnv || isDev) {
            console.error('sprintf抛出错误，请检查以下多语言的占位符是否错误（如%s错写成%S等）')
        }
        console.error(str)
        console.error(e)
        return str
    }
}
