import { useEffect } from 'react'
import { isPc } from 'utils'
import { setMobileCookies } from 'utils/setMobileCookies'

import currencyLogoStore from './currencyLogoStore'
import useStore from './user'
import useUSDTList from './useUSDTList'

interface StoreProps {
    children: React.ReactNode
}
export const StoreProvider = ({ children }: StoreProps): JSX.Element => {
    const getUserInfo = useStore(state => state.getUserInfo)
    const getUSDTList = useUSDTList(state => state.getUSDTList)
    const getCurrencyLogo = currencyLogoStore(state => state.getCurrencyLogo)
    setMobileCookies()

    useEffect(() => {
        getUserInfo()
        getUSDTList()
        getCurrencyLogo()

        if (!isPc()) {
            const onMessage = (event: { data: string }) => {
                console.warn('event.data: ', event.data)
                if (event.data === 'com.app.token.refresh' && !isPc()) {
                    setMobileCookies()
                }
            }
            window.addEventListener('message', onMessage)
        }

        // const intervalRefreshToken = setInterval(() => {
        //     if (!isPc()) {
        //         setMobileCookies()
        //     }
        // }, 30000)
    }, [])
    return <>{children}</>
}
