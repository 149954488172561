import Cookies from 'js-cookie'

/**
 * 用户系统语言匹配(未匹配到返回 'en-ww')
 */

export const initLang = () => {
    const langList = [
        {
            code: 'en-ww'
        },
        {
            code: 'zh-cn'
        },
        {
            code: 'zh-hk'
        },
        {
            code: 'ko-kr'
        },
        {
            code: 'id-id'
        },
        {
            code: 'ja-jp'
        },
        {
            code: 'pt-pt'
        },
        {
            code: 'ru-ru'
        },
        {
            code: 'vi-vn'
        },
        {
            code: 'th-th'
        },
        {
            code: 'sr-sr'
        },
        {
            code: 'es-es'
        },
        {
            code: 'ar-kw'
        }
    ]

    let lang = 'en-ww'
    /** @ts-ignore */
    const browser = (navigator.language || navigator.browserLanguage).toLowerCase()

    // 中文和繁体
    if (browser.indexOf('zh') !== -1) {
        if (browser === 'zh-hk' || browser === 'zh-tw') {
            lang = 'zh-hk'
        } else {
            lang = 'en-ww'
        }
        // 其他语言
    } else if (langList.some((item: { code: string }) => browser.indexOf(item.code.split('-')[0]) !== -1)) {
        langList.forEach((val: any) => {
            const str = val.code.split('-')[0]

            if (browser.indexOf(str) !== -1) {
                lang = val.code
            }
        })
    }

    return lang
}

// 当前系统语言
export const locale = window.location.pathname.split('/')[1] || Cookies.get('lang') || initLang()

// 选择初始语言
export const indexLocale = window.navigator.userAgent.indexOf('HeadlessChrome') !== -1 ? 'en-ww' : Cookies.get('lang') || initLang()
