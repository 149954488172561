import { ApiUserInfo } from 'api'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { checkLogin, post } from 'utils'

/**
 * 判断当前uid是否为子账户
 *
 * 返回值：默认 undefined / 普通账户 false / 子账户 true
 */
export const useCheckSubAccount = (): boolean | undefined => {
    const [isSub, setIsSub] = useState<boolean>()

    const uid: string = Cookies.get('m_showID') as string

    const fetchUserInfo = async (): Promise<void> => {
        await post<ApiUserInfo>('user/info').then((data: { accountType: number }) => {
            sessionStorage.setItem('is_sub', JSON.stringify({ uid, isSub: Number(data.accountType) === 2 }))
            setIsSub(data.accountType === 2)
        })
    }

    useEffect(() => {
        if (!checkLogin()) {
            setIsSub(false)
        } else {
            if (!checkSessionStorage(uid)) {
                fetchUserInfo()
            } else {
                setIsSub(JSON.parse(sessionStorage.getItem('is_sub') as string).isSub)
            }
        }
    }, [])

    return isSub
}

/**
 * 检查sessionStorage中是否有当前id账户信息
 */
const checkSessionStorage = (uid: string): boolean => {
    const item = String(sessionStorage.getItem('is_sub'))
    if (!item || JSON.parse(item)?.uid !== uid) {
        return false
    } else {
        return JSON.parse(item).uid === uid
    }
}
