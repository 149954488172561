import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Skeleton } from '@douyinfe/semi-ui'
import { useInViewport } from 'ahooks'
import { ApiBannerList, ApiBannerListItem } from 'api'
import { imgOrigin, locale } from 'config'
import { useEffect, useRef, useState } from 'react'
import { ArrowLeft, ArrowRight } from 'react-dui-icons'
import Slider from 'react-slick'
import { dealJumpUri, isPc } from 'utils'

import style from './ScrollCoins.module.scss'

const ScrollCoins = (): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null)
    const [isInViewport] = useInViewport(ref)
    const [hasEnteredViewport, setHasEnteredViewport] = useState(false)
    const [slider, setSlider] = useState<Slider>()
    const [list, setList] = useState<ApiBannerListItem[]>([])

    async function getBannerList() {
        const resp = await ApiBannerList({
            isNewWeb: true
        })
        setList(resp?.list ?? [])
    }

    function SamplePrevArrow(props: any) {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                id="pre"
                style={{ visibility: 'hidden', ...style }}
                onClick={onClick}
            >
                <ArrowLeft size={20} />
            </div>
        )
    }

    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                id="next"
                style={{ visibility: 'hidden', ...style }}
                onClick={onClick}
            >
                <ArrowRight size={20} />
            </div>
        )
    }

    const settings = {
        dots: isPc(),
        infinite: true,
        centerMode: !isPc(),
        slidesToShow: isPc() ? 4 : 1,
        slidesToScroll: 1,
        speed: 1000,
        pauseOnHover: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        arrows: isPc(),
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 5000,
        rtl: locale !== 'ar-kw'
    }

    useEffect(() => {
        if (isInViewport && !hasEnteredViewport) {
            setHasEnteredViewport(true)
        }
    }, [isInViewport, hasEnteredViewport])

    useEffect(() => {
        getBannerList()
    }, [])

    const placeholder: JSX.Element = <Skeleton.Image style={{ width: 388, height: 218, borderRadius: 8 }} />

    return (
        <div
            className={classNames({ [style.root]: true, [style.enter]: hasEnteredViewport })}
            ref={ref}
        >
            <h1>{t('paro:Trending Events ')}</h1>
            <Slider
                {...settings}
                ref={(ref): void => {
                    ref !== null && slider === undefined && setSlider(ref)
                }}
                className={style.scrollList}
            >
                {list?.map((item: any, index: number) => {
                    return (
                        <Skeleton
                            key={index}
                            placeholder={placeholder}
                            loading={!item.pic}
                        >
                            <div className={classNames('slider-item')}>
                                <a
                                    href={dealJumpUri(item.jumpUrl)}
                                    onClick={() => {
                                        window?.gtag?.('event', 'click_banner', {
                                            sort: index + 1
                                        })
                                    }}
                                >
                                    <img
                                        src={`${imgOrigin}${item.pic}`}
                                        alt={item.pic}
                                        loading={'eager'}
                                    />
                                </a>
                            </div>
                        </Skeleton>
                    )
                })}
            </Slider>
        </div>
    )
}

export default ScrollCoins
