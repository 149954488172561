import { Button, Popover } from '@douyinfe/semi-ui'
import { QRCodeWithLogo } from 'components/QRCodeWithLogo/QRCodeWithLogo'
import { domain, locale } from 'config'
import { Download02 } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'

import style from './DownloadPop.module.scss'

interface Props {
    /** 类型，1:导航栏内，2:右下角浮动，3:固底浮动 */
    type?: number
}
const DownloadPop = (props: Props): JSX.Element => {
    const navigator = useNavigate()
    const { type = 1 } = props

    const popContainer = (
        <div className={style.popContainer}>
            <div className={style.title}>{t('Scan QR Code to Download App')}</div>
            <div className={style.code}>
                <QRCodeWithLogo
                    size={200}
                    value={`https://www.${domain}/${locale}/download`}
                />
            </div>
            <div
                className={style.link}
                onClick={() => {
                    navigator(`/${locale}/download`)
                    // google data
                    window?.gtag?.('event', 'click_download_navbar')
                    // google data
                }}
            >
                {t('View more')}
            </div>
        </div>
    )

    return (
        <section className={style.root}>
            {type === 1 && (
                <div className={style.topWrap}>
                    <Popover
                        showArrow
                        content={popContainer}
                        position="bottomRight"
                        onVisibleChange={visible => {
                            visible && window?.gtag?.('event', 'click_download_navbar')
                        }}
                        zIndex={1080}
                    >
                        <span>
                            <Download02 size={24} />
                        </span>
                    </Popover>
                </div>
            )}

            {type === 2 && (
                <div className={style.floatWrap}>
                    <Popover
                        showArrow
                        content={popContainer}
                        position="leftBottom"
                    >
                        <div className={style.floatBox}>
                            <Download02 size={12} />
                        </div>
                    </Popover>
                </div>
            )}

            {type === 3 && (
                <div
                    className={style.downloadPop}
                    onClick={() => navigator(`/${locale}/download`)}
                >
                    <img
                        src="/main_static/images/home/d_icon_0327.svg"
                        className={style.logo}
                        alt="logo"
                    />
                    <div className={style.info}>
                        <div className={style.infoTitle}>Parobit App</div>
                        <div className={style.infoTip}>Mobile transaction,safe & fast</div>
                    </div>
                    <Button
                        theme="solid"
                        type={'primary'}
                    >
                        Download <Download02 size={12} />
                    </Button>
                </div>
            )}
        </section>
    )
}

export default DownloadPop
