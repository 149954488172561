import { loadScript } from './tools'

export const PIXEL_REPORT_SCENE = {
    LANDING_4_PAGE: {
        sendTo: 'DC-14613142/stand0/adogt0+standard',
        id: '1794456',
        seg: '37544594',
        reportOnAllPage: false
    },
    ALL_PAGE: {
        sendTo: 'DC-14613142/stand0/adogt007+standard',
        id: '1794493',
        seg: '37545751',
        reportOnAllPage: true
    },
    CLICK_HEADER_BUY: {
        sendTo: 'DC-14613142/stand0/adogt003+standard',
        id: '1794495',
        seg: '37545134',
        reportOnAllPage: false
    },
    CLICK_HEADER_MARKETS: {
        sendTo: 'DC-14613142/stand0/adogt004+standard',
        id: '1794499',
        seg: '37545759',
        reportOnAllPage: false
    },
    CLICK_HEADER_TRADE: {
        sendTo: 'DC-14613142/stand0/adogt005+standard',
        id: '1794503',
        seg: '37545764',
        reportOnAllPage: false
    },
    CLICK_HEADER_FUTURES: {
        sendTo: 'DC-14613142/stand0/adogt006+standard',
        id: '1794505',
        seg: '37545773',
        reportOnAllPage: false
    },
    CLICK_HEADER_LOGIN: {
        sendTo: 'DC-14613142/stand0/adogt002+standard',
        id: '1794466',
        seg: '37544662',
        reportOnAllPage: false
    },
    CLICK_CREATE_ACCOUNT: {
        sendTo: 'DC-14613142/stand0/adogt00+standard',
        id: '1794457',
        seg: '37544615',
        reportOnAllPage: false
    },
    CLICK_GOOGLE_BTN: {
        sendTo: 'DC-14613142/stand0/adogt000+standard',
        id: '1794458',
        seg: '37544617',
        reportOnAllPage: false
    },
    CLICK_TG_BTN: {
        sendTo: 'DC-14613142/stand0/adogt001+standard',
        id: '1794459',
        seg: '37544618',
        reportOnAllPage: false
    }
} as const

type IPixelReportSceneKey = keyof typeof PIXEL_REPORT_SCENE

export const pixelReport = (scene: IPixelReportSceneKey) => {
    const { sendTo, id, seg, reportOnAllPage = false } = PIXEL_REPORT_SCENE[scene]
    const url = window?.location?.href
    // 注册按钮都上报
    if (reportOnAllPage || window?.location?.pathname.includes('landing') || scene === 'CLICK_CREATE_ACCOUNT') {
        window?.gtag?.('event', 'conversion', {
            allow_custom_scripts: true,
            u1: url,
            u2: document?.referrer,
            send_to: sendTo
        })
        loadScript(`https://secure.adnxs.com/px?id=${id}&seg=${seg}&redir=${encodeURIComponent(url)}&t=1`)
    }
}
