import type { ApiTokenRefresh } from 'api/token'
import type { AxiosRequestHeaders, AxiosResponse } from 'axios'
import axios from 'axios'
import { CustomToast } from 'components/semi-ext/CustomToast/CustomToast'
import { locale } from 'config'
import { apiPrefix, NFTVersion, wwwPrefixDrv } from 'config/domain.config'
import Cookies from 'js-cookie'

import { AuthHeaders, getAuth } from './auth'
import { checkLogin } from './checkLogin'
import { convertKeyStyle } from './convertKeyStyle'
import { clearToken, updataToken } from './token'

// typpes
type DRequestHeaders = AxiosRequestHeaders & AuthHeaders

// 不需要提示的错误码
const projectErrcodeNoTip = [924009, 200008, 200009, 200084, 200085, 200086, 390016, 390017, 390020, 390021, 390022, 400075, 400076, 400077, 400078, 400079, 400080, 400091]
class ApiError<T extends ApiResponseType = ApiResponseType> {
    public errcode: T['errcode']

    public data: T['data']

    constructor(errcode: T['errcode'], data: T['data']) {
        this.errcode = errcode
        this.data = data
    }
}

type RequestArguments<T extends ApiType> = T['params'] extends Record<string, unknown> ? [api: T['api'], params: T['params'], noTip?: T['noTip']] : [api: T['api']]

let tokenUpadating = false
async function request(api: string, method: 'get' | 'post', params?: Record<string, unknown>, noTip?: boolean): Promise<any> {
    let snakeCaseParams = {} as Record<string, unknown>
    if (params) {
        snakeCaseParams = convertKeyStyle(params, 'snake')
    }

    // 临近过期时刷新token
    const accessTime = Number(Cookies.get('m_access_time'))
    /** 刷新token信息取消 接口暂时不支持web刷新 tokenUpadating */
    if (checkLogin() && accessTime && accessTime * 1000 - Date.now() < 3600 && tokenUpadating) {
        const showUid = Number(Cookies.get('m_showID'))
        tokenUpadating = true
        const res: AxiosResponse<ApiResponseType> = await axios({
            method: 'post',
            url: `${apiPrefix}token/refresh`,
            data: {
                show_uid: showUid
            }
        })
        tokenUpadating = false
        if (res.data.errcode) {
            clearToken()
            return Promise.reject(new ApiError(res.data.errcode, res.data.data))
        } else if (typeof res.data.data === 'object') {
            updataToken(convertKeyStyle(res.data.data, 'camel') as ApiTokenRefresh['return'])
        }
    }

    const action = method.toLocaleUpperCase() === 'POST' ? 'data' : 'params'

    // 合约部分url 处理
    let url = api
    if (url.includes('swapv1')) {
        url = apiPrefix + url.replace('swapv1/', '')
    } else if (url.includes('capi')) {
        url = wwwPrefixDrv + url
    } else {
        url = apiPrefix + url.replace('v1', NFTVersion)
    }

    return axios({
        method,
        // url: apiPrefix + api.replace('v1', NFTVersion),
        url,
        timeout: 30 * 1000,
        [action]: snakeCaseParams
    }).then((res: AxiosResponse<ApiResponseType>) => {
        if (res.data.errcode) {
            /**
             * 100010 時間戳不能為空
             * 300001 您的登錄已過期，請重新登錄
             * 300101 簽名無效
             * 300201 异常
             */

            if ([100010, 300001, 300101, 300201, 300010].includes(res.data.errcode)) {
                clearToken()
                // window.location.href = `https://www.${domain}/${locale}/login`
                CustomToast.error({
                    // content: `ErrCode: ${res.data.errcode}`,
                    content: t('300001', { ns: 'errCode' }) /** 您的登录已过期，请重新登录 */
                })

                const path = window.location.pathname.split('/')[3].toLocaleLowerCase()
                if (path === 'newcard') {
                    // 信用卡业务不跳转到登录页（麻烦死了）
                    window.location.reload()
                } else {
                    // 跳转登录页，并在登陆成功后返回原页面
                    window.location.href = `/${locale}/login?backUrl=${window.location.pathname}${window.location.search}`
                }
            } else if (res.data.errcode !== 0 && !projectErrcodeNoTip.includes(res.data.errcode) && !noTip) {
                CustomToast.error({
                    // content: `ErrCode: ${res.data.errcode}`,
                    content: res.data.msg || t(`${res.data.errcode}`, { ns: 'errCode' })
                })
            }
            if (res?.data?.data) {
                res.data.data = convertKeyStyle(res.data.data as any, 'camel')
            }
            return res.data
            // return Promise.reject(new ApiError(res.data.errcode, res.data.data))
        } else if (typeof res.data.data === 'object') {
            // 汇率接口不走camelCase,避免特殊字符被过滤掉
            if (url?.includes('dc_to_usdt_list')) {
                return res?.data?.data
            }
            return convertKeyStyle(res.data.data, 'camel')
        } else {
            return res?.data?.data || res.data
        }
    })
}

// 请求拦截
axios.interceptors.request.use(async config => {
    const { method = 'get' } = config
    const action = method.toLocaleUpperCase() === 'POST' ? 'data' : 'params'
    const auth = await getAuth(config[action])

    config.headers = {
        'Content-Type': 'application/json;charset=UTF-8;',
        ...auth
    } as DRequestHeaders

    return config
})

export function get<Type extends ApiGetType>(...[api, params]: RequestArguments<Type>): Promise<Type['return']> {
    return request(api, 'get', params)
}

export function post<Type extends ApiPostType>(...[api, params, noTip = false]: RequestArguments<Type>): Promise<Type['return']> {
    return request(api, 'post', params, noTip)
}
