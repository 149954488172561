import { post } from 'utils'

interface IApiDepositInternalVerify {
    api: 'deposit/internal_verify'
    method: 'post'
    params: {
        depositId: string
        verifyCode: string
    }
    return: {
        errcode?: number
    }
}

export const ApiDepositInternalVerify = async (params: IApiDepositInternalVerify['params']) => {
    return await post<IApiDepositInternalVerify>('deposit/internal_verify', { ...params })
}
