import { post } from 'utils'
export interface ApiGoogleLoginTokenInterface {
    api: 'google_login/token'
    method: 'post'
    params: {
        /** ID令牌 */
        idToken: string
        /** session id */
        sId: string
    }
    return: {
        accessToken: string
        expires: number
        signSecret: string
        showUid: number
    }
}
export const ApiGoogleLoginToken = async (params: ApiGoogleLoginTokenInterface['params']) => {
    return await post<ApiGoogleLoginTokenInterface>('google_login/token', { ...params })
}
