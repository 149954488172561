import { Button } from '@douyinfe/semi-ui'
import { ValidateStatus } from '@douyinfe/semi-ui/lib/es/_base/baseComponent'
import { IGetAccountByInviteCode } from 'api'
import { ApiGoogleRegister, ApiTGbindAccount } from 'api/thirdLogin'
import { ApiGoogleBindAccount } from 'api/thirdLogin/googleBind'
import { ApiThirdRegister } from 'api/thirdLogin/thirdRegister'
import md5 from 'blueimp-md5'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import useBackUrl from 'hooks/useBackUrl'
import { CSSProperties, FC, useMemo, useRef, useState } from 'react'
import { ArrowDown, IconArrowUp, Telegram, WebGoogle } from 'react-dui-icons'
import { useSearchParams } from 'react-router-dom'
import useGoogleLoginParams from 'store/useGoogleLoginParams'
import useTelegramRegisterParams from 'store/useTelegramRegisterParams'
import { isPc, post, storage } from 'utils'
import { storeToken } from 'utils/token'
import FormItem from 'views/Auth/Register/components/FormItem/FormItem'
import FormTab from 'views/Auth/Register/components/FormTab/FormTab'
import { ApiOclrSendCaptchaParams } from 'views/Auth/Register/types/oclrSendCaptcha'

import styles from './ThirdRegisterForm.module.scss'

export interface IThirdRegisterFormProps {
    type: 'Telegram' | 'Google' | undefined | ''
    labelShow?: boolean
    thirdPreSetEmail?: string
}

const ThirdRegisterForm: FC<IThirdRegisterFormProps> = props => {
    const [searchParams] = useSearchParams()
    // state
    const [tab, setTab] = useState<'e' | 'p'>('e')
    const [email, setEmail] = useState('')
    const [emailDisabled, setEmailDisabled] = useState(false)
    const [emailVerification, setEmailVerification] = useState(false)
    const [phone, setPhone] = useState('')
    const [phoneDisabled, setPhoneDisabled] = useState(false)
    // 不带区号的纯净手机号
    const [purePhone, setPurePhone] = useState('')
    const [phoneVerification, setPhoneVerification] = useState(false)
    const [verificationCode, setVerificationCode] = useState('')
    const [verificationCodeOk, setVerificationCodeOk] = useState(false)
    const [inviteCode, setInviteCode] = useState(() => {
        return searchParams.get('inviteCode') || storage.getItem('res_invite_code') || ''
    })
    const [password, setPassword] = useState<string>('')
    const [passwordVerification, setPasswordVerification] = useState(false)
    const [inviteShow, setInviteShow] = useState<boolean>(!!inviteCode)
    const [inviteStatus, setInviteStatus] = useState<ValidateStatus>('default')
    const titleStyle: CSSProperties = isPc() ? {} : { marginBottom: '20px' }
    const { aop } = useBackUrl('', false)
    const [isNewAccount, setIsNewAccount] = useState(false)
    const [titleType, setTitleType] = useState<'login' | 'reg' | 'default'>('default')
    // store
    const telegramRegisterParams = useTelegramRegisterParams()
    const googleRegisterParams = useGoogleLoginParams()
    if (props.type === 'Telegram' && !telegramRegisterParams.params.regId) {
        window.location.reload()
    }

    // eslint-disable-next-line prettier/prettier
    const captchaRef = useRef({ captchaClick() {}, reCount() {} })

    // memo
    const pwdFC = useMemo(() => {
        console.log(isNewAccount)

        if (isNewAccount) {
            return (
                <FormItem
                    defaultVal={password}
                    type={'pwd'}
                    onChange={(v, b) => {
                        setPassword(v)
                        setPasswordVerification(b)
                    }}
                ></FormItem>
            )
        } else {
            return <></>
        }
    }, [isNewAccount])
    const account = useMemo(() => {
        if (tab === 'e') {
            return emailVerification ? email : ''
        } else {
            if (phoneVerification && phone.indexOf('+') > -1) {
                return phone.split('+')[1]
            } else {
                return ''
            }
        }
    }, [phone, email, tab])
    const verificationData = useMemo(() => {
        let data: ApiOclrSendCaptchaParams
        if (tab === 'e') {
            data = {
                captchaId: '',
                account: email,
                oclrMethod: 'email',
                oclrScene: 'register',
                cOtpType: 2
            }
        } else {
            const phoneArr = phone.split('+')
            data = {
                captchaId: '',
                areaCode: Number(phoneArr[0]),
                account: phoneArr[1],
                oclrMethod: 'phone',
                oclrScene: 'register',
                cOtpType: 1
            }
        }
        return data
    }, [phone, email])
    const createBtnDisabled = useMemo(() => {
        if (!verificationCodeOk) {
            return true
        }
        if (isNewAccount && !passwordVerification) {
            return true
        }
        if (tab === 'e') {
            return !emailVerification
        } else {
            return !phoneVerification
        }
    }, [tab, emailVerification, phoneVerification, verificationCodeOk, passwordVerification, isNewAccount])

    // func
    const checkInviteCode = () => {
        // google data
        window?.gtag?.('event', 'click_verity_invitation_code')
        // google data
        return new Promise<void>((resolve, reject) => {
            post<IGetAccountByInviteCode>('invite/inviter_account', {
                inviteCode
            })
                .then(data => {
                    if (data.account) {
                        setInviteStatus('success')
                        storage.setItem('res_invite_code', inviteCode)
                        storage.setItem('res_invite_account', data.account)
                        resolve()
                    } else {
                        setInviteStatus('error')
                        reject(new Error(''))
                    }
                })
                .catch(() => {
                    setInviteStatus('error')
                    if (inviteCode === storage.getItem('res_invite_code')) {
                        storage.delItem('res_invite_code')
                        storage.delItem('res_invite_account')
                    }
                    reject(new Error(''))
                })
        })
    }
    const register = () => {
        const countryCodeObj = tab === 'p' ? { countryCode: verificationData?.areaCode } : {}
        // @ts-ignore
        if (props.type === 'Telegram') {
            if (isNewAccount) {
                ApiThirdRegister({
                    regId: telegramRegisterParams.params.regId,
                    regSign: telegramRegisterParams.params.regSign,
                    pwd: md5(password),
                    account: verificationData.account,
                    captcha: verificationCode,
                    ...countryCodeObj
                }).then((res: any) => {
                    storeToken(res)
                    Toast.success({ content: t('Login succeeded'), showClose: false })
                    aop()
                })

                // post(api.thirdRegister, {
                //     regId: telegramRegisterParams.params.regId,
                //     regSign: telegramRegisterParams.params.regSign,
                //     pwd: md5(password),
                //     account: verificationData.account,
                //     captcha: verificationCode,
                //     ...countryCodeObj
                // }).then((res: any) => {
                //     storeToken(res)
                //     Toast.success(t('Login succeeded'))
                //     aop()
                // })
            } else {
                ApiTGbindAccount({
                    regId: telegramRegisterParams.params.regId,
                    regSign: telegramRegisterParams.params.regSign,
                    account: verificationData.account,
                    captcha: verificationCode,
                    ...countryCodeObj
                }).then((res: any) => {
                    storeToken(res)
                    Toast.success({ content: t('Login succeeded'), showClose: false })
                    aop()
                })
            }
        } else {
            const googleParams = {
                idToken: googleRegisterParams.idToken,
                sId: googleRegisterParams.sId,
                account: verificationData.account,
                captcha: verificationCode
            }
            if (isNewAccount) {
                ApiGoogleRegister({
                    ...googleParams,
                    pwd: md5(password),
                    ...countryCodeObj
                }).then(res => {
                    storeToken(res)
                    Toast.success({ content: t('Login succeeded'), showClose: false })
                    aop()
                })
            } else {
                ApiGoogleBindAccount({
                    ...googleParams,
                    ...countryCodeObj
                }).then((res: any) => {
                    storeToken(res)
                    Toast.success({ content: t('Login succeeded'), showClose: false })
                    aop()
                })
            }
        }
    }
    const onClick = () => {
        if (inviteCode) {
            checkInviteCode().then(() => register())
        } else {
            register()
        }
    }
    const returnTitle = () => {
        if (titleType === 'default') {
            return t('Authentication for account security')
        }
        if (titleType === 'login') {
            return t('Login')
        }
        if (titleType === 'reg') {
            return t('Create an Account')
        }
    }
    return (
        <div className={styles.thirdRegisterForm}>
            {props.labelShow && <div className={styles.thirdRegisterFormTitle}>{returnTitle()}</div>}
            <div
                className={styles.thirdLabel}
                style={titleStyle}
            >
                {props.type === 'Telegram' ? (
                    <Telegram
                        size={24}
                        className={styles.thirdLabelIcon}
                    ></Telegram>
                ) : null}
                {props.type === 'Google' ? (
                    <WebGoogle
                        size={24}
                        className={styles.thirdLabelIcon}
                    ></WebGoogle>
                ) : null}
                {props.type}
                <span className={styles.thirdLabelName}>{telegramRegisterParams.params.userName}</span>
            </div>
            <FormTab
                options={[
                    { label: t('Email Address'), value: 'e' },
                    { label: t('Phone number'), value: 'p' }
                ]}
                defaultVal={tab}
                onChange={v => {
                    const vC = v as 'e' | 'p'
                    setTab(vC as 'e' | 'p')
                    if (tab === 'e') {
                        setEmailDisabled(false)
                    } else {
                        setPhoneDisabled(false)
                    }
                    captchaRef.current.reCount?.()
                }}
            ></FormTab>
            <FormItem
                disabled={emailDisabled}
                show={tab === 'e'}
                defaultVal={email}
                type={'email'}
                onChange={(v, b) => {
                    setEmail(v)
                    setEmailVerification(b)
                }}
                onClearVerAccount={() => {
                    setEmailDisabled(false)
                    captchaRef.current.reCount?.()
                }}
                thirdPreSetEmail={props.thirdPreSetEmail}
            ></FormItem>
            <FormItem
                disabled={phoneDisabled}
                show={tab === 'p'}
                defaultVal={purePhone}
                type={'phone'}
                onPurePhoneChange={v => setPurePhone(v)}
                onChange={(v, b) => {
                    setPhone(v)
                    setPhoneVerification(b)
                }}
                onClearVerAccount={() => {
                    setPhoneDisabled(false)
                    captchaRef.current.reCount?.()
                }}
            ></FormItem>
            {pwdFC}
            <FormItem
                captchaCb={(_a, _b, isNewAccount) => {
                    if (tab === 'e') {
                        setEmailDisabled(true)
                    } else {
                        setPhoneDisabled(true)
                    }
                    setIsNewAccount(isNewAccount)
                    setTitleType(isNewAccount ? 'reg' : 'login')
                    console.log(isNewAccount, 'cacb')
                }}
                verificationData={verificationData}
                account={account}
                label={t('Verification Code')}
                type={'verification'}
                onChange={(val, valid) => {
                    setVerificationCode(val)
                    setVerificationCodeOk(valid)
                }}
                thirdType={props.type ?? 'Google'}
                captchaRef={captchaRef}
            ></FormItem>
            <FormItem
                defaultVal={inviteCode}
                inviteStatus={inviteStatus}
                style={{ marginBottom: '48px' }}
                label={
                    <span
                        className={styles.inviteLabelBox}
                        onClick={() => setInviteShow(pre => !pre)}
                    >
                        {t('Invite code(Optional)')}
                        {inviteShow ? (
                            <ArrowDown
                                size={16}
                                className={styles.inviteArrow}
                            ></ArrowDown>
                        ) : (
                            <IconArrowUp
                                size={16}
                                className={styles.inviteArrow}
                            ></IconArrowUp>
                        )}
                    </span>
                }
                showInp={inviteShow}
                onChange={v => {
                    setInviteCode(v)
                    if (v?.length === 0) {
                        setInviteStatus('default')
                    }
                }}
                type={'inviteCode'}
            ></FormItem>
            <div className={styles.operationItem}>
                <Button
                    size={'large'}
                    theme="solid"
                    type="primary"
                    disabled={createBtnDisabled}
                    onClick={onClick}
                    block
                >
                    {t('Confirm')}
                </Button>
            </div>
        </div>
    )
}
export default ThirdRegisterForm
