import { post } from 'utils'

export interface ApiContractListItem {
    instrumentName: string // 合约名称
    instrumentId: string // 合约ID
    showIcon: '0' | '1' // 是否展示图标 0:展示 1:不展示
    contractType: '0' | '1' // 合约类型:0永续合约 1:move合约
    priceChangePercent: string // 24小时内的涨跌幅
    newPrice: string // 折算成usdt的现价
    volume: string
}
export interface ApiContractListType {
    api: 'contract/list'
    method: 'post'
    return: {
        list: ApiContractListItem[]
    }
}

// 如果有入参，加上对应的params声明，传入就好了
export const ApiContractList = async () => {
    return await post<ApiContractListType>('contract/list')
}
