import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef } from 'react'
import { post } from 'utils'
import { getLocale, loadScript } from 'utils/tools'

import { api, BeCaptchaBody, BeCaptchaCallback, BeCaptchaPlate, ValidateBody } from './api'
import style from './Captcha.module.scss'

interface GoogleDataType {
    captchaId: string
    siteKey: string
}

export interface CaptchaMethods {
    click: () => void
}

const JIYAN = 1 // 极验
const WANGYI = 2 // 网易
const GOOGLE = 3 // 谷歌

interface CaptchaContainerProps extends WidgetClassName {
    children?: ReactNode
    /** 用户账号 */
    account: string
    /**
     * 一个页面中不可同时初始化两个<Captcha />组件，否则会相互干扰；
     * 因此务必为不在使用的<Captcha />组件传入disabled: true
     */
    disabled?: boolean
    /** 回调函数：请勿传入含有状态的函数，否则该状态不会随react更新 */
    callback: (captchaId: string, account: string) => void
    /** 谷歌验证时会调用并传入true，由于谷歌验证无感，可使用该方法展示加载动画 */
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

type CaptchaType = React.ForwardRefExoticComponent<CaptchaContainerProps & React.RefAttributes<CaptchaMethods>> & {
    /** 是否已经加载网易/极验的脚本 */
    hasInsertJs?: boolean
    /** 用户账号 */
    account?: string
    /** 网易验证的对象 */
    wangyiObj?: any
    /** 极验验证的对象 */
    jiyanObj?: any
    /** 平台类型和id */
    plateData?: BeCaptchaPlate
    /** 谷歌数据 */
    googleData?: GoogleDataType
    /** 外部传入的回调，在行为验证成功后调用 */
    callback?: (captchaId: string, account: string) => void
    /** 重试次数 */
    retryCount?: number
    /** 重新获取平台的次数 */
    requestCount?: number
}

/**
 * 行为验证各接口作用
 * beCaptcha/plate：用于决定使用哪个平台
 * beCaptcha：用于获取captchaId等参数
 * beCaptcha/validate：将行为验证结果发送至后台验证
 */

/**
 * 网易/级验/谷歌 行为认证
 *
 * 务必为不在使用的<Captcha />组件传入disabled: true
 */
export const Captcha: CaptchaType = forwardRef<CaptchaMethods, CaptchaContainerProps>(({ className, children, account = '', disabled, callback, setLoading }, ref) => {
    const captchaRef = useRef<HTMLDivElement>(null)
    const locale = getLocale()

    /** 行为验证结果处理，成功时调用外部回调 */
    const onValidate = async (isSuccess: boolean, type: 1 | 2 | 3, captchaId?: string): Promise<void> => {
        return new Promise(resolve => {
            if (isSuccess) {
                Captcha.retryCount = 0
                setTimeout(() => setLoading && setLoading(false), 500)
                // 成功回调
                if (captchaId && Captcha.callback) {
                    Captcha.callback(captchaId, Captcha.account ?? '')
                }
                // 重新初始化
                getPlate().then(() => resolve())
            } else {
                // 失败切换平台
                getPlate(type).then(() => resolve())
            }
        })
    }

    /**
     * 网易验证
     * @param plateId 平台id
     * @param setwtestId  captcha_id
     * @param validate sdk返回的参数，平台为极验和网易易盾时要传
     */
    const wangyiHandler = async (plateId: string, setwtestId: string, validate: string): Promise<void> => {
        const body: ValidateBody = {
            plateId,
            captchaId: setwtestId,
            clientType: 'web',
            validate,
            account: Captcha.account ?? ''
        }

        try {
            // @ts-ignore
            const resp = await post(api.beCaptchaValidate, body)
            // 请求成功才回调
            if (!resp?.errcode) await onValidate(true, WANGYI, setwtestId)
        } catch (e) {
            console.error(e)
            await onValidate(false, WANGYI)
        } finally {
            Captcha.wangyiObj.refresh()
        }
    }

    // 极验验证
    const jiyanHandler = async (result: any, plateId: string, geetestId: string) => {
        try {
            // const result = Captcha.jiyanObj.getValidate()

            if (!result) {
                Toast.info({ content: t('310055', { ns: 'errCode' }), showClose: false } /** 行为验证失败 */)
                throw new Error(t('310055', { ns: 'errCode' }))
            }

            const body: ValidateBody = {
                plateId,
                captchaId: geetestId,
                clientType: 'web',
                lotNumber: result?.lot_number ?? '',
                captchaOutput: result?.captcha_output ?? '',
                passToken: result?.pass_token ?? '',
                genTime: result?.gen_time ?? '',
                account: Captcha.account ?? ''
            }
            // @ts-ignore
            const resp = await post(api.beCaptchaValidate, body)
            // 请求成功才回调
            if (!resp?.errcode) await onValidate(true, JIYAN, geetestId)
        } catch (e) {
            console.error(e)
            Toast.info({ content: t('310055'), showClose: false } /** 行为验证失败 */)
            await onValidate(false, JIYAN)
        }
    }

    /**
     * google验证
     * @param plateId id
     * @param captchaId  captcha_id
     * @param validate sdk，
     */
    const googleHandler = async (plateId: string, captchaId: string, gRecaptchaResponse: string): Promise<void> => {
        const body: ValidateBody = {
            plateId,
            captchaId,
            validate: '123',
            clientType: 'web',
            response: gRecaptchaResponse,
            account: Captcha.account ?? ''
        }

        try {
            // @ts-ignore
            await post(api.beCaptchaValidate, body)
            await onValidate(true, GOOGLE, captchaId)
        } catch (e) {
            console.error(e)
            await onValidate(false, GOOGLE)
            // 谷歌验证失败时，等待切换平台，然后自动重试，重试次数不超过2次
            if (typeof Captcha.retryCount === 'number' && Captcha.retryCount < 2) {
                captchaRef.current?.click()
                Captcha.retryCount++
            } else {
                Captcha.retryCount = 0
                Toast.info({ content: t('310055', { ns: 'errCode' }), showClose: false } /** 行为验证失败 */)
            }
        }
    }

    /** 极验初始化 */
    const jiyanInitialize = (data: BeCaptchaCallback): Promise<void> => {
        // 其他语言不生效
        const langMap: any = {
            'zh-hk': 'zho-hk'
        }
        return new Promise((resolve, reject) => {
            if (!window.initGeetest4) {
                reject(new Error('window.initGeetest4 is undefined'))
                return
            }
            window.initGeetest4(
                {
                    captchaId: data.captchaKey,
                    product: 'bind',
                    language: langMap?.[locale] ?? 'eng'
                },
                function (captchaObj: any) {
                    captchaObj
                        .onReady(function () {
                            Captcha.jiyanObj = captchaObj
                            // 验证码ready之后才能调用showCaptcha方法显示验证码
                            // captchaObj.showCaptcha()
                        })
                        .onSuccess(function () {
                            const result = captchaObj.getValidate()
                            jiyanHandler(result, Captcha.plateData?.plateId ?? '', data.captchaId)
                            // your code,结合您的业务逻辑重置验证码
                            Captcha.jiyanObj.reset()
                        })
                        .onError(function (err: any) {
                            // your code
                            Captcha.jiyanObj.reset()
                            console.error(err)
                            onValidate(false, JIYAN)
                        })
                }
            )
        })
    }

    /** 网易初始化 */
    const wangyiInitialize = (data: BeCaptchaCallback): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!window.initNECaptcha) {
                reject(new Error('window.initNECaptcha is undefined'))
                return
            }
            window.initNECaptcha(
                {
                    element: '#captcha',
                    captchaId: data.captchaKey,
                    mode: 'popup',
                    width: '280px',
                    lang: locale.indexOf('zh') !== -1 ? 'zh-CN' : 'en',
                    onVerify: function (err: any, dataVal: any) {
                        if (err) return
                        // 成功回调
                        wangyiHandler(Captcha.plateData?.plateId ?? '', data.captchaId, dataVal.validate)
                    }
                },
                function (instance: any) {
                    // 初始化成功后得到验证实例instance，可以调用实例的方法
                    Captcha.wangyiObj = instance
                    resolve()
                },
                function (err: any) {
                    // 初始化失败后触发该函数，err对象描述当前错误信息
                    getPlate(WANGYI)
                    Captcha.wangyiObj && Captcha.wangyiObj.refresh()
                    console.error(err)
                    reject(err)
                }
            )
        })
    }

    /** 谷歌初始化 */
    const googleInitialize = async (data: BeCaptchaCallback): Promise<void> => {
        Captcha.googleData = { captchaId: data.captchaId, siteKey: data.siteKey }
        await loadScript(`https://www.google.com/recaptcha/api.js?render=${data.siteKey}`)
    }

    /** 初始化平台 */
    const initialize = async ({ plateId, plateType }: BeCaptchaPlate): Promise<void> => {
        const body: BeCaptchaBody = { plateId, clientType: 'web' }
        // @ts-ignore
        const data = await post<BeCaptchaCallback>(api.beCaptcha, body)

        try {
            if (plateType === JIYAN) {
                // @ts-ignore
                await jiyanInitialize(data)
            } else if (plateType === WANGYI) {
                // @ts-ignore
                await wangyiInitialize(data)
            } else if (plateType === GOOGLE) {
                // @ts-ignore
                await googleInitialize(data)
            }
        } catch (e) {
            console.error(e)
            getPlate(plateType)
        }
    }

    /**
     * 获取/切换平台
     * @param plateType 不传时，后台随机返回一个平台；传入时，返回结果不会是该平台
     */
    const getPlate = async (plateType: number | string = ''): Promise<void> => {
        if (typeof Captcha.requestCount === 'number' && Captcha.requestCount > 2) {
            console.error('captcha error.')
            Captcha.requestCount = 0
            return
        }

        if (typeof Captcha.requestCount === 'number') {
            Captcha.requestCount++
        }

        if (!Captcha.hasInsertJs) {
            setLoading && setLoading(true)
            await Promise.all([loadScript('https://static.geetest.com/v4/gt4.js'), loadScript('https://cstaticdun.126.net/load.min.js')])
                .then(() => {
                    Captcha.hasInsertJs = true
                })
                .catch(e => {
                    console.error(e)
                })
        }

        setLoading && setLoading(true)
        // @ts-ignore
        const data = await post<BeCaptchaPlate>(api.beCaptchaPlate, { plateType })
        // @ts-ignore
        Captcha.plateData = data
        // @ts-ignore
        await initialize(data)
        setLoading && setLoading(false)
    }

    /** 调用谷歌行为验证 */
    const googleVerify = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!window.grecaptcha) {
                reject(new Error('Google recaptcha is not ready.'))
                return
            }
            window.grecaptcha.ready(async () => {
                if (!Captcha.plateData || !Captcha.googleData) {
                    reject(new Error('No plateData or no googleData'))
                } else {
                    try {
                        setLoading && setLoading(true)
                        const token = await window.grecaptcha.execute(Captcha.googleData.siteKey, { action: 'submit' })
                        await googleHandler(Captcha.plateData.plateId, Captcha.googleData.captchaId, token)
                        resolve()
                    } catch (e) {
                        reject(e)
                    } finally {
                        setLoading && setLoading(false)
                    }
                }
            })
        })
    }

    /** 点击回调 */
    const onCaptchaClick = async () => {
        if (Captcha.account === '') {
            Toast.info({ content: t('Web_Index_EnterNumberOrMailbox'), showClose: false } /** 请输入手机号码或邮箱 */)
            return
        }

        try {
            if (!Captcha.plateData) {
                throw new Error('No plateData')
            }
            if (Captcha.plateData.plateType === JIYAN) {
                Captcha.jiyanObj.showCaptcha()
            } else if (Captcha.plateData.plateType === WANGYI) {
                Captcha.wangyiObj.popUp()
            } else if (Captcha.plateData.plateType === GOOGLE) {
                await googleVerify()
            }
        } catch (e) {
            /** 失败切换平台 */
            getPlate(Captcha.plateData?.plateType ?? '')
            console.error(e)
        }
    }

    useImperativeHandle(ref, () => {
        return {
            click: () => {
                captchaRef.current?.click()
            }
        }
    })

    useEffect(() => {
        if (!Captcha.hasInsertJs) {
            Captcha.retryCount = 0
            Captcha.requestCount = 0

            Promise.all([loadScript('https://static.geetest.com/v4/gt4.js'), loadScript('https://cstaticdun.126.net/load.min.js')])
                .then(() => {
                    Captcha.hasInsertJs = true
                })
                .catch(e => {
                    console.error(e)
                })
                .finally(() => {
                    return getPlate()
                })
        }
    }, [])

    useEffect(() => {
        // 激活时更新account和callback
        if (!disabled) {
            Captcha.account = account
            Captcha.callback = callback
        }
    }, [account, callback, disabled])

    return (
        <div
            className={classNames(className, style.captchaContainer)}
            onClick={() => onCaptchaClick()}
            ref={captchaRef}
        >
            {!!children && children}
            {/* 行为验证 */}
            <div id="captcha" />
        </div>
    )
})

export default Captcha
