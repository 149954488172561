import { domain } from 'config/domain.config'

import { locale } from './locale.config'

export const rootLangExprationTime = 24 * 60 * 60 * 1000 * 30 // 以毫秒为单位，我们假设修改多语言 key 内容的操作频率极低

export const cookieOptions = { domain: `.${window.location.hostname.split('.').slice(-2).join('.')}`, path: '/' }

export const downloadLink = `https://www.${domain}/${locale}/download`

// toast id 固定id，只弹一个
export const toastSuccessID = 'toastSuccessID'
export const toastErrorID = 'toastErrorID'
export const toastInfoID = 'toastInfoID'

// 收藏
export const collectionStoragekey = 'favorites'
export const collectionSwapStoragekey = 'favoritesSwap'
