import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import copy from 'copy-to-clipboard'
import { TFunction } from 'i18next'
import { ReactNode, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CheckCircleOutlinedFill, Copy } from 'react-dui-icons'

import style from './CommonCopy.module.scss'

interface Props {
    text: string
    callback?: () => void
    size?: number
    tips?: boolean // 是否弹toast提示
    children?: ReactNode
}

export const CopyLinkHandleCard = (text: string, callback?: () => void): void => {
    const copied = copy(text)
    if (copied) {
        callback?.()
    }
}

export const CopyLinkHandle = (text: string, t: TFunction): void => {
    const copied = copy(text)
    if (copied) {
        Toast.success({
            content: t('Copy Succeeded'),
            duration: 3000
        })
    }
}

// 用户直接在copy图标交互的场景
const CommonCopy = ({ text, callback, size = 16, tips = false, children }: Props): JSX.Element => {
    const [isCopy, setIsCopy] = useState(false)

    return (
        <CopyToClipboard
            style={{ cursor: 'pointer' }}
            onCopy={() => {
                callback?.()
                setIsCopy(true)
                setTimeout(() => {
                    setIsCopy(false)
                }, 1000)
                if (tips) {
                    Toast.success({ content: t('Copy Succeeded'), showClose: false, id: 'common_copy' })
                }
            }}
            text={text}
        >
            {children ||
                (isCopy ? (
                    <CheckCircleOutlinedFill
                        size={size}
                        className={style.icon}
                    />
                ) : (
                    <Copy
                        size={size}
                        onClick={e => e.stopPropagation()}
                    />
                ))}
        </CopyToClipboard>
    )
}
export default CommonCopy
