import { post } from 'utils'

export enum RevisionRankType {
    Hot = 'hot',
    New = 'new',
    Gainers = 'rise', // 涨跌榜
    Turnover = 'trade' // 交易量榜
}

export interface IApiRevisionRankInfoItem {
    baseId: string
    countDown: string
    currencyId: number
    currencyLogo: string
    dayChangeRate: string
    oneChange: string
    fourChange: string
    dayChangeStatus: number
    dayDoneNum: string
    newPrice: string
    onlineDate: string
    takeDown: number
    tradePair: string
    kd?: string[]
}

export interface IApiRevisionRankInfoType {
    api: 'revision/v1/rank_info'
    method: 'post'
    params: {
        rankType: RevisionRankType
    }
    return: IApiRevisionRankInfoItem[]
}

export const ApiRevisionRankInfo = async (params: IApiRevisionRankInfoType['params']) => {
    return await post<IApiRevisionRankInfoType>('revision/v1/rank_info', { ...params })
}
