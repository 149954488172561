import { post } from 'utils'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

const useLoginData = create<RegisterConfig, any>(
    devtools(
        set => {
            return {
                thirdParty: undefined,
                phoneCode: {
                    filteredAll: [],
                    areaConfig: undefined,
                    all: [],
                    recommend: []
                },
                loadingThirdParty() {
                    const subDomain = window.location.hostname.split('.')[0]
                    // @ts-ignore
                    post('third/info', {
                        domainType: subDomain === 'dev' || subDomain === 'opts' ? 'www' : subDomain
                    }).then((res: any) => {
                        set(state => ({ ...state, thirdParty: res }))
                    })
                },
                getPhoneCode() {
                    post('register/region_code').then((data: any) => {
                        data.filteredAll = data.all.filter((item: any) => item.disable === 0)
                        set(state => ({ ...state, phoneCode: data }))
                    })
                }
            }
        },
        { name: 'useLoginData', enabled: import.meta.env.DEV }
    )
)

export default useLoginData
