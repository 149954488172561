import loadable from '@loadable/component'
import { HeaderProps } from 'components/Header/Header'
import { locale } from 'config'
import AppTelegramLogin from 'views/AppTelegramLogin/AppTelegramLogin'
import Login from 'views/Auth/Login/Login'
import Register from 'views/Auth/Register/Register'
import ValidateLogon from 'views/Auth/ValidateLogon/ValidateLogon'
import Home from 'views/Home/index'

import { activeRouter } from './active'
import { userRouter } from './user'

export type LAYOUT = {
    header?: HeaderProps
    showFooter?: boolean
    showHeader?: boolean
}

export type RouterItem = {
    name: string
    path: string
    children?: Array<RouterItem>
    component?: React.ComponentType<any>
    layout?: LAYOUT // 路由基础layout配置，如header, footer, 订制页面
    hidden?: boolean
    redirectTo?: string
    meta?: {
        title: string
        icon?: JSX.Element
        /** 允许访问的权限 如 代理商/子帐号 等，如只有一级则传 【'user'】 */
        permission?: Array<string>
    }
}
//
const commonPath = `/${locale}`

const routeList: Array<RouterItem> = [
    {
        name: 'home',
        path: '/:locale',
        component: Home,
        layout: { showHeader: true }
    },
    // {
    //     name: 'home',
    //     path: `${commonPath}`,
    //     component: Home,
    //     layout: { showHeader: true }
    // },
    {
        name: '404',
        path: `${commonPath}/404`,
        component: loadable(() => import('views/Auth/NotFound/NotFound')),
        layout: { showHeader: false }
    },
    {
        name: 'register',
        path: `${commonPath}/register`,
        component: Register,
        layout: { header: { showNav: true, showReg: false } }
    },
    {
        name: 'download',
        path: `${commonPath}/download`,
        component: loadable(() => import('views/Download')),
        layout: { header: { showNav: true, showReg: true } }
    },
    {
        name: 'download',
        path: `${commonPath}/splash`,
        component: loadable(() => import('views/Download')),
        layout: { header: { showNav: true, showReg: true } }
    },
    {
        name: 'login',
        path: `${commonPath}/login`,
        component: Login,
        layout: { header: { showNav: true, showReg: false } }
    },
    {
        name: 'validateLogon',
        path: `${commonPath}/validateLogon`,
        component: ValidateLogon
    },
    {
        name: 'resetPwd',
        path: `${commonPath}/resetPwd`,
        component: loadable(() => import('views/Auth/ResetPwd/ResetPwd'))
    },
    {
        name: 'landing', // 投放落地页
        path: `${commonPath}/landing/:activeId`,
        component: loadable(() => import('views/Auth/Landing/Landing'))
    },
    // {
    //     name: 'cardActivateSuccess',
    //     path: `${commonPath}/card/activate/success`,
    //     component: loadable(() => import('views/Card/pages/Result/Result'))
    // },
    {
        name: 'appTgLogin',
        path: `${commonPath}/tgLoginForApp`,
        component: AppTelegramLogin,
        layout: { showHeader: false }
    },
    {
        name: 'cgtListingEvent',
        path: `${commonPath}/cgt-listing-event`,
        component: loadable(() => import('../views/CgtEvent')),
        layout: { showHeader: false }
    },
    {
        name: 'Deposit',
        path: `${commonPath}/deposit`,
        component: loadable(() => import('../views/Pay/Deposit/Deposit')),
        layout: { showHeader: true }
    },
    {
        name: 'Withdraw',
        path: `${commonPath}/withdraw`,
        component: loadable(() => import('../views/Pay/Withdraw/Withdraw'))
    },
    {
        name: 'Withdraw',
        path: `${commonPath}/withdraw/:currency`,
        component: loadable(() => import('../views/Pay/Withdraw/Withdraw'))
    },
    {
        name: 'fees',
        path: `${commonPath}/fees`,
        component: loadable(() => import('views/Fees/Fees'))
    },
    {
        name: 'career',
        path: `${commonPath}/career`,
        component: loadable(() => import('views/Career/Career'))
    },
    {
        name: 'careerDetail',
        path: `${commonPath}/career/:id`,
        component: loadable(() => import('views/Career/Detail/Detail'))
    },
    {
        name: 'listing',
        path: `${commonPath}/listing`,
        component: loadable(() => import('views/Listing/Listing'))
    },
    {
        name: 'markets',
        path: `${commonPath}/markets`,
        component: loadable(() => import('views/Markets/Markets'))
    },
    {
        name: 'ranking',
        path: `${commonPath}/ranking/:type`,
        component: loadable(() => import('views/Ranking/Ranking'))
    },
    {
        name: 'coupon',
        path: `${commonPath}/coupon`,
        component: loadable(() => import('views/Coupon/index'))
    },
    {
        name: 'invite',
        path: `${commonPath}/invite-friends`,
        component: loadable(() => import('views/Invite/Invite'))
    },
    ...userRouter,
    ...activeRouter
]

export { commonPath, routeList }
