import { Space } from '@douyinfe/semi-ui'
import { useInViewport } from 'ahooks'
import { ApiVersionAll } from 'api/home/ApiVersionAll'
import { QRCodeWithLogo } from 'components/QRCodeWithLogo/QRCodeWithLogo'
import { domain, locale } from 'config'
import { useEffect, useRef, useState } from 'react'
// import { ApiVersionAll } from 'api/home/ApiVersionAll'
import { AndroidFill, GoogleplayBlack, IphoneBlack } from 'react-dui-icons'

// import qrcodeImg from './assets/demo_qrcode.png'
import downloadImg from './assets/download.svg'
import style from './Download.module.scss'

// TODO 跳转链接 图片
const Download = ({ order = 0 }): JSX.Element => {
    console.log(order)
    const ref = useRef<HTMLDivElement>(null)
    const [isInViewport] = useInViewport(ref)
    const [hasEnteredViewport, setHasEnteredViewport] = useState(false)
    const [andUrl, setAndUrl] = useState({ downloadUrl: '' })

    // TODO app store link
    // TODO google play link
    const DATA = [
        {
            icon: <IphoneBlack size={24} />,
            text: t('paro:App Store')
        },
        {
            icon: <GoogleplayBlack size={24} />,
            text: t('paro:Google Play')
        },
        {
            icon: <AndroidFill size={24} />,
            text: t('paro:Android'),
            link: `https://dl.parobit.com/app/${andUrl.downloadUrl}`
        },
        {
            icon: <IphoneBlack size={24} />,
            text: t('paro:TestFlight')
        }
    ]

    async function getDownloadUrl() {
        const resp = await ApiVersionAll()
        setAndUrl(resp?.android)
    }

    useEffect(() => {
        getDownloadUrl()
    }, [])

    useEffect(() => {
        if (isInViewport && !hasEnteredViewport) {
            setHasEnteredViewport(true)
        }
    }, [isInViewport, hasEnteredViewport])

    return (
        <div
            className={classNames({ [style.root]: true, [style.enter]: hasEnteredViewport })}
            ref={ref}
        >
            <img
                src={downloadImg}
                alt=""
                className={style.mainImg}
            />
            <Space
                vertical
                align="start"
                className={style.right}
            >
                <h1>{t('paro:Trading Could be Anytime, Anywhere.')}</h1>
                <Space className={style.download}>
                    <QRCodeWithLogo value={`https://www.${domain}/${locale}/n/download`} />
                    <Space
                        vertical
                        align="start"
                        className={style.types}
                    >
                        <h3>{t('paro:Scan to Download ')}</h3>
                        <h1>{t('paro:iOS & Android')}</h1>
                        <Space className={style.items}>
                            {DATA.map((item, index) => (
                                <div
                                    className={style.item}
                                    key={index}
                                    onClick={() => window.open(item?.link)}
                                >
                                    {item.icon}
                                    <small>{item.text}</small>
                                </div>
                            ))}
                        </Space>
                    </Space>
                </Space>
            </Space>
        </div>
    )
}

export default Download
