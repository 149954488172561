import { Button } from '@douyinfe/semi-ui'
import { ApiTokenCreate } from 'api'
import md5 from 'blueimp-md5'
import AgreementBox from 'components/AgreementBox/AgreementBox'
import ExclamationModal from 'components/ExclamationModal/ExclamationModal'
import GoogleLoginButton from 'components/GoogleLoginBtn/GoogleLoginBtn'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import TelegramLoginBtn from 'components/TelegramLoginBtn/TelegramLoginBtn'
import { locale } from 'config'
import { useWindowSize } from 'hooks'
import useBackUrl from 'hooks/useBackUrl'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useGoogleLoginParams from 'store/useGoogleLoginParams'
import useLoginData from 'store/useLoginData'
import useSecondaryVerification from 'store/useSecondaryVerification'
import useTelegramRegisterParams from 'store/useTelegramRegisterParams'
import { getRegExp, isPc, post, safeSprintf, storage } from 'utils'
import { storeToken } from 'utils/token'
import { ISecondaryVerification } from 'views/Auth/Login/types/ISecondaryVerification'
import { api } from 'views/Auth/Register/api/api'
import ApiOclrLoginRegisterParams from 'views/Auth/Register/api/ApiOclrLoginRegisterParams'
import FormItem from 'views/Auth/Register/components/FormItem/FormItem'
import FormTab from 'views/Auth/Register/components/FormTab/FormTab'
import LoginQR from 'views/Auth/Register/components/LoginQR/LoginQR'
import ThirdRegisterForm from 'views/Auth/Register/components/ThirdRegisterForm/ThirdRegisterForm'
import { ApiOclrSendCaptchaParams } from 'views/Auth/Register/types/oclrSendCaptcha'

import styles from './LoginForm.module.scss'

const LoginForm = (props: { isSide?: boolean; setType?: (type: 'login' | 'reg') => void }): JSX.Element => {
    const { isSide = false, setType } = props // 侧边栏模式
    const navigate = useNavigate()
    const secondaryVerification = useSecondaryVerification()
    // state
    // email or phone or password
    const [tab, setTab] = useState<'e' | 'p' | 'pwd' | 'qr'>('pwd')
    const [account, setAccount] = useState('')
    const [email, setEmail] = useState<string>('')
    const [emailDisabled, setEmailDisabled] = useState(false)
    const [emailVerification, setEmailVerification] = useState(false)
    const [password, setPassword] = useState<string>('')
    const [passwordVerification, setPasswordVerification] = useState(false)
    const [agreement, setAgreement] = useState(false)
    const [agreementTip, setAgreementTip] = useState(false)
    const [phone, setPhone] = useState<string>('')
    // 不带区号的phone
    const [purePhone, setPurePhone] = useState('')
    const [phoneVerification, setPhoneVerification] = useState(false)
    const [phoneDisabled, setPhoneDisabled] = useState(false)
    const [verificationCode, setVerificationCode] = useState('')
    const [verificationCodeOk, setVerificationCodeOk] = useState(false)
    const [captchaId, setCaptchaId] = useState('')
    const [tipPopShow, setTipPopShow] = useState(false)
    // 侧边栏模式下为刷新当前页
    const { aop, backUrl } = useBackUrl(isSide ? window?.location?.pathname : '')
    const [activeBox, setActiveBox] = useState<'reg' | 'third'>('reg')
    const [thirdType, setThirdType] = useState<'Telegram' | 'Google' | ''>('')
    const [thirdEmail, setThirdEmail] = useState('')
    const loginData = useLoginData()
    const googleLoginParams = useGoogleLoginParams(state => state)
    const telegramRegisterParams = useTelegramRegisterParams(state => state)
    const { isMobile } = useWindowSize()
    // memo
    const loginBtnDisabled = (() => {
        // if (!agreement) {
        //     return true
        // }
        if (tab === 'e') {
            return !(emailVerification && verificationCodeOk)
        } else if (tab === 'p') {
            return !(phoneVerification && verificationCodeOk)
        } else if (tab === 'pwd') {
            return !passwordVerification
        }
    })()
    const verAccount = useMemo(() => {
        if (tab === 'e') {
            return emailVerification ? email : ''
        } else if (tab === 'p') {
            if (phoneVerification && phone.indexOf('+') > -1) {
                return phone.split('+')[1]
            } else {
                return ''
            }
        } else {
            return account?.length > 0 ? account : ''
        }
    }, [tab, email, emailVerification, phone, phoneVerification, account])
    const verData = (() => {
        let data: ApiOclrSendCaptchaParams
        if (tab === 'e') {
            data = {
                captchaId,
                account: email,
                oclrMethod: 'email',
                oclrScene: 'login',
                cOtpType: 2
            }
        } else if (tab === 'p') {
            const phoneArr = phone.split('+')
            data = {
                captchaId,
                areaCode: Number(phoneArr[0]),
                account: phoneArr[1],
                oclrMethod: 'phone',
                oclrScene: 'login',
                cOtpType: 1
            }
        } else {
            data = {
                captchaId: '',
                account,
                oclrMethod: 'password',
                oclrScene: 'login'
            }
        }
        return data
    })()

    // ref
    const captchaRef = useRef({
        captchaClick: () => {},
        reCount: () => {}
    })

    const registerCb = (userName: string, regId: string, regSign: string) => {
        // 新帐号，跳转登录页
        window?.gtag?.('event', 'click_signup_with_telegram')
        // google data
        telegramRegisterParams.update({ userName, regId, regSign })
        setActiveBox('third')
        setThirdType('Telegram')
    }
    const loginCb = (token: ApiTokenCreate['return']) => {
        // google data
        window?.gtag?.('event', 'click_login_with_telegram')
        // google data
        storeToken(token)
        Toast.success({ content: t('Web_Login_SuccessToast'), showClose: false })
        aop()
    }
    const googleRegisterCb = (sId: string, idToken: string, email?: string) => {
        window?.gtag?.('event', 'click_login_with_google')
        googleLoginParams.update(sId, idToken)
        setThirdEmail(email ?? '')
        // console.log('fjaewiof@!12312.com')
        setActiveBox('third')
        setThirdType('Google')
    }

    const checkSubmit = () => {
        if (!agreement) {
            setAgreementTip(true)
            return false
        }

        setAgreementTip(false)
        return true
    }
    // func
    const submit = () => {
        if (!checkSubmit()) {
            return false
        }
        // google data
        window?.gtag?.('event', 'click_login_submit', {
            way: verData.oclrMethod
        })
        // google data
        const data = {} as ApiOclrLoginRegisterParams
        data.oclrScene = verData.oclrScene
        data.account = verData.account
        data.oclrMethod = verData.oclrMethod
        switch (tab) {
            case 'e':
                data.captcha = verificationCode
                break
            case 'pwd':
                data.pwd = md5(password)
                break
            case 'p':
                data.captcha = verificationCode
                data.areaCode = verData.areaCode
                break
        }
        // @ts-ignore
        post(api.oclrLoginRegister, data).then((res: any) => {
            if (res?.errcode) {
                const errCodeStr = String(res?.errcode)
                // eslint-disable-next-line no-case-declarations
                const data = {} as ISecondaryVerification

                if (['200008', '200009'].includes(errCodeStr)) {
                    switch (errCodeStr) {
                        case '200008':
                            // 需要二次验证
                            // eslint-disable-next-line no-case-declarations
                            let sendType = '' as ISecondaryVerification['sendType']
                            // 二次验证账号
                            // eslint-disable-next-line no-case-declarations
                            let templateAccount = ''
                            // eslint-disable-next-line no-case-declarations
                            const needGoogle = !!res?.data?.needGoogleCaptcha
                            // eslint-disable-next-line no-case-declarations
                            const needSendPhone: string = res?.data?.needSendPhone ?? ''
                            // eslint-disable-next-line no-case-declarations
                            const needSendEmail: string = res?.data?.needSendEmail ?? ''
                            // eslint-disable-next-line no-case-declarations
                            let needSendAccount = ''
                            if (tab === 'pwd') {
                                // 子账号
                                templateAccount = account
                                // 有绑定的邮箱或者手机
                                if ('needSendType' in res.data) {
                                    if (res?.data?.needSendType === 1) {
                                        sendType = 'phone'
                                    } else if (res?.data?.needSendType === 2) {
                                        sendType = 'email'
                                    }

                                    if (res?.data?.needSendAccount) {
                                        needSendAccount = res?.data?.needSendAccount
                                    }
                                } else {
                                    if (getRegExp('phone').test(account)) {
                                        sendType = 'phone'
                                    } else if (getRegExp('email').test(account)) {
                                        sendType = 'email'
                                    }
                                }
                            } else if (tab === 'e') {
                                templateAccount = email
                                sendType = 'email'
                            } else {
                                templateAccount = phone
                                sendType = 'phone'
                            }
                            data.needSendPhone = needSendPhone
                            data.needSendEmail = needSendEmail
                            data.needGoogle = needGoogle
                            data.captchaId = verData.captchaId
                            data.account = templateAccount
                            data.oclrMethod = verData.oclrMethod
                            data.needSendAccount = needSendAccount
                            if (verData.oclrMethod === 'email') {
                                data.cOtpType = 2
                            } else if (verData.oclrMethod === 'phone') {
                                data.cOtpType = 1
                                data.areaCode = verData.areaCode
                            } else {
                                data.pwd = md5(password)
                            }
                            data.sendType = sendType
                            data.oclrScene = 'login'
                            data.needVerificationCode = true
                            break
                        case '200009':
                            // eslint-disable-next-line no-case-declarations
                            data.needGoogle = true
                            data.needVerificationCode = false
                            data.account = verData.account
                            data.captcha = verificationCode
                            if (verData.oclrMethod === 'email') {
                                data.oclrMethod = 'email'
                                data.cOtpType = 2
                            } else if (verData.oclrMethod === 'phone') {
                                data.oclrMethod = 'phone'
                                data.cOtpType = 1
                                data.areaCode = verData.areaCode
                            } else {
                                data.oclrMethod = 'password'
                                data.pwd = md5(password)
                            }
                            break
                    }
                    secondaryVerification.update(data)
                    setTimeout(() =>
                        navigate(`/${locale}/validateLogon?backUrl=${backUrl}`, {
                            state: {
                                secondVer: true
                            }
                        })
                    )
                }
                if (errCodeStr === '200084' || errCodeStr === '200085' || errCodeStr === '200086') {
                    /** 200084: 账号或密码错误 200085: 验证码错误 200086: 谷歌验证码错误 */
                    const text = safeSprintf(t(errCodeStr, { ns: 'errCode' }), res?.data?.args[0])
                    Toast.error({ content: text, showClose: false })
                }
            } else {
                storeToken(res)
                Toast.success({ content: t('Log in successfully'), showClose: false })
                storage.setItem('last-login-type', tab, 'local')
                aop()
            }
        })
    }
    useEffect(() => {
        loginData.loadingThirdParty()
        const lastLoginType = storage.getItem('last-login-type')
        if (lastLoginType) {
            setTab(lastLoginType)
        }
        // setAccount('xxxxx@aaa.ccc')
    }, [])
    return (
        <>
            {activeBox === 'reg' && (
                <div className={classNames(styles.loginForm, isSide && styles.isSide)}>
                    {isPc() ? <div className={styles.loginFormTitle}>{t('Log in')}</div> : null}
                    <div className={styles.loginFormNotHaveAccount}>
                        {t("Don't have account?")}
                        <span
                            className={styles.registerTip}
                            onClick={() => (isSide ? setType?.('reg') : navigate(`/${locale}/register?backUrl=${backUrl}`))}
                        >
                            {t('Register Now')}
                        </span>
                    </div>
                    <div className={styles.formtab}>
                        <FormTab
                            defaultVal={tab}
                            options={
                                isMobile
                                    ? [
                                          { label: t('Password'), value: 'pwd' },
                                          { label: t('Email'), value: 'e' },
                                          {
                                              label: t('Telephone'),
                                              value: 'p'
                                          }
                                      ]
                                    : [
                                          { label: t('Password'), value: 'pwd' },
                                          { label: t('Email'), value: 'e' },
                                          {
                                              label: t('Telephone'),
                                              value: 'p'
                                          },
                                          {
                                              label: t('QRcode'),
                                              value: 'qr'
                                          }
                                      ]
                            }
                            onChange={tab1 => {
                                const tabC = tab1 as any
                                setTab(tabC)
                                setEmailDisabled(false)
                                setPhoneDisabled(false)
                                captchaRef.current.reCount?.()
                            }}
                        ></FormTab>
                    </div>

                    {tab === 'qr' ? (
                        <>
                            <LoginQR></LoginQR>
                            <div style={{ opacity: 0, height: 0, overflow: 'hidden' }}>
                                {/* 仅用于撑开布局，没别的用 */}
                                <AgreementBox
                                    checked={agreement}
                                    onChange={b => setAgreement(b)}
                                ></AgreementBox>
                            </div>
                        </>
                    ) : (
                        <>
                            <FormItem
                                show={tab === 'pwd'}
                                defaultVal={account}
                                label={t('Email / Phone / Sub-account')}
                                placeholder={t('Email / Phone / Sub-account')}
                                type={'txt'}
                                onChange={val => setAccount(val)}
                            ></FormItem>
                            <FormItem
                                disabled={emailDisabled}
                                show={tab === 'e'}
                                defaultVal={email}
                                type={'email'}
                                onChange={(val, valid) => {
                                    setEmail(val)
                                    setEmailVerification(valid)
                                }}
                                onClearVerAccount={() => {
                                    setEmailDisabled(false)
                                    captchaRef.current.reCount?.()
                                }}
                            ></FormItem>
                            <FormItem
                                disabled={phoneDisabled}
                                show={tab === 'p'}
                                defaultVal={purePhone}
                                type={'phone'}
                                onPurePhoneChange={v => setPurePhone(v)}
                                onChange={(val, valid) => {
                                    setPhone(val)
                                    setPhoneVerification(valid)
                                }}
                                onClearVerAccount={() => {
                                    setPhoneDisabled(false)
                                    captchaRef.current.reCount?.()
                                }}
                            ></FormItem>
                            {/* 验证码email跟phone分开 */}
                            <FormItem
                                show={tab === 'e'}
                                style={{ marginBottom: '48px' }}
                                captchaRef={captchaRef}
                                verificationData={verData}
                                account={verAccount}
                                label={t('Verification Code')}
                                type={'verification'}
                                onChange={(val, valid) => {
                                    setVerificationCode(val)
                                    setVerificationCodeOk(valid)
                                }}
                                captchaCb={id => {
                                    if (tab === 'e') {
                                        setEmailDisabled(true)
                                    } else {
                                        setPhoneDisabled(true)
                                    }
                                    setCaptchaId(id)
                                }}
                            ></FormItem>
                            <FormItem
                                show={tab === 'p'}
                                style={{ marginBottom: '48px' }}
                                captchaRef={captchaRef}
                                verificationData={verData}
                                account={verAccount}
                                label={t('Verification Code')}
                                type={'verification'}
                                onChange={(val, valid) => {
                                    setVerificationCode(val)
                                    setVerificationCodeOk(valid)
                                }}
                                captchaCb={id => {
                                    if (tab === 'e') {
                                        setEmailDisabled(true)
                                    } else {
                                        setPhoneDisabled(true)
                                    }
                                    setCaptchaId(id)
                                }}
                            ></FormItem>
                            <FormItem
                                show={tab === 'pwd'}
                                defaultVal={password}
                                style={{ marginBottom: '48px' }}
                                label={t('Password')}
                                type={'pwd'}
                                pwdType={'login'}
                                onChange={(val, valid) => {
                                    setPassword(val)
                                    setPasswordVerification(valid)
                                }}
                            ></FormItem>
                            <AgreementBox
                                checked={agreement}
                                onChange={b => setAgreement(b)}
                            ></AgreementBox>
                            {agreementTip && <p className={styles.errorTip}>{t('Please tick the box to confirm that you consent to the Terms of Use')}</p>}
                            <Button
                                style={{ marginBottom: '16px', height: '48px', border: 'none' }}
                                theme="solid"
                                type={'primary'}
                                block
                                onClick={submit}
                                disabled={loginBtnDisabled}
                            >
                                {t('Login')}
                            </Button>
                            <div className={styles.divider}>
                                <div className={styles.left}></div>
                                <div className={styles.text}>{t('Or')}</div>
                                <div className={styles.right}></div>
                            </div>
                            <div className={styles.thirdBox}>
                                <GoogleLoginButton googleRegisterCb={googleRegisterCb} />
                                <TelegramLoginBtn
                                    agreement={agreement}
                                    registerCb={registerCb}
                                    loginCb={loginCb}
                                ></TelegramLoginBtn>
                            </div>
                            <div
                                className={styles.loginFormForgetpdTip}
                                onClick={() => setTipPopShow(true)}
                            >
                                {t('Forgot password')}
                                {'?'}
                            </div>
                        </>
                    )}

                    <ExclamationModal
                        visible={tipPopShow}
                        setVisible={setTipPopShow}
                        onCancel={() => setTipPopShow(false)}
                        cancelText={t('Cancel')}
                        onOk={() => navigate(`/${locale}/resetPwd`)}
                        okText={t('Continue')}
                        icon={'warning'}
                    >
                        <div className={styles.forgetPopInfo}>
                            <div className={styles.tipTitle}>{t('Notice Board')}</div>
                            <div className={styles.tipSection}>{t('ChangePwdTip') /** Attention: Withdrawal will be suspended for 24 hours once password has been changed. */}</div>
                        </div>
                    </ExclamationModal>
                </div>
            )}
            {activeBox === 'third' && (
                <ThirdRegisterForm
                    thirdPreSetEmail={thirdEmail}
                    labelShow
                    type={thirdType}
                />
            )}
        </>
    )
}

export default LoginForm
