/**
 * 按需输出时间
 * @param time 时间戳
 * @param type 用于自定义时间输出方法，h,m,s分别表示时分秒，YY,MM,DD分别表示年日月，w表示数字星期
 */
export const formatDate = (time: number | string, type?: string): string => {
    const date = new Date(+time)
    if (type) {
        return type
            .replace('h', `00${date.getHours()}`.substr(-2))
            .replace('m', `00${date.getMinutes()}`.substr(-2))
            .replace('s', `00${date.getSeconds()}`.substr(-2))
            .replace('w', `${date.getDay()}`) // 数字星期
            .replace('YY', `${date.getFullYear()}`)
            .replace('MM', `00${date.getMonth() + 1}`.substr(-2))
            .replace('DD', `00${date.getDate()}`.substr(-2))
    } else {
        return formatDate(time, 'MM-DD h:m')
    }
}
