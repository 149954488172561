export const langList = [
    {
        code: 'en-ww',
        fiatCurrencySymbol: 'USD',
        flagIos: 'en',
        icon: '',
        isGoogle: 0,
        name: 'English',
        sort: 1
    },
    {
        code: 'zh-hk',
        fiatCurrencySymbol: 'HKD',
        flagIos: 'zh-Hant',
        icon: '/newhome/pc/img/nav/ftzw@2x.png',
        isGoogle: 1,
        name: '繁體中文',
        sort: 4
    },
    {
        code: 'id-id',
        fiatCurrencySymbol: 'IDR',
        flagIos: 'id',
        icon: '/newhome/pc/img/nav/yny@2x.png',
        isGoogle: 1,
        name: 'Indonesia',
        sort: 5
    },
    {
        code: 'ja-jp',
        fiatCurrencySymbol: 'USD',
        flagIos: 'ja',
        icon: '',
        isGoogle: 1,
        name: '日本語',
        sort: 6
    },
    {
        code: 'pt-pt',
        fiatCurrencySymbol: 'EUR',
        flagIos: 'pt-PT',
        icon: '/lang/543b9496219e9daa47d8c4fff0c2020c.png',
        isGoogle: 1,
        name: 'Português-PT',
        sort: 7
    },
    {
        code: 'ru-ru',
        fiatCurrencySymbol: 'RUB',
        flagIos: 'ru',
        icon: '/lang/a1bcf2340633243e2a81404b3ce85bc8.png',
        isGoogle: 1,
        name: 'Pусский',
        sort: 8
    },
    {
        code: 'vi-vn',
        fiatCurrencySymbol: 'VND',
        flagIos: 'vi',
        icon: 'admin/20210802/365416b47092c6871e2c1c026ba4a14e.jpg',
        isGoogle: 1,
        name: 'Tiếng Việt',
        sort: 9
    },
    {
        code: 'th-th',
        fiatCurrencySymbol: 'THB',
        flagIos: 'th-TH',
        icon: '/lang/4533f66669672c98b49a8ce8d5fc1494.png',
        isGoogle: 1,
        name: 'ภาษาไทย',
        sort: 10
    },
    {
        code: 'sr-sr',
        fiatCurrencySymbol: 'USD',
        flagIos: 'sr',
        icon: '/lang/313aaba5325c7dd57134a3f3b364ae81.png',
        isGoogle: 1,
        name: 'Сербиан',
        sort: 11
    },
    {
        code: 'es-es',
        fiatCurrencySymbol: 'EUR',
        flagIos: 'es-es',
        icon: '/lang/f3da13c757e7a838e11275785ac43c20.png',
        isGoogle: 1,
        name: 'Español',
        sort: 12
    },
    {
        code: 'ar-kw',
        fiatCurrencySymbol: 'USD',
        flagIos: 'ar-kw',
        icon: 'admin/20210909/5e02c7f37918341288ee24c7f307455c.png',
        isGoogle: 1,
        name: 'العربية',
        sort: 13
    }
    // {
    //     code: 'tr-tk',
    //     fiatCurrencySymbol: 'USD',
    //     flagIos: 'tr-tk',
    //     icon: '/lang/44cb9bc09ed94ba2203493d22be2d4bd.jpg',
    //     isGoogle: 1,
    //     name: 'Türkçe*',
    //     sort: 42
    // }
]
