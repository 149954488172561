import { post } from 'utils'
export interface ApiTcListType {
    api: 'tc/list'
    method: 'post'
    return: {
        areaCommunity: {
            communityName: string;
            communityUrl: string;
        },
        functionCommunity: {
            communityName: string;
            communityUrl: string;
        }
    }
}
export const ApiTcList = async () => {
    return await post<ApiTcListType>('tc/list')
}
