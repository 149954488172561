import { ApiPopupFetch, PopupItem, PopupLocation, PopupShowType, PopupType } from 'api'
import { Modal } from 'components'
import { useEffect, useMemo, useState } from 'react'
import { storage } from 'utils'

import { PopUpImage } from './Image/Image'
import style from './index.module.scss'

const Popup = (): JSX.Element => {
    const [list, setList] = useState<PopupItem[]>([])
    const [hide, setHide] = useState(false)
    const [position, setPosition] = useState<number>(0)
    const popup = storage?.getItem('popup') ?? {}

    const fetchPopup = async () => {
        const { list = [] } = await ApiPopupFetch()
        const today = new Date().toLocaleDateString('us').replaceAll('/', '-')

        const needShowList = list.filter(({ location, touchPopupId, showType, popupType }) => {
            const storageItem = popup[touchPopupId]
            const isPcIndex = location === PopupLocation.PcIndex
            const show = showType === PopupShowType.Once ? typeof storageItem === 'undefined' : storageItem !== today

            return isPcIndex && show && popupType === PopupType.NoButton
        })

        if (needShowList.length > 0) {
            setList(needShowList)
        }
    }

    const handleAfterClose = (): void => {
        const nextPosition = position + 1
        if (nextPosition < list.length) {
            setPosition(nextPosition)
            setHide(false)
        }
    }

    const detailCommonLogic = (type: 0 | 1 | 2 | 3): void => {
        const { buttonLeftUrl, buttonRightUrl, imageUrl } = list[position]
        const hrefValHooks = [null, buttonLeftUrl, buttonRightUrl, imageUrl]
        if (hrefValHooks[type]) {
            window.open(hrefValHooks[type] as string)
        }

        popup[list[position].touchPopupId] = new Date().toLocaleDateString('us').replaceAll('/', '-')
        storage.setItem('popup', popup)
        setHide(true)
    }

    const item = useMemo(() => list[position] ?? {}, [list, position])

    const handleCancelClick = (): void => {
        detailCommonLogic(0)
        window?.gtag('event', 'home_popup_close_click', {
            id: item.touchPopupId,
            url: `${item.imageUrl},${item.buttonLeftUrl},${item.buttonRightUrl}`
        })
    }

    const handleImgClick = (): void => {
        detailCommonLogic(3)
        window?.gtag('event', 'home_popup_img_click', {
            id: item.touchPopupId,
            url: `${item.imageUrl},${item.buttonLeftUrl},${item.buttonRightUrl}`
        })
    }

    const modalVisible = useMemo(() => {
        if (!hide && list.length > 0) {
            window?.gtag('event', 'home_popup_imp', {
                id: item.touchPopupId,
                url: `${item.imageUrl},${item.buttonLeftUrl},${item.buttonRightUrl}`
            })
        }
        return !hide && list.length > 0
    }, [hide, list.length])

    useEffect(() => {
        fetchPopup()
    }, [])

    return (
        <Modal
            visible={modalVisible}
            header={null}
            footer={null}
            maskStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
            }}
            maskClosable={false}
            className={classNames(style.root, style.contentNoPadding)}
            afterClose={handleAfterClose}
            centered
        >
            <PopUpImage
                onImgClick={handleImgClick}
                image={item?.image}
                popupType={item?.popupType}
            />
            {modalVisible ? (
                <div
                    className={style.closeButton}
                    onClick={handleCancelClick}
                ></div>
            ) : null}
        </Modal>
    )
}

export default Popup
