// import { Button } from '@douyinfe/semi-ui'
import { PopupType } from 'api'
import { imgOrigin } from 'config'

import style from './Image.module.scss'

interface PopUpImageProps {
    onImgClick: () => void;
    image: string;
    popupType: PopupType;
}

export const PopUpImage = ({ onImgClick, image, popupType }: PopUpImageProps): JSX.Element => {
    return (
        <section
            className={classNames({
                [style.root]: true,
                [style.cursor]: popupType === PopupType.NoButton
            })}
            style={{ backgroundImage: `url(${imgOrigin}${image})` }}
            onClick={onImgClick}
        />
    )
}
