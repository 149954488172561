import React from 'react'

const DownArrow = /* #__PURE__ */ (function () {
    const component = function (props: any) {
        const { color, size, ...otherProps } = props
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...otherProps}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.40039 17.9551L4.40039 19.9551L6.40039 19.9551L19.4004 19.9551L19.4004 15.9551L11.2288 15.9551L22.3462 4.8377L19.5178 2.00927L8.40039 13.1267V4.95508L4.40039 4.95508L4.40039 17.9551Z"
                    fill={color}
                />
            </svg>
        )
    }

    component.defaultProps = {
        color: '#EB4933',
        size: '24'
    }

    return component
})()

export default DownArrow
