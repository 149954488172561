import { ApiMarket, ApiMarketsListType } from 'api/assets/ApiMarket'
import { storage } from 'utils'
// import { useLayoutEffect } from 'react'
import create from 'zustand'
import { devtools } from 'zustand/middleware'
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars

interface IconList {
    currencyLogoList: ApiMarketsListType[]
    getCurrencyLogo: () => void
}

const currencyLogoStore = create<IconList, any>(
    devtools(
        set => {
            return {
                currencyLogoList: storage.getItem('currencyLogoList') || [],
                getCurrencyLogo() {
                    /**
                     * 通过market接口获取全部线上交易对的图标
                     */
                    ApiMarket().then(res => {
                        // 缓存
                        storage.setItem('currencyLogoList', res?.list ?? [])
                        set({
                            currencyLogoList: res?.list ?? []
                        })
                    })
                }
            }
        },
        { name: 'currencyLogoStore', enabled: import.meta.env.DEV }
    )
)

export default currencyLogoStore
