import { ChartTemplate } from 'components/FigureChart/ChartTemplate'
import { Suspense } from 'react'
import { useErrorBoundary } from 'use-error-boundary'

import { DgLoading } from '../DgLoading/DgLoading'

interface AsyncComponentWrapperProps {
    children: React.ReactNode
}

/**
 * AsyncComponentWrapper 用于包裹异步加载的组件，使得异步组件加载失败时不会导致网页白屏
 *
 * 用法：
 *
 * ```js
 * import { lazy } from '@loadable/component'
 * import { AsyncComponentWrapper } from 'src/components/AsyncComponentWrapper'
 * const SomeComponet = lazy(() => import('./some_path'))
 *
 * export const Container = () => {
 *     return (
 *         <AsyncComponentWrapper>
 *             <SomeComponent />
 *         </AsyncComponentWrapper>
 *     )
 * }
 * ```
 */
export const AsyncComponentWrapper = ({ children }: AsyncComponentWrapperProps): JSX.Element => {
    const { ErrorBoundary } = useErrorBoundary()

    return (
        <Suspense
            fallback={
                <div style={{ width: '100%', height: '100%' }}>
                    <DgLoading style={{ position: 'absolute' }} />
                </div>
            }
        >
            <ErrorBoundary
                render={() => <>{children}</>}
                renderError={() => <ChartTemplate></ChartTemplate>}
            />
        </Suspense>
    )
}
