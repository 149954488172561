import { Loading } from 'components'
import { Suspense } from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'
import { RouterItem } from 'router'

// import { ViewAuth } from 'utils/ViewAuth'

// const commonPath = '/:locale'

export function renderRouter(item: RouterItem) {
    return (
        <Route
            key={item.name}
            path={item.path}
            element={
                item?.redirectTo
                ? <Navigate to={item.redirectTo} replace />
                : <Suspense fallback={<Loading />}>
                    {item.children
                        ? <Outlet />
                        : item.component && <item.component />
                    }
                </Suspense>
            }
        >
            {item.children && item.children?.map(child => renderRouter(child))}
        </Route>
    )
}

/**
 * 渲染组件通用HOC，入参为空为认证路由，渲染路由children
 * @param Component 路由渲染组件
 * @returns
 */
// const wrapComponent = (Component: React.ElementType | null = null) => {
//     return (props: any) => {
//         const { header, showFooter, showHeader, ...restProps } = props

//         return (
//             <>
//                 {/* 登录判断不放在路由，放到业务逻辑里去 */}
//                 {/* <ViewAuth isAuthRoute={isAuthRoute}> */}
//                 <AppLayout
//                     header={header}
//                     showFooter={showFooter}
//                     showHeader={showHeader}
//                 >
//                     {Component && <Component {...restProps} />}
//                 </AppLayout>
//                 {/* </ViewAuth> */}
//             </>
//         )
//     }
// }
/**
 * 渲染路由，包含访客和认证路由
 * @param list 路由配置
 * @returns
 */
export const renderRoutes = (list: Array<RouterItem>) => {
    return list?.map(route => {
        const { path, component, layout, name } = route
        const WrappedComponent = component as React.ElementType

        return (
            <Route
                key={name}
                path={path}
                element={
                    route?.redirectTo
                    ? <Navigate to={route.redirectTo} replace/>
                    : <Suspense fallback={<Loading />}>
                        <WrappedComponent {...layout} />
                    </Suspense>
                }
            >
                {renderRouter(route)}
            </Route>
        )
    })
}
