import { Button, Dropdown, Space } from '@douyinfe/semi-ui'
import { Link } from 'components'
// import NavCoinMargined from 'components/Icons/icons/NavCoinMargined'
import Navcoupon from 'components/Icons/icons/Navcoupon'
import NavFuturesBonus from 'components/Icons/icons/NavFuturesBonus'
import NavUsdt from 'components/Icons/icons/NavUsdt'
import { domain, locale } from 'config'
import { useEffect } from 'react'
import { NavDownFill, ReturnLeft } from 'react-dui-icons'
import { useParams } from 'react-router-dom'
import { checkLogin, PIXEL_REPORT_SCENE, pixelReport, storage } from 'utils'

import { BalanceDropDown } from './Balance/BalanceDropDown'
import DownloadPop from './DownloadPop/DownloadPop'
import style from './Header.module.scss'
import HeaderMenu from './HeaderMenu/HeaderMenu'
import { LangDropDown } from './LangDropDown/LangDropDown'
import { Notification } from './Notification/Notification'
// import { OrdersDropDown } from './Orders/OrdersDropDown'
import ThemeSwitch from './ThemeSwitch/ThemeSwitch'
import { UserDropDown } from './User/UserDropDown'

export interface HeaderProps {
    /** 是否展示导航 */
    showNav?: boolean
    /** 是否展示登录注册模块 */
    showReg?: boolean
    /** 是否展示主题切换 */
    showTheme?: boolean
    className?: string
}
export const Header = (props: HeaderProps): JSX.Element => {
    const { showNav = true, showReg = true, showTheme = true } = props
    const { coin, basecoin } = useParams<{ locale: string; coin: string; basecoin: string }>()
    // const [homePageUrl, setHomePageUrl] = useState<string>(`/${locale}/`) // logo路径默认为旧主站

    // 阿拉伯语支持rtl
    useEffect(() => {
        const rtlLangs = ['ar-kw']

        if (rtlLangs.includes(String(locale))) {
            document.documentElement.setAttribute('dir', 'rtl')
        } else {
            document.documentElement.setAttribute('dir', 'ltr')
        }
    }, [])

    useEffect(() => {
        // 渠道码记录
        const channelCode = new URLSearchParams(window.location.search).get('channelCode')
        if (channelCode) {
            storage.setItem('channelCode', channelCode, 'local', 'reg')
        }
    }, [])

    const goHomePage = () => {
        location.href = `/${locale}/`
    }

    const navMenu = [
        {
            name: t('P2P'),
            to: `//otc.${domain}/${locale}/otc`
        },
        {
            name: t('Market') /** 行情 */,
            to: `/${locale}/markets`
        },
        {
            name: t('Trade') /** 交易 */,
            to: !basecoin ? `/${locale}/trade/USDT/BTC` : `/${locale}/trade/${coin}/${basecoin}`
        },

        {
            name: t('Derivatives') /** 合约交易 */,
            to: `/${locale}/swap/BTCUSDTPERP`,
            isHot: true,
            children: [
                {
                    name: t('USDT margined') /** U本位合约 */,
                    to: `/${locale}/swap/BTCUSDTPERP`,
                    icon: <NavUsdt size={24} />,
                    subName: t('Futures settled in USDT')
                },
                // {
                //     name: t('Coin margined') ,
                //     to: `/${locale}/swap/BTCPERP`,
                //     icon: <NavCoinMargined size={24} />,
                //     subName: t('Futures settled in respective cryptocurrency')
                // },
                {
                    name: t('Future Bonus') /** 合约赠金 */,
                    to: `/${locale}/bonus-hub`,
                    icon: <NavFuturesBonus size={24} />,
                    subName: t('Trading Events & Bonus Rewards')
                },
                {
                    name: t('Welfare Center') /** 卡券中心 */,
                    to: `/${locale}/reward-fee`,
                    icon: <Navcoupon size={24} />,
                    subName: t('Enjoy more trading discounts')
                }
            ]
        }
    ]

    const report = (eventId: string) => {
        const getScene = () => {
            interface IsceneMap {
                [key: string]: string
            }
            const sceneMap: IsceneMap = {
                home: 'home',
                markets: 'swap',
                nft: 'nft',
                fund: 'fund',
                dft: 'dfy-flyer'
            }
            const href = window.location.href
            const path = window.location.pathname
            for (const key in sceneMap) {
                if (href.indexOf(key) > -1) {
                    return key
                }
            }
            return path === `/${locale}` ? 'home' : path
        }
        // google data
        window?.gtag?.('event', eventId, {
            scene: getScene()
        })
    }

    const LANDING_GTAG_MAP: Record<string, Partial<keyof typeof PIXEL_REPORT_SCENE>> = {
        [`/${locale}/charge/credit`]: 'CLICK_HEADER_BUY',
        [`/${locale}/markets`]: 'CLICK_HEADER_MARKETS',
        [`/${locale}/trade/USDT/BTC`]: 'CLICK_HEADER_TRADE',
        [`/${locale}/swap/BTCUSDTPERP`]: 'CLICK_HEADER_FUTURES',
        [`/${locale}/login?backUrl=${window.location.pathname}`]: 'CLICK_HEADER_LOGIN'
    }

    return (
        <nav className={classNames(style.header, !showReg && style.noBg, props.className)}>
            <div className={classNames(style.left)}>
                <a onClick={goHomePage}>
                    <div className={style.logo}></div>
                </a>
                {showNav && (
                    <div
                        className={style.navLinks}
                        id="headerLinksWrap"
                    >
                        {navMenu?.map((item, index) => {
                            return (
                                <Dropdown
                                    key={index}
                                    getPopupContainer={(): HTMLElement => document.getElementById('headerLinksWrap') ?? document.body}
                                    position="bottomLeft"
                                    className={style.dropdown}
                                    spacing={13}
                                    render={
                                        item?.children && (
                                            <Dropdown.Menu>
                                                {item?.children?.map((subItem: any, index) => {
                                                    return (
                                                        <Dropdown.Item key={index}>
                                                            <Link
                                                                href={subItem.to}
                                                                className={style.navSubLink}
                                                            >
                                                                <div>
                                                                    <span className={style.icon}>{subItem.icon}</span>
                                                                    <div className={style.subItemName}>
                                                                        <span>{subItem.name}</span>
                                                                        {subItem?.subName && <span className={style.subName}>{subItem?.subName}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className={style.arrow}>
                                                                    <ReturnLeft size={16} />
                                                                </div>
                                                            </Link>
                                                        </Dropdown.Item>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        )
                                    }
                                >
                                    <div>
                                        <Link
                                            href={item.to}
                                            className={style.navLink}
                                            onClick={() => {
                                                LANDING_GTAG_MAP?.[item.to] && pixelReport(LANDING_GTAG_MAP?.[item.to])
                                            }}
                                        >
                                            {item.name}
                                            {item?.children && <NavDownFill size={12} />}
                                        </Link>
                                    </div>
                                </Dropdown>
                            )
                        })}
                    </div>
                )}
            </div>
            <div className={style.right}>
                {checkLogin() && (
                    <div className={style.balanceBlock}>
                        <BalanceDropDown />
                    </div>
                )}
                {/* {checkLogin() && (
                    <div className={style.balanceBlock}>
                        <OrdersDropDown />
                    </div>
                )} */}
                {showReg && (
                    <div className={style.userBlock}>
                        {!checkLogin() && (
                            // 理财上线，登录注册先跳旧版
                            <>
                                <Link
                                    className={style.links}
                                    href={`/${locale}/login?backUrl=${window.location.pathname}`}
                                    onClick={() => {
                                        pixelReport('CLICK_HEADER_LOGIN')
                                        report('click_log_in')
                                    }}
                                >
                                    <Button
                                        size="small"
                                        theme="solid"
                                        type="secondary"
                                        style={{ minWidth: '73px' }}
                                    >
                                        {t('Log in')}
                                    </Button>
                                </Link>
                                <Link
                                    className={style.links}
                                    href={`/${locale}/register?backUrl=${window.location.pathname}`}
                                    onClick={() => report('click_signup_navbar')}
                                >
                                    <Button
                                        className={style.signUp}
                                        size="small"
                                        theme="solid"
                                        type="primary"
                                    >
                                        {t('Sign up')}
                                    </Button>
                                </Link>
                            </>
                        )}
                        {checkLogin() && <UserDropDown />}
                    </div>
                )}
                {checkLogin() && (
                    <div className={style.userBlock}>
                        <Notification />
                    </div>
                )}

                <div className={style.otherBlock}>
                    <Space spacing={16}>
                        <DownloadPop type={1} />
                        <LangDropDown />
                        <div id="alwaysLightWrap"></div>
                        {showTheme && <ThemeSwitch />}
                    </Space>
                </div>

                {/* 小屏下侧边栏菜单 */}
                <div className={style.headerMenu}>
                    <HeaderMenu navMenu={navMenu} />
                </div>
            </div>
        </nav>
    )
}
