import { domain } from 'config'
import { useMemo } from 'react'
import { Link as ReactLink } from 'react-router-dom'

// import { PIXEL_REPORT_SCENE } from 'utils'
import style from './index.module.scss'

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    href?: string
    address?: string
    to?: string
    openInBlank?: boolean
    noReferrer?: boolean
    className?: string
    children: React.ReactNode
}

interface MailProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    address?: string
    children?: React.ReactNode
}

export const Link = ({ href, to, openInBlank = false, noReferrer = false, className, children, ...props }: LinkProps): JSX.Element => {
    const attrs = useMemo(() => {
        const obj = {} as React.AnchorHTMLAttributes<HTMLAnchorElement>
        if (openInBlank || (href && href.split('.')?.length > 1 && href.indexOf(domain) === -1)) {
            obj.target = '_blank'
        }
        if (noReferrer || (href && href.split('.')?.length > 1 && href.indexOf(domain) === -1)) {
            obj.rel = 'noreferrer noopener'
        }
        return obj
    }, [openInBlank, noReferrer])

    return to ? (
        <ReactLink
            to={to}
            className={classNames(className, style.link)}
            {...props}
        >
            {children}
        </ReactLink>
    ) : (
        <a
            className={classNames(className, style.link)}
            href={href}
            {...attrs}
            {...props}
        >
            {children}
        </a>
    )
}

export const Mail = ({ href, className, ...props }: MailProps): JSX.Element => {
    return (
        <a
            className={classNames(className, style.link)}
            href={`mailto:${href}`}
            {...props}
        >
            {href}
        </a>
    )
}
