import { Button } from '@douyinfe/semi-ui'
import { ApiErrorReport } from 'api/common/ApiErrorReport'
import { isDev, isPreEnv, isTestEnv } from 'config'
import { Component, ErrorInfo } from 'react'
import UAParser from 'ua-parser-js'
import { isPc, storage } from 'utils'
import { isApp } from 'utils/appUtil'

import serverErrorSvg from './assets/server_error.svg'
import style from './ErrorBoundary.module.scss'

interface ErrorBoundaryProps {
    children: React.ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

const botRegExp = /(Googlebot|jsdom|DuckDuckBot|Twitterbot|facebookexternalhit|facebookcatalog|Facebot|TelegramBot|WhatsApp|kakaotalk-scrap|kakaostory-og-reader|Discordbot|Yahoo|Baiduspider|Sosospider|360Spider|google.com|SogouSpider|BingBot|YisouSpider|YandexBot|Yeti.*naver|PetalBot)/i

const ignoreError = () => {
    const userAgent = window.navigator.userAgent
    const isBot = botRegExp.test(userAgent)
    const isIgnoredUrl = window.location.href.includes('utm_source=campaign')
    return isDev || isIgnoredUrl || isBot
}

// 封装统一上报
export const larkReport = (message: string) => {
    if (ignoreError()) {
        return
    }
    const userAgent = window.navigator.userAgent
    const platform = isPc() ? 'PC' : isApp() ? 'H5 (webview)' : 'H5'

    const parser = new UAParser(userAgent)
    ApiErrorReport({
        url: window.location.href,
        message: `\nPlatform: ${platform}\n` + `Browser: ${parser.getBrowser().name} ${parser.getBrowser().version}\n` + `Engine: ${parser.getEngine().name} ${parser.getEngine().version}\n` + `OS: ${parser.getOS().name} ${parser.getOS().version}\n` + `UserAgent: ${userAgent}\n` + `\n${message}`
    })
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true }
    }

    // csp策略命中上报
    cspReport() {
        document.addEventListener('securitypolicyviolation', function (e) {
            larkReport('CSP Violation Detected\n' + `Blocked URI:${e.blockedURI}\n` + `Violated Directive:${e.violatedDirective}\n` + `Document URI:${e.documentURI}\n` + `Source File:${e.sourceFile}\n` + `Status Code:${e.statusCode}\n`)
        })
    }

    componentDidMount(): void {
        // this.cspReport()

        storage.setItem('last-global-refresh', { timestamp: 0 }, 'local')
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        if (isTestEnv || isPreEnv) {
            console.error(error, errorInfo)
        }

        this.setState({
            hasError: true
        })

        this.onError(error, errorInfo)
    }

    onError(error: Error, errorInfo: ErrorInfo) {
        const timestamp = storage.getItem('last-global-refresh', 'local')?.timestamp
        const botRegExp = /(Googlebot|jsdom|DuckDuckBot|Twitterbot|facebookexternalhit|facebookcatalog|Facebot|TelegramBot|WhatsApp|kakaotalk-scrap|kakaostory-og-reader|Discordbot|Yahoo|Baiduspider|Sosospider|360Spider|google.com|SogouSpider|BingBot|YisouSpider|YandexBot|Yeti.*naver|PetalBot)/i
        const userAgent = window.navigator.userAgent
        const ignoreError = isDev || botRegExp.test(userAgent) || window.location.href.includes('utm_source=campaign')

        if (/loading.*chunk.*failed/i.test(error.toString())) {
            if (Date.now() - timestamp > 120 * 1000) {
                storage.setItem('last-global-refresh', { timestamp: Date.now() }, 'local')
                window.setTimeout(() => {
                    window.location?.replace(window.location.href)
                })
            }
            console.error(error)
        }

        if (error && !ignoreError) {
            const platform = isPc() ? 'PC' : isApp() ? 'H5 (webview)' : 'H5'

            const parser = new UAParser(userAgent)

            ApiErrorReport({
                url: window.location.href,
                message:
                    `\n${error.toString()}\n` +
                    `\nPlatform: ${platform}\n` +
                    `Browser: ${parser.getBrowser().name} ${parser.getBrowser().version}\n` +
                    `Engine: ${parser.getEngine().name} ${parser.getEngine().version}\n` +
                    `OS: ${parser.getOS().name} ${parser.getOS().version}\n` +
                    `UserAgent: ${userAgent}\n` +
                    `\n${error.stack ? 'Stack:\n' + JSON.stringify(error.stack) + '\n\n' : ''}` +
                    `${errorInfo ? 'React info:\n' + JSON.stringify(errorInfo) + '\n\n' : ''}`
            })
        }
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <div className={style.root}>
                    <div className={style.main}>
                        <img src={serverErrorSvg} />
                        <h3></h3>
                        <h5>{t('The page flew into the outer space')}</h5>
                        <div>
                            <Button
                                theme="light"
                                type="primary"
                                size={isPc() ? 'large' : 'default'}
                                onClick={() => {
                                    window?.location?.replace(window?.location?.origin)
                                }}
                            >
                                {t('Go Back')}
                            </Button>
                            <Button
                                theme="solid"
                                type="primary"
                                size={isPc() ? 'large' : 'default'}
                            >
                                <a href={'mailto:support@parobit.com'}>{t('Support')}</a>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
        return this.props.children
    }
}
