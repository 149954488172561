import { Header } from 'components'
import DownloadPop from 'components/DownloadPop/DownloadPop'
import { locale } from 'config'
import { useEffect } from 'react'
import { checkLogin, isPc } from 'utils'
import RegisterBanner from 'views/Auth/Register/components/RegisterBanner/RegisterBanner'
import RegisterForm from 'views/Auth/Register/components/RegisterForm/RegisterForm'

import styles from './Register.module.scss'

const Register = (): JSX.Element => {
    useEffect(() => {
        if (checkLogin()) {
            window.location.href = `/${locale}/`
        }
    }, [])
    return (
        <section className={styles.root}>
            <Header
                showNav={false}
                showReg={false}
            />
            <div className={styles.banner}>
                <RegisterBanner></RegisterBanner>
            </div>
            <div className={styles.form}>
                <RegisterForm labelShow={isPc()}></RegisterForm>
            </div>
            <DownloadPop></DownloadPop>
        </section>
    )
}

export default Register
