import { Button, Input, Space } from '@douyinfe/semi-ui'
import classNames from 'classnames'
import QrCode02 from 'components/Icons/icons/QrCode02'
import { locale } from 'config'
import { useEffect, useState } from 'react'
import { GoogleplayBlack, IphoneBlack, LongArrow } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'
import { checkLogin } from 'utils'

import style from './Banner.module.scss'
import DepositModal from './components/DepositModal'

const Banner = () => {
    const navigate = useNavigate()
    const [value, setValue] = useState<string>('')
    const [depositModalVisible, setDepositModalVisible] = useState<boolean>(false)
    const [hasEnteredViewport, setHasEnteredViewport] = useState(false)

    useEffect(() => {
        if (!hasEnteredViewport) {
            setHasEnteredViewport(true)
        }
    }, [hasEnteredViewport])

    const DATA = [
        {
            label: t('High'),
            desc: t('paro:High liquidity')
        },
        {
            label: t('7/24h'),
            desc: t('paro:Customer Support')
        },
        {
            label: t('120+'),
            desc: t('paro:Countries & regions')
        }
    ]

    return (
        <section className={classNames(style.root, hasEnteredViewport && style.enter)}>
            <h1>{t('paro:Connect to the Future, Limitless Trading')}</h1>
            <Space className={style.data}>
                {DATA.map((item, index) => (
                    <Space
                        vertical
                        className={style.dataItem}
                        key={index}
                    >
                        <h2>{item.label}</h2>
                        <p>{item.desc}</p>
                    </Space>
                ))}
            </Space>
            <Space className={style.regGroup}>
                {!checkLogin() ? (
                    <Space className={style.form}>
                        <Input
                            size="large"
                            placeholder={t('Email/Phone number')}
                            onChange={setValue}
                        />
                        <Button
                            size="large"
                            theme="solid"
                            className={style.startBtn}
                            onClick={() => {
                                navigate(`/${locale}/register`, { state: { presetAccount: value } })
                            }}
                        >
                            <Space>
                                <h4>{t('Get Start')}</h4>
                                <LongArrow />
                            </Space>
                        </Button>
                    </Space>
                ) : (
                    <Space className={style.form}>
                        <Button
                            size="large"
                            className={style.depositBtn}
                            onClick={() => setDepositModalVisible(true)}
                        >
                            <h4>{t('Deposit')}</h4>
                        </Button>
                        <Button
                            size="large"
                            theme="solid"
                            className={style.tradeBtn}
                            onClick={() => navigate(`/${locale}/markets`)}
                        >
                            <Space>
                                <h4>{t('Trade Now')}</h4>
                                <LongArrow />
                            </Space>
                        </Button>
                    </Space>
                )}

                {/* TODO 跳转 */}
                <Space className={style.download}>
                    <div className={style.downloadItem}>
                        <GoogleplayBlack />
                    </div>
                    <div className={style.downloadItem}>
                        <IphoneBlack />
                    </div>
                    <div className={classNames(style.downloadItem, style.qrcode)}>
                        <QrCode02 />
                    </div>
                </Space>
            </Space>

            <DepositModal
                visible={depositModalVisible}
                setVisible={setDepositModalVisible}
            />
        </section>
    )
}

export default Banner
