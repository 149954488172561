import { Button } from '@douyinfe/semi-ui'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import { TelegramUser } from 'components/TelegramLogin/TelegramLogin'
import { Base64 } from 'js-base64'
import { useEffect } from 'react'
import { Telegram } from 'react-dui-icons'
import { useSearchParams } from 'react-router-dom'
import useLoginData from 'store/useLoginData'
import { UAParser } from 'ua-parser-js'
import { AppHelper } from 'utils/appHelper'

import style from './AppTelegramLogin.module.scss'
const AppTelegramLogin = (): JSX.Element => {
    const [searchParams] = useSearchParams()
    const [loginData] = useLoginData(state => [state])

    const telegramLoginCallback = (user: TelegramUser) => {
        console.log(user)
        const encodeUser = Base64.encode(JSON.stringify(user))
        const stringUser = JSON.stringify(user)
        console.log(encodeUser, JSON.stringify(user), user, 'encodeforios')

        const type = new URLSearchParams(window.location.search).get('type')
        if (type !== 'App') {
            return
        }

        const parser = new UAParser()
        const device = parser.getOS().name
        console.log(device)
        if (device === 'iOS' && AppHelper.ios) {
            window.location.href = `parobit://thirdLoginDone?from=tg&data=${encodeURIComponent(stringUser)}`
        }
        if (device === 'Android' && AppHelper.android) {
            AppHelper.android.telegramLogin(stringUser)
        }
    }
    const tgClick = () => {
        const Telegram = (window as any).Telegram
        if (!loginData.thirdParty?.teleBotId) {
            return
        }

        if (Telegram) {
            Telegram.Login.auth(
                {
                    bot_id: loginData.thirdParty?.teleBotId
                },
                (user: TelegramUser) => telegramLoginCallback(user)
            )
        } else {
            Toast.warning({ content: t('100063', { ns: 'errCode' }), showClose: false })
        }
    }
    useEffect(() => {
        loginData.loadingThirdParty()
    }, [])

    useEffect(() => {
        // 开发者模式打开控制台
        const isDev = searchParams.getAll('dev')[0]
        if (isDev) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;(function () {
                const script = document.createElement('script')
                script.src = '//cdn.jsdelivr.net/npm/eruda'
                document.body.appendChild(script)
                script.onload = function () {
                    // @ts-ignore
                    eruda.init()
                }
            })()
        }
    }, [])

    return (
        <div className={style.tgloginForApp}>
            <div className={style.content}>
                <Telegram size={64} />
                <div className={style.title}>{t('Quick login with Telegram')}</div>
                <div className={style.text}>{t('Log in with Telegram and explore the digital currency world')}</div>
            </div>
            <Button
                className={style.next}
                size={'large'}
                theme={'solid'}
                type={'primary'}
                onClick={() => {
                    tgClick()
                }}
            >
                {t('Next')}
            </Button>
        </div>
    )
}
export default AppTelegramLogin
