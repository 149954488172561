import { IconChevronRight } from '@douyinfe/semi-icons'
import { Button, Divider, Typography } from '@douyinfe/semi-ui'
import { ApiGetMsgUnread, ApiMessageLatestUnreadType, ApiSetMessageReadAll, ApiSetMessageReadOne } from 'api/notification'
import { domain, locale } from 'config'
import { useEffect, useState } from 'react'
import { formatDate } from 'utils'

import style from './MessageDropDown.module.scss'

interface NewsDropdownProps {
    count?: number // loading 模板数量
    fetchMsgCount: Function // 拉取未读消息数量
    isMobile?: boolean // 是否是移动端
}
export const MessageDropDown = ({ fetchMsgCount, isMobile = false }: NewsDropdownProps): JSX.Element => {
    const [list, setList] = useState<ApiMessageLatestUnreadType['return']['list']>([])
    const [mobile, setMobile] = useState(false)
    const fetchMessage = async (): Promise<void> => {
        const { list } = await ApiGetMsgUnread()
        setList(list)
    }
    useEffect(() => {
        setMobile(isMobile)
        fetchMessage()
    }, [])

    const redirect = (path: string) => {
        location.href = `https://www.${domain}${path}`
    }

    /**
     * 阅读单条消息及全部
     * @param id 消息id
     */
    const readMsg = (id?: string | number, articleId?: string | number, categoryId?: string | number): void => {
        console.log('readMsg')
        if (id) {
            ApiSetMessageReadOne({ id })
        } else {
            ApiSetMessageReadAll({})
        }

        // 跳转到id页面
        if (Number(categoryId) === 2 && +Number(categoryId) !== 0) {
            redirect(`/${locale}/notice/${articleId}`)
        } else if (id) {
            redirect(`/${locale}/user/notification/${id}`)
        } else {
            fetchMsgCount()
            fetchMessage()
        }
    }
    const { Paragraph, Text } = Typography
    return (
        <section className={!mobile ? style.root : style.rootMobile}>
            <div className={style.header}>
                <span className={style.title}>{t('Notifications')}</span>
                <div
                    className={style.hasRead}
                    onClick={() => readMsg()}
                >
                    {t('Mark as Read')}
                </div>
                {/* <Button
                    theme="borderless"
                    type="primary"
                    className={style.hasRead}
                    onClick={(): void => readMsg()}
                >
                    {t('Mark as Read')}
                </Button> */}
            </div>
            <Divider />
            <ul className={style.content}>
                {list?.map(({ contentTitle, articleId, messageId, categoryId, datelineTimeStamp }, index) => (
                    <li key={articleId + index}>
                        <Button
                            theme="borderless"
                            size="large"
                            style={{ justifyContent: 'left' }}
                            onClick={(): void => readMsg(messageId, articleId, categoryId)}
                            block
                        >
                            <Paragraph
                                ellipsis
                                className={!mobile ? style.messageTitle : style.messageTitleMobile}
                            >
                                {contentTitle}
                            </Paragraph>
                            <div className={style.time}>{formatDate(datelineTimeStamp * 1000, 'YY-MM-DD h:m:s')}</div>
                        </Button>
                    </li>
                ))}
            </ul>

            {list?.length === 0 && <p className={style.noData}>{t('No Unread Messages')}</p>}

            <Text
                className={style.more}
                link={{ href: `/${locale}/user/notification` }}
            >
                {t('more')}
                <IconChevronRight style={{ verticalAlign: 'text-bottom' }} />
            </Text>
        </section>
    )
}
