import { get } from 'utils'

export interface ApiCardListInfoCardFacadeType {
    path: string
    // 1正面，2反面
    type: number
}
export interface ApiCardListInfoType {
    // 申请ID
    applyId: number
    // 卡id
    dCardId: string
    // 卡类型，1=虚拟卡 2=实体卡
    cardType: number
    // 状态 0=待激活 1=活跃 2=冻结 3=注销
    status: number
    // 申请状态 0=申请中 1=成功 -1=失败
    applyStatus: number
    // 开卡费 0=未支付 1=已支付
    cardFee: number
    remark: string
    /** 虚拟卡余额快照 */
    quota: string
    quotaCurrency: string
    /** 卡面皮肤 */
    cardFacade: string
}
export interface ApiCardListType {
    api: 'debit_card/card/v1/list'
    method: 'get'
    return: {
        applyTime: number
        cardList: ApiCardListInfoType[]
        reviewTime: number
    }
}
export const ApiCardList = async () => {
    // return {
    //     applyTime: 1701331507,
    //     cardList: [
    //         {
    //             applyId: 22502,
    //             applyStatus: 1,
    //             cardFee: 1,
    //             cardType: 2,
    //             dCardId: 'EECA17013315158941008874521',
    //             remark: '',
    //             status: 0
    //         },
    //         {
    //             applyId: 3267,
    //             applyStatus: 1,
    //             cardFee: 1,
    //             cardType: 1,
    //             dCardId: 'EEVT17013315122381008874276',
    //             remark: '',
    //             status: 1
    //         }
    //     ],
    //     review_time: 1701331507
    // }
    return await get<ApiCardListType>('debit_card/card/v1/list')
}
