import { domain, locale } from 'config'
import { useSearchParams } from 'react-router-dom'

import { useWindowSize } from './useWindowSize'

export default function useBackUrl(path?: string, notDelay?: boolean) {
    const [searchParams] = useSearchParams()
    const { isMobile } = useWindowSize()

    let backUrl = searchParams?.get('backUrl') || ''
    const checkBackUrl = (backUrl: string) => {
        let url
        try {
            url = new URL(backUrl, `https://${domain}`)
        } catch (_) {
            return false // not a valid URL
        }

        if (url.protocol !== 'https:') {
            return false // not secure
        }
        // 包括otc域名
        if (!(url.hostname === domain || url.hostname === `otc.${domain}`)) {
            return false // not the expected domain
        }
        return true
    }
    const back = () => {
        if (!backUrl) {
            const regexp = backUrl.match(/(.*)parobit(test|pre)?.com(.*)/)
            backUrl = path || (regexp ? regexp[3] : '') || (isMobile ? `/${locale}/` : `/${locale}/user`)
        }
        // 如果是首页的话，登录完默认跳到个人中心。移动端不跳
        if (checkBackUrl(backUrl)) {
            if (backUrl === `/${locale}/` || backUrl === `/${locale}`) {
                // 移动端跳到m首页
                if (isMobile) {
                    window.location.href = `https://m.${domain}/${locale}/`
                } else {
                    window.location.href = `/${locale}/user`
                }
            } else {
                window.location.href = decodeURIComponent(backUrl)
            }
        } else {
            if (isMobile) {
                window.location.href = `https://m.${domain}/${locale}/`
            } else {
                window.location.href = `/${locale}/user`
            }
        }
    }
    const aop = () => {
        if (notDelay) {
            back()
        } else {
            setTimeout(back, 1500)
        }
    }
    return { backUrl, back, aop }
}
