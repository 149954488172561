import { Link } from 'components'
import { locale } from 'config'
import { useLanguageList } from 'hooks'
import { createRef, SyntheticEvent } from 'react'
import { AreaFill as LanguageIcon, Check } from 'react-dui-icons'
import { setCookie } from 'utils/cookie'

import { DropDownContainer } from '../../semi-ext'
import style from './LangDropDown.module.scss'

export const LangDropDown = (): JSX.Element => {
    const ref = createRef<HTMLDivElement>()
    const { languageList, rateList, currentFiat: curFiat, currentLanguage: curLanguage, onFiatChange } = useLanguageList()

    const onScrollhandle = (e: SyntheticEvent) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
    }

    // 下拉菜单内容区
    const content = (
        <div className={style.root}>
            <div className={classNames(style.content, style.scrollbar)}>
                <Link
                    to={window.location.pathname}
                    className={classNames(style.lang, style.active)}
                >
                    <Check size={16} />
                    <span className={style.active}>{curLanguage?.name}</span>
                </Link>

                {languageList?.map(
                    ({ code, name }) =>
                        locale !== code && (
                            <Link
                                className={classNames(style.lang)}
                                onClick={(): void => {
                                    console.log('code', code)
                                    setCookie('lang', code, 365)
                                    window.location.href = window.location.href.replace(locale, code)
                                }}
                                key={code}
                            >
                                {locale === code && <Check size={16} />}
                                <span>{name}</span>
                            </Link>
                        )
                )}
            </div>
            <div
                className={style.content}
                ref={ref}
                onWheel={onScrollhandle}
            >
                <div className={classNames(style.addr, style.active)}>
                    <Check size={16} />
                    <span className={style.active}>{`${curFiat?.abbr}-${curFiat?.symbol}`}</span>
                </div>
                {Object.values(rateList)?.map(
                    item =>
                        curFiat?.abbr !== item?.abbr && (
                            <div
                                className={classNames(style.addr, curFiat?.abbr === item?.abbr ? style.active : '')}
                                key={`${item?.abbr}${item.lang}`}
                                onClick={() => onFiatChange(item)}
                            >
                                {curFiat?.abbr === item?.abbr && <Check size={16} />}
                                <span>{`${item?.abbr} - ${item?.symbol}`}</span>
                            </div>
                        )
                )}
            </div>
        </div>
    )

    return (
        <DropDownContainer
            content={content}
            trigger="hover"
            zIndex={1080}
        >
            <div className={style.langWrap}>
                <LanguageIcon size={24} />
            </div>
        </DropDownContainer>
    )
}
