import { Button, Space } from '@douyinfe/semi-ui'
import { downloadLink } from 'config'
import { createPortal } from 'react-dom'
import { PutAway } from 'react-dui-icons'
import { isPc } from 'utils'

import styles from './DownloadPop.module.scss'
import logoSvg from './logo.svg'

const DownloadPop = (): JSX.Element => {
    return createPortal(
        !isPc() && (
            <div
                className={styles.downloadPop}
                onClick={() => {
                    window.open(downloadLink)
                    window?.gtag?.('event', 'click_download_cta')
                }}
            >
                <img
                    src={logoSvg}
                    className={styles.logo}
                    alt=""
                />
                <div className={styles.info}>
                    <div className={styles.infoTitle}>Parobit App</div>
                    <div className={styles.infoTip}>Mobile transaction,safe & fast</div>
                </div>
                <Button
                    theme="solid"
                    type={'primary'}
                    className={styles.btn}
                >
                    <Space>
                        {' '}
                        Download <PutAway size={16} />
                    </Space>
                </Button>
            </div>
        ),
        document.body
    )
}

export default DownloadPop
