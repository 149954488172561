import { useRequest } from 'ahooks'
import { ApiDepositInternalListWithoutVerify, InternalListWithoutVerifyItem } from 'api/pay/ApiDepositInternalListWithoutVerify'
import { Footer, Header } from 'components'
import GoogleLoginPrompt from 'components/GoogleLoginBtn/GoogleLoginPrompt'
import InternalTransfer from 'components/InternalTransfer/InternalTransfer'
import { PcOnline } from 'components/PcOnline/PcOnline'
import TelegramEntranceModal from 'components/TelegramEntranceModal/TelegramEntranceModal'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { checkLogin, isPc } from 'utils'

// const IpAlert = loadable(() => import('../../components/IpAlert/IpAlert'))
import IpAlert from '../../components/IpAlert/IpAlert'
import Banner from './Banner/Banner'
import Download from './Download/Download'
import style from './index.module.scss'
import OurStrengths from './OurStrengths/OurStrengths'
import Popup from './Popup'
// import ProductInfo from './ProductInfo/ProductInfo'
import ScrollCoins from './ScrollCoins/ScrollCoins'
import StartJourney from './StartJourney/StartJourney'
import StartTrade from './StartTrade/StartTrade'
import TradingOpportunity from './TradingOpportunity/TradingOpportunity'
import WhyChoose from './WhyChoose/WhyChoose'

const Index = (): JSX.Element => {
    const { t } = useTranslation()
    const [insiteTransferList, setInsiteTransferList] = useState<InternalListWithoutVerifyItem[]>([])

    useRequest(ApiDepositInternalListWithoutVerify, {
        manual: !checkLogin(),
        onSuccess: res => {
            setInsiteTransferList(res.list.sort((a, b) => b.verifyEndTime - a.verifyEndTime))
        }
    })

    return (
        <div className={style.root}>
            <Helmet>
                {/* Parobit是世界主流的加密貨幣交易平台，提供BTC、ETH等百餘種加密貨幣資產交易及投資服務，包含現貨交易、槓桿交易、法幣交易、雲算力、聯合挖礦、活期和基金固定理財...等，立即註冊搶先體驗多樣性的理財產品。 */}
                <meta
                    name="description"
                    content={
                        t(
                            'Parobit is a leading global cryptocurrency exchange platform, offering trading and investment services for over a hundred crypto assets such as BTC, ETH, and more. Services include spot trading, leveraged trading, fiat trading, cloud mining, merged mining, flexible and fixed-income financial products, and more. Register now to experience our diverse range of financial products.'
                        ) as string
                    }
                />
                {/* 加密貨幣交易所, 區塊鏈交易所, 比特幣交易, 以太坊價格走勢, 比特幣價格, PEPE, SUI, 基金理財, 雲算力, 聯合挖礦, 虛擬貨幣, 加密錢包 */}
                <meta
                    name="keywords"
                    content={t('Blockchain Crypto Exchange, Cryptocurrency Exchange, Bitcoin Trading, Ethereum price trend, BTC price, PEPE price, SUI price, fund management, cloud mining, merged mining, Virtual currency, crypto wallet') as string}
                />
            </Helmet>
            <Header />
            {!checkLogin() && <GoogleLoginPrompt />}
            <div className={classNames(style.main)}>
                <div className={style.mainContainer}>
                    <Banner />
                    <ScrollCoins />
                    <OurStrengths />
                    <TradingOpportunity />
                    <WhyChoose />
                    <StartJourney />
                    <PcOnline
                        visible={false}
                        floatIcon={true}
                    />
                    {isPc() && <Popup />}
                </div>
                <Download />
                <StartTrade />
                <Footer />
            </div>
            <IpAlert />
            <TelegramEntranceModal />
            {/* 站内转账 */}
            {insiteTransferList?.map(item => (
                <InternalTransfer
                    key={item.depositId}
                    depositId={item.depositId}
                    currencyMark={item.currencyMark}
                    num={item.num}
                    verifyEndTime={item.verifyEndTime}
                />
            ))}
        </div>
    )
}

export default Index
