import { IconMinus, IconPlus } from '@douyinfe/semi-icons'
import { Collapse } from '@douyinfe/semi-ui'
import { locale } from 'config'
import dayjs from 'dayjs'
import { NewTwitterWhite, TelegramBlack } from 'react-dui-icons'
// FacebookBlack, InsBlack, LinkedinBlack, MediumBlack, RedditBlack, TalkBlack,
import { checkLogin, isChinese } from 'utils'
import { fixZendeskLang, zendesk } from 'utils/zendesk'

import style from './Footer.module.scss'
interface Props {
    /** stretch:铺满宽屏 padding:固定间距  */
    mode?: 'stretch' | 'padding'
}

interface Link {
    name: string
    to: string
    target?: string
}
interface Group {
    title: string
    links: Link[]
}
interface Column {
    groups: Group[]
}

export const Footer = ({ mode = 'stretch' }: Props): JSX.Element => {
    const isChina = isChinese(locale)

    const data: Column[] = [
        {
            groups: [
                {
                    title: t('About'),
                    links: [
                        {
                            name: t('About Us'),
                            to: `https://parobit.zendesk.com/hc/${fixZendeskLang()}/articles/41001948684569`,
                            target: '_blank'
                        },
                        {
                            name: t('App Download'),
                            to: `/${locale}/download`
                        },
                        {
                            name: t('Referral'),
                            to: `/${locale}/invite-friends`
                        },
                        {
                            name: t('paro:Listing Application'),
                            to: `/${locale}/listing`
                        }
                    ]
                }
            ]
        },
        {
            groups: [
                {
                    title: t('Terms of Service'),
                    links: [
                        {
                            name: t('Terms of Service'),
                            to: zendesk.policies,
                            target: '_blank'
                        },
                        {
                            name: t('Privacy Policy'),
                            to: zendesk.privacyPolicy,
                            target: '_blank'
                        },
                        {
                            name: t('Anti Money Laundering'),
                            to: `https://parobit.zendesk.com/hc/${fixZendeskLang()}/articles/40534931024281`,
                            target: '_blank'
                        }
                    ]
                }
            ]
        },
        {
            groups: [
                {
                    title: t('Service Support'),
                    links: [
                        {
                            name: t('Help Center'),
                            to: `https://parobit.zendesk.com/hc/${fixZendeskLang()}`,
                            target: '_blank'
                        },
                        {
                            name: t('paro:Parobit Verify'),
                            to: `/${locale}/verify`
                        },
                        {
                            name: t('Feedback'),
                            to: `https://parobit.zendesk.com/hc/${fixZendeskLang()}/requests/new`,
                            target: '_blank'
                        },
                        {
                            name: t('Fees'),
                            to: checkLogin() ? `/${locale}/user/others/vip` : `/${locale}/fees`,
                            target: '_blank'
                        }
                    ]
                }
            ]
        },
        {
            groups: [
                {
                    title: t('Products'),
                    links: [
                        {
                            name: t('Spot Trading'),
                            to: `/${locale}/trade/USDT/BTC`
                        },
                        {
                            name: t('Derivatives'),
                            to: `/${locale}/swap/BTCUSDTPERP`
                        },
                        {
                            name: 'P2P',
                            to: `https://otc.parobit.com/${locale}/otc/trade/buy-USDT`
                        }
                    ]
                }
            ]
        }
    ]

    const mobileData = data.reduce((acc: Group[], column: Column) => {
        return acc.concat(column.groups)
    }, [])

    const renderContactImg = (
        <div className={style.contactImg}>
            <a
                target="_blank"
                href={isChina ? 'https://t.me/Parobitcn' : 'https://t.me/ParobitEN'}
                rel="noopener noreferrer"
                className={style.telegram}
            >
                <TelegramBlack size={32} />
                {/* <Telegram size={24} /> */}
            </a>
            <a
                target="_blank"
                href="https://twitter.com/parobit"
                rel="noopener noreferrer"
                className={style.twitter}
            >
                <NewTwitterWhite size={32} />
                {/* <Twitter size={24} /> */}
            </a>
        </div>
    )

    const renderCollapse = (
        <div className={style.collapse}>
            <Collapse
                expandIcon={<IconPlus className={style.collapseIcon} />}
                collapseIcon={<IconMinus className={style.collapseIcon} />}
            >
                {mobileData?.map((item, index) => {
                    return (
                        <Collapse.Panel
                            header={item?.title}
                            itemKey={String(index)}
                            key={index}
                        >
                            {item?.links?.map((subItem: any, index: number) => {
                                return (
                                    <div
                                        className={style.subLink}
                                        key={index}
                                        onClick={() => (window.location.href = subItem?.to)}
                                    >
                                        {subItem?.name}
                                    </div>
                                )
                            })}
                        </Collapse.Panel>
                    )
                })}
            </Collapse>
        </div>
    )

    const renderLinkGroup = (
        <section className={style.linkGroup}>
            <a
                onClick={() => {
                    location.href = `/${locale}/`
                }}
            >
                <div className={style.logo}></div>
            </a>
            {renderCollapse}
            {renderContactImg}
            <div className={style.email}>
                <div>
                    <span>{t('Customer support email') /** parobit客服邮箱 */}:</span>
                    <a href={'mailto:paro_support@parobit.com'}>{'paro_support@parobit.com'}</a>
                </div>

                <div>
                    <span>{t('Business Cooperation')}:</span>
                    <a
                        href={'https://t.me/Parobit'}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        {'Telegram@Parobit'}
                    </a>
                </div>
            </div>
        </section>
    )

    return (
        <section className={classNames(style.footer, mode === 'padding' && style.paddingFooter)}>
            <div className={style.links}>
                <div className={style.left}>{renderLinkGroup}</div>
                <div className={style.right}>
                    {data?.map((item, colIndex) => {
                        return (
                            <div key={colIndex}>
                                {item?.groups?.map((group, groupIndex) => {
                                    return (
                                        <div
                                            className={style.linkGroup}
                                            key={groupIndex}
                                        >
                                            <h5>{group.title}</h5>
                                            {group?.links?.map((link, linkIndex) => {
                                                return (
                                                    <a
                                                        key={linkIndex}
                                                        href={link.to}
                                                        target={link?.target ?? '_self'}
                                                    >
                                                        {link.name}
                                                    </a>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>

            {renderLinkGroup}

            <div className={style.footerBottom}>
                <div className={style.copyright}>
                    {renderContactImg}
                    <div className={style.right}>
                        {'The Crypto Market is Volatile! Please Invest with Caution!'} <br />
                        {`Copyright © 2018-${dayjs().year()} Parobit.COM , Parobit LIMITED`}
                    </div>
                </div>
            </div>
        </section>
    )
}
