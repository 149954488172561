import { DropDownContainer } from 'components'
import { locale } from 'config'
import { ReactNode } from 'react'
import { NavDownFill } from 'react-dui-icons'

import { Balance } from './Balance'

export interface UserCenterDropDownPropsData {
    name: string
    to: string
    icon?: ReactNode
}

export const BalanceDropDown = (): JSX.Element => {
    return (
        <DropDownContainer
            content={<Balance />}
            trigger="hover"
            placement="bottomRight"
            zIndex={1080}
        >
            <div
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={() => {
                    window.location.href = `/${locale}/user/overview`
                }}
            >
                {t('Balance')}
                <NavDownFill size={12} />
            </div>
        </DropDownContainer>
    )
}
