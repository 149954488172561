import React from 'react'

const Option = /* #__PURE__ */ (function () {
    const component = function (props: any) {
        const { color, size, ...otherProps } = props
        return (
            <svg
                width={size}
                height={size}
                className="dui-icon"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color={color}
                {...otherProps}
            >
                <path
                    d="M11.3844 21.1999H6.46147C5.10203 21.1999 3.99999 20.1254 4 18.7999L4.0001 5.39999C4.0001 4.07451 5.10214 3 6.46158 3H17.5385C18.898 3 20 4.07452 20 5.4V11"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 9H16"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M8 13H12"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M19.5684 21.1199L19.5684 15.7305L21 17.162"
                    stroke="currentColor"
                    strokeWidth="1.50056"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.621 15.7317V21.1211L15.1895 19.6895"
                    stroke="currentColor"
                    strokeWidth="1.50056"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )
    }

    component.defaultProps = {
        color: '#0C0F0F',
        size: '24'
    }

    return component
})()

export default Option
