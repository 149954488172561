import { post } from 'utils'
export interface ApiGoogleBindInterface {
    api: 'third/google_bind'
    method: 'post'
    params: {
        /** Google ID令牌 */
        idToken: string
        /** 第三方登录接口返回的session id */
        sId: string
        /** 验证码 如果是登录后的绑定，则不需要传 */
        captcha?: string
        /** 手机/邮箱 如果是登录后的绑定，则不需要传 */
        account?: string
        countryCode?: number | string
    }
    headers: {

    },
    return: {
        accessToken: string
        expires: number
        signSecret: string
        showUid: number
    }
}
export const ApiGoogleBindAccount = async (params: ApiGoogleBindInterface['params']) => {
    return await post<ApiGoogleBindInterface>('third/google_bind', { ...params })
}
