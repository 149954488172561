import { camelCase, isObject, snakeCase } from 'lodash'

/**
 * 对象/数组下划线转驼峰/驼峰转下划线
 * @param obj [需要转换的数据]
 * @param style 'camel' | 'snake'
 * @param isShallow [一般不用传，作为返回数据用]
 */
export function convertKeyStyle(obj: Record<string, any> | Array<Record<string, any>>, style: 'camel' | 'snake', isShallow = false) {
    obj = obj ?? {}
    if (Array.isArray(obj)) {
        const result = [] as Array<Record<string, any>>
        obj.forEach((item, index) => {
            result[index] = isObject(item) ? convertKeyStyle(item, style, isShallow) : item
        })
        return result
    } else {
        const result = {} as Record<string, any>
        for (const [key, val] of Object.entries(obj)) {
            const convertedKey = style === 'snake' ? snakeCase(key) : camelCase(key)
            if (isShallow) {
                result[convertedKey] = val
            } else {
                result[convertedKey] = isObject(val) ? convertKeyStyle(val, style) : val
            }
        }
        return result
    }
}
