import { Divider, Space } from '@douyinfe/semi-ui'
import { ActionSheet } from 'components'
import ExclamationModal from 'components/ExclamationModal/ExclamationModal'
import P2PTrading from 'components/Icons/icons/P2PTrading'
import { domain, locale } from 'config'
import React, { Fragment } from 'react'
import { Deposit2 } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'
import { isPc } from 'utils'

import style from './DepositModal.module.scss'

interface Props {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}
const DepositModal = (props: Props) => {
    const { visible, setVisible } = props
    const navigator = useNavigate()

    const renderContent = () => {
        return (
            <Fragment>
                <h4>{t('Select deposit method')}</h4>
                {!isPc() && <Divider />}
                <Space>
                    <div
                        className={style.item}
                        onClick={() => navigator(`/${locale}/deposit`)}
                    >
                        <p>{t('I already own crypto.')}</p>
                        <Space>
                            <Deposit2 size={32} />
                            <b>{t('Deposit Crypto')}</b>
                        </Space>
                    </div>
                    <div
                        className={style.item}
                        onClick={() => window.open(`//otc.${domain}/${locale}/otc`)}
                    >
                        <p>{t('I want to buy crypto.')}</p>
                        <Space>
                            <P2PTrading size={32} />
                            <b>{t('P2P trading')}</b>
                        </Space>
                    </div>
                </Space>
            </Fragment>
        )
    }

    return (
        <>
            {isPc() ? (
                <ExclamationModal
                    visible={visible}
                    setVisible={setVisible}
                    width={480}
                    icon={false}
                    footer={null}
                >
                    <div className={style.depositModal}>{renderContent()}</div>
                </ExclamationModal>
            ) : (
                <ActionSheet
                    visible={visible}
                    setVisible={setVisible}
                >
                    <div className={classNames(style.depositModal, style.mobile)}>{renderContent()}</div>
                </ActionSheet>
            )}
        </>
    )
}

export default DepositModal
