import { Button, Dropdown, Space } from '@douyinfe/semi-ui'
import CommonCopy from 'components/CommonCopy/CommonCopy'
import { locale } from 'config'
import React from 'react'
import { Newcertified, Newusers, ShieldTick, User as UserICon } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'
import useStore from 'store/user'
import { toLogout } from 'utils'
// import { useMyBalanceStore } from 'views/User/MyBalance/store'
import avatarImg from 'views/User/Overview/Infos/assets/default_avatar.svg'

import style from './User.module.scss'

export const User = (): JSX.Element => {
    const [userInfo] = useStore(state => [state.userInfo])
    // 子账户新增业务：屏蔽 充币/提币 按钮
    // const [total, setTotal] = useState('')
    const navigator = useNavigate()
    // const isSub = useCheckSubAccount()
    // const { hidden, setHidden } = useMyBalanceStore()

    // const { loading } = useRequest(ApiGetAssetsTotal, {
    //     onSuccess: res => {
    //         setTotal(res.totalUsdtEstimation)
    //     }
    // })

    // const redirect = (path: string) => {
    //     window.location.href = `https://www.${domain}${path}`
    // }

    return (
        <section className={style.root}>
            <div
                className={style.overview}
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    navigator(`/${locale}/user/overview`)
                }}
            >
                <img
                    src={avatarImg}
                    alt=""
                />
                <Space
                    vertical
                    align="start"
                    className={style.info}
                >
                    <Space>
                        <p>{userInfo?.nickname || 'Parobit user'}</p>
                        <div className={classNames(style.vipIcon, `vip_${+(userInfo?.vipLevel ?? 0) >= 6 ? 6 : userInfo?.vipLevel}`)}></div>
                    </Space>
                    <Space>
                        <small>UID</small>
                        <small>{userInfo?.showUid}</small>
                        <CommonCopy
                            text={userInfo?.showUid as string}
                            size={14}
                        />
                    </Space>
                </Space>
            </div>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigator(`/${locale}/user/overview`)}>
                    <Space>
                        <UserICon size={20} />
                        <p>{t('Overview')}</p>
                    </Space>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigator(`/${locale}/user/security`)}>
                    <Space>
                        <ShieldTick size={20} />
                        <p>{t('Security')}</p>
                    </Space>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigator(`/${locale}/user/security/realNameVerification`)}>
                    <Space>
                        <Newcertified size={20} />
                        <p>{t('paro:Identification')}</p>
                    </Space>
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={() => navigator(`/${locale}/user/api`)}>
                    <Space>
                        <Api size={20} />
                        <p>{t('API setting')}</p>
                    </Space>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigator(`/${locale}/user/subaccount`)}>
                    <Space>
                        <Subaccount size={20} />
                        <p>{t('Sub account')}</p>
                    </Space>
                </Dropdown.Item> */}
                <Dropdown.Item onClick={() => navigator(`/${locale}/invite-friends`)}>
                    <Space>
                        <Newusers size={20} />
                        <p>{t('Referral')}</p>
                    </Space>
                </Dropdown.Item>
            </Dropdown.Menu>
            <Button
                block
                onClick={() => toLogout()}
            >
                {t('Log out')}
            </Button>
        </section>
    )
}
