/**
 * zustand 使用文档：https://github.com/pmndrs/zustand
 */
import { initialTheme, isRererse } from 'config'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

export interface workBeachState {
    theme: Theme
    isRererse: boolean
    setThemeData: (theme: Theme) => void
    setIsRererse: (isRererse: boolean) => void
}

const themeStore = create<workBeachState, any>(
    devtools(
        set => ({
            theme: initialTheme,
            isRererse: isRererse(),
            setThemeData: (theme: Theme) => {
                set({
                    theme
                })
            },
            setIsRererse: (isRererse: boolean) => {
                set({
                    isRererse
                })
            }
        }),
        { anonymousActionType: 'themeStore', enabled: import.meta.env.DEV }
    )
)

export default themeStore
