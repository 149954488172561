import { post } from 'utils'

export interface ApiErrorReportType {
    api: 'lark/send_for_white',
    method: 'post',
    params: {
        url: string;
        message: string;
    },
    return: {}
}

export const ApiErrorReport = async (params: ApiErrorReportType['params']) => {
    return await post<ApiErrorReportType>('lark/send_for_white', { ...params })
}
