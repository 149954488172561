import create from 'zustand'

interface IState {
    params: {
        userName: string
        regId: string
        regSign: string
    }
    update: (payload: IState['params']) => any
}

const useTelegramRegisterParams = create<IState, any>(set => {
    return {
        params: {
            userName: '',
            regId: '',
            regSign: ''
        },
        update: payload => {
            set({
                params: payload
            })
        }
    }
})

export default useTelegramRegisterParams
