import { ApiUSDTList, ApiUSDTListObj } from 'api/common/USDTList'
// import { useLayoutEffect } from 'react'
import { get } from 'utils'
import { objectWithNoSuchMethod } from 'utils/objectWithNoSuchMethod'
import create from 'zustand'
import { devtools } from 'zustand/middleware'
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
let USDTListTime: NodeJS.Timeout
const useUSDTList = create<USDTList, any>(
    devtools(
        set => {
            return {
                USDTList: undefined,
                getUSDTList() {
                    /**
                     * 获取交易对精度包
                     */

                    const fetchUsdt = async (_?: any, noValueKey?: any): Promise<void> => {
                        // 正在请求中，直接中断
                        // if (isFetchingUSDTList) return

                        // 读取本地缓存
                        const storageKey = 'USDT_list'
                        const USDTList: ApiUSDTListObj = JSON.parse(window.sessionStorage.getItem(storageKey) || '{}')

                        if (
                            Object.keys(USDTList)?.length > 2 && // Session 不为空
                            (USDTList.exprationTime ?? 0) > Date.now() && // 未过期情况
                            typeof noValueKey === 'undefined' // noValueKey 触发当前函数调用的 key 为空
                        ) {
                            USDTList.get = function (key: string | number): string | void {
                                return this[key]
                            }
                            // eslint-disable-next-line prettier/prettier
                            set(state => ({ ...state, USDTList: objectWithNoSuchMethod(USDTList, () => {}) }))
                            // eslint-disable-next-line prettier/prettier
                            window.USDTList = objectWithNoSuchMethod(USDTList, () => {})

                            // setLoading(false)
                            return
                        }

                        // isFetchingUSDTList = true

                        // if (Object.keys(USDTList)?.length === 0) {
                        //     // setLoading(true)
                        // }

                        const { list } = await get<ApiUSDTList>('rate/dc_to_usdt_list' + '?timestamp=' + Date.now())
                        Object.keys(list).forEach(item => {
                            USDTList[item.toUpperCase()] = +list[item]
                        })

                        USDTList.get = function (key: string | number): string | void {
                            return this[key]
                        }
                        USDTList.exprationTime = Date.now() + 1000 * 60 * 2 - 500
                        // eslint-disable-next-line prettier/prettier
                        set(state => ({ ...state, USDTList: objectWithNoSuchMethod(USDTList, () => {}) }))
                        // eslint-disable-next-line prettier/prettier
                        window.USDTList = objectWithNoSuchMethod(USDTList, () => {})
                        window.sessionStorage.setItem(storageKey, JSON.stringify(USDTList))
                        // isFetchingUSDTList = false
                        // setLoading(false)
                    }

                    if (window.USDTList === undefined) {
                        window.USDTList = {
                            get(key: string): string | void {
                                return this[key]
                            }
                        }
                    }
                    fetchUsdt()
                    USDTListTime = setInterval(() => {
                        fetchUsdt()
                    }, 120000)

                    // return () => {
                    //     clearInterval(USDTListTime)
                    // }
                }
            }
        },
        { name: 'useUSDTList', enabled: import.meta.env.DEV }
    )
)

export default useUSDTList
