import { RouterItem } from 'router'

export * from './router'

/** 菜单类型 */
export type Menu = {
    itemKey: string
    path: string
    text: string | undefined
    icon: JSX.Element | undefined
    items: Array<Menu>
    router: RouterItem
    parent: Menu | undefined
}

/** 斜杠路径 */
const slashPath = (source: string) => {
    return source[0] === '/' ? source : `/${source}`
}

/**
 * 路由权限访问
 * @param route 路由
 * @param role 权限者
 * @returns
 */
const hasPermission = (route: RouterItem, role: string) => {
    const permission = route.meta && route.meta.permission
    if (!permission) {
        return true
    }
    // 简单的：服务端的role = 客户端的permission；复杂的实现可以增加permission组合，例如添加修改删除细分
    return permission.find(item => item === role)
}

/**
 * 过滤授权后的路由
 * @param source 路由列表
 * @param role 权限者
 * @returns
 */
export function filterRouters(source: Array<RouterItem> | undefined, role: string): Array<RouterItem> | undefined {
    if (!source) {
        return undefined
    }
    const res: Array<RouterItem> = []
    const asyncList = source.filter(route => route.meta?.permission) // 过滤授权后的路由
    asyncList.forEach(item => {
        if (hasPermission(item, role)) {
            const cloneItem = Object.assign({}, item)
            res.push(cloneItem)
            item.children && (cloneItem.children = filterRouters(item.children, role))
        }
    })
    return res
}

/** 生成菜单 */
const generateMenu = (router: RouterItem, parent: Menu | undefined = undefined) => {
    const parentPath = parent && slashPath(parent.router.path)
    const path = slashPath(router.path)
    const menu: Menu = {
        itemKey: router.name,
        path: parentPath ? `${parentPath}${path}` : path,
        text: router.meta && router.meta.title,
        icon: router.meta && router.meta.icon,
        items: [],
        router,
        parent
    }
    if (router.children) {
        router.children.forEach(children => {
            !children.hidden && menu.items.push(generateMenu(children, menu))
        })
    }
    return menu
}

/** 生成菜单组 */
export const generateMenus = (routers: Array<RouterItem>): Array<Menu> => {
    return routers.filter(item => !item.hidden)?.map(item => generateMenu(item))
}

/** 获取当前路由基本信息 */
export function findMenuByPathname(menus: Array<Menu>, pathname: string): Menu | undefined {
    let rs = menus.find(item => item.path === pathname)
    if (!rs) {
        for (let i = 0; i < menus?.length; i++) {
            rs = findMenuByPathname(menus[i].items, pathname)
            if (rs) {
                break
            }
        }
    }
    return rs
}

/** 检查是否允许访问 */
export const checkPermission = (routers: Array<RouterItem>, pathname: string, role = 'user'): boolean => {
    pathname = pathname.substr(1)

    if (pathname === '') {
        return true
    }
    const ar = pathname.split('/')
    // 去掉前面的 lang 参数
    ar.splice(0, 2)
    let tmp: Array<RouterItem> = routers
    for (let i = 0; i < ar?.length; i++) {
        const leaf = tmp.find(item => item.name === ar[i])
        if (!leaf || !hasPermission(leaf, role)) {
            // 父节点的权限判断
            return false
        }
        if (i === ar?.length - 1) {
            // 终节点找到以后，并已经通过权限验证
            return true
        }
        if (!leaf.children) {
            break
        }
        tmp = leaf.children
    }
    return false
}

/** 获取菜单 */
export const getMenu = (menus: Array<Menu>, itemKey: string): Menu | undefined => {
    let rs = menus.find(item => item.itemKey === itemKey)
    if (!rs) {
        for (let i = 0; i < menus?.length; i++) {
            rs = getMenu(menus[i].items, itemKey)
            if (rs) {
                break
            }
        }
    }
    return rs
}

/** 生成面包屑 */
export const generateCrumbs = (menu: Menu | undefined): Array<any> => {
    const rs = []
    let tmp: Menu | undefined = menu
    while (tmp) {
        rs.push({
            name: tmp.text,
            path: tmp.path,
            noLink: false
        })
        tmp = tmp.parent
    }
    return rs.reverse()
}
