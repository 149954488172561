import { post } from '../../utils'

export interface IAssetsTotal {
    api: 'assets/total'
    method: 'post'
    return: {
        total: string
        totalUsdtEstimation: string
    }
}

export const ApiGetAssetsTotal = async () => {
    return await post<IAssetsTotal>('assets/total')
}
