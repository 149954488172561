import { Space } from '@douyinfe/semi-ui'
import { AsyncComponentWrapper } from 'components/AsyncComponentWrapper'
import FigureChart from 'components/FigureChart/FigureChart'
import DownArrow from 'components/Icons/icons/DownArrow'
import UpArrow from 'components/Icons/icons/UpArrow'
import { useCurrencyLogo } from 'hooks/useCurrencyLogo'
import { Suspense } from 'react'
import { isChinese, isPc, RMB2USD } from 'utils'

import { HotItem } from '../../TradingOpportunity'
import style from './Hot.module.scss'
interface Props extends HotItem {
    hotKlineLoading: boolean
}

const Hot = (props: Props) => {
    const [getLogoByMark] = useCurrencyLogo()

    console.log('Hot props:', props)

    return (
        <div
            className={style.item}
            key={props.instrumentId}
        >
            {getLogoByMark(props?.instrumentName?.replace('USDT', ''), [48, 48])}
            <h1>
                {props.instrumentName}
                <h5>/{Number(props.contractType) === 0 ? 'Perpetual' : 'MOVE'}</h5>
            </h1>
            <h5>{RMB2USD(props.newPrice, 8, 'USDT')}</h5>
            <Space className={style.chartWrap}>
                <Space
                    className={classNames({
                        [style.success]: Number(props.priceChangePercent) >= 0,
                        [style.danger]: Number(props.priceChangePercent) < 0,
                        [style.reverse]: isChinese()
                    })}
                >
                    {Number(props.priceChangePercent) < 0 ? <DownArrow /> : <UpArrow />}
                    <h2>{`${props.priceChangePercent}%`}</h2>
                </Space>
                <div className={style.figureChart}>
                    <Suspense fallback={null}>
                        <AsyncComponentWrapper>
                            {isPc() ? (
                                <FigureChart
                                    status={Number(props?.priceChangePercent) < 0 ? 0 : 1}
                                    loading={props.hotKlineLoading}
                                    chartData={props.kd && props.kd}
                                    height={72}
                                    width={96}
                                    lineSize={2}
                                />
                            ) : (
                                <FigureChart
                                    status={Number(props?.priceChangePercent) < 0 ? 0 : 1}
                                    loading={props.hotKlineLoading}
                                    chartData={props.kd && props.kd}
                                    height={48}
                                    width={138}
                                    lineSize={2}
                                />
                            )}
                        </AsyncComponentWrapper>
                    </Suspense>
                </div>
            </Space>
        </div>
    )
}

export default Hot
