import { domain } from 'config'
import { cookieOptions } from 'config/common.config'
import Cookies from 'js-cookie'
import { getLocale } from 'utils'

/** 判断用户是否登录 */
export const checkLogin: () => boolean = () => {
    const fields = ['m_showID', 'm_access']
    return fields.every(cookieName => Cookies.get(cookieName) !== undefined)
}

export const openLogin = () => {
    window.location.href = `//www.${domain}/${getLocale()}/login?backUrl=${window.location.pathname}`
}

export const openRegister = () => {
    window.location.href = `//www.${domain}/${getLocale()}/register?backUrl=${window.location.pathname}`
}

/** 退出登录 */
export const toLogout = (): void => {
    clearCookies()
    window.location.reload()
}

/** 删除session并跳转登录页 */
export const clearCookies: () => void = () => {
    Cookies.remove('m_access', cookieOptions)
    Cookies.remove('m_access_time', cookieOptions)
    Cookies.remove('m_showID', cookieOptions)

    window.dispatchEvent(new Event('storage'))
    window.dispatchEvent(new CustomEvent('cookiechange'))
}
