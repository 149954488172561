import { useTheme } from 'hooks'
import ContentLoader from 'react-content-loader'

interface Props {
    width?: number
    height?: number
}

export const ChartTemplate = ({ width = 100, height = 34.5 }: Props): JSX.Element => {
    const [theme] = useTheme()
    const opacity = theme === 'light' ? 1 : 0.05

    return (
        <ContentLoader
            width={width}
            height={height}
            style={{ width, height, margin: 0, opacity }}
        >
            <rect
                x="0"
                y="0"
                width={width}
                height={height}
            />
        </ContentLoader>
    )
}
