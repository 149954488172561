import { Space } from '@douyinfe/semi-ui'
import { IApiRevisionRankInfoItem } from 'api'
import { AsyncComponentWrapper } from 'components/AsyncComponentWrapper'
import FigureChart from 'components/FigureChart/FigureChart'
import DownArrow from 'components/Icons/icons/DownArrow'
import UpArrow from 'components/Icons/icons/UpArrow'
import { useCurrencyLogo } from 'hooks/useCurrencyLogo'
import { Suspense } from 'react'
import { isChinese, isPc, RMB2USD } from 'utils'

import style from './Recommeded.module.scss'
interface Props extends IApiRevisionRankInfoItem {
    minsKlineLoading: boolean
}

const Recommeded = (props: Props) => {
    const [getLogoByMark] = useCurrencyLogo()
    return (
        <div
            className={style.item}
            key={props.currencyId}
        >
            {getLogoByMark(props?.tradePair?.split('/')?.[0], [48, 48])}
            <h1>{props.tradePair}</h1>
            <h5>{RMB2USD(props.newPrice, 8, props?.tradePair?.split('/')[1])}</h5>
            <Space className={style.chartWrap}>
                <Space
                    className={classNames({
                        [style.success]: props.dayChangeStatus !== 0,
                        [style.danger]: props.dayChangeStatus === 0,
                        [style.reverse]: isChinese()
                    })}
                >
                    {props.dayChangeStatus === 0 ? <DownArrow /> : <UpArrow />}
                    <h2>{`${props.dayChangeRate}%`}</h2>
                </Space>
                <div className={style.figureChart}>
                    <Suspense fallback={null}>
                        <AsyncComponentWrapper>
                            {isPc() ? (
                                <FigureChart
                                    status={props.dayChangeStatus as 0 | 1}
                                    loading={props.minsKlineLoading}
                                    chartData={props.kd && props.kd}
                                    height={72}
                                    width={96}
                                    lineSize={2}
                                />
                            ) : (
                                <FigureChart
                                    status={props.dayChangeStatus as 0 | 1}
                                    loading={props.minsKlineLoading}
                                    chartData={props.kd && props.kd}
                                    height={48}
                                    width={138}
                                    lineSize={2}
                                />
                            )}
                        </AsyncComponentWrapper>
                    </Suspense>
                </div>
            </Space>
        </div>
    )
}

export default Recommeded
