import dLogo from 'components/QRCodeWithLogo/assets/logo.svg'
import styles from 'components/QRCodeWithLogo/QRCodeWithLogo.module.scss'
import { imgOrigin } from 'config'
import QRCode from 'qrcode.react'

interface QRCodeWithLogoProps extends WidgetClassName {
    id?: string
    size?: number
    value: string
    logo?: string
    style?: React.CSSProperties
}

// 旧文件复制而来
export const QRCodeWithLogo = ({ className, size = 200, value, id, logo, style }: QRCodeWithLogoProps): JSX.Element => {
    // 小尺寸时中间的logo要缩小，否则会扫不出来
    const smallSize = size < 100
    return (
        <section
            className={classNames(styles.root, className)}
            style={{ width: size, height: size, ...style }}
        >
            <QRCode
                id={id}
                value={value}
                size={size}
                level="M"
                style={{ width: '100%', height: '100%' }}
            />
            <div className={classNames(styles.container, logo && styles.usdt)}>
                <div
                    className={styles.logowrap}
                    style={smallSize ? { width: 24, height: 24 } : { width: 40, height: 40 }}
                >
                    {logo ? (
                        <img
                            src={`${imgOrigin}${logo}`}
                            width={smallSize ? 16 : 32}
                            height={smallSize ? 16 : 32}
                        />
                    ) : (
                        <img
                            src={dLogo}
                            style={{ width: smallSize ? 16 : 32, height: smallSize ? 16 : 32 }}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}
