import { post } from 'utils'

export interface LangItem {
    code: string
    fiatCurrencySymbol: string
    flagIos: string
    icon: string
    isGoogle: number
    name: string
    sort: number
}
export interface ApiLang {
    api: 'lang'
    method: 'post'
    return: LangItem[] | {}
}
export const ApiGetLang = async () => {
    return await post<ApiLang>('lang')
    // return {}
}
