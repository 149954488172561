import { FC, useEffect, useState } from 'react'

import styles from './FormTab.module.scss'

export interface ITabItem {
    label: string
    value: string
}

interface IFormTabProps {
    defaultVal?: string
    options: ITabItem[]
    onChange?: (v: string) => any
}

const FormTab: FC<IFormTabProps> = props => {
    const [active, setActive] = useState<string>(() => {
        return props.defaultVal || props.options?.[0].value || ''
    })
    useEffect(() => {
        setActive(props?.defaultVal ?? '')
    }, [props?.defaultVal])
    return (
        <div className={styles.formTab}>
            {props.options?.map(item => (
                <div
                    onClick={() => {
                        setActive(item.value)
                        props.onChange?.(item.value)
                    }}
                    key={item.value}
                    className={classNames(styles.formTabItem, active === item.value ? styles.formTabItemActive : '')}
                >
                    {item.label}
                </div>
            ))}
        </div>
    )
}

export default FormTab
