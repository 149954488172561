import { Button, Modal, Space } from '@douyinfe/semi-ui'
import { ModalReactProps } from '@douyinfe/semi-ui/lib/es/modal'
import { useWindowSize } from 'hooks'
import { ReactNode, SetStateAction, useEffect } from 'react'
import { CheckCircleOutlinedFill, Close, ExclamationCircleOutlinedFill } from 'react-dui-icons'

import style from './ExclamationModal.module.scss'

type DefIconType = 'success' | 'warning'
type DefListType = { [K in DefIconType]: JSX.Element }
export interface Props extends ModalReactProps {
    icon: false | string | ReactNode
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
    title?: string | ReactNode
    subTitle?: string | ReactNode
    okText?: string
    cancelText?: string
    onCancel?: () => void
    onOk?: (value?: any) => void
    content?: ReactNode
    footer?: ReactNode
    width?: number
    showCancel?: boolean
    showClose?: boolean
    btnLoading?: boolean
}

const ExclamationModal = (props: Props) => {
    const { icon = false, showCancel = true, showClose = true } = props
    const { isMobile } = useWindowSize()
    const renderIcon = (icon: DefIconType | ReactNode) => {
        const defList: DefListType = {
            success: (
                <CheckCircleOutlinedFill
                    className="CheckCircleOutlinedFill"
                    size={32}
                />
            ),
            warning: <ExclamationCircleOutlinedFill size={32} />
        }
        if (typeof icon === 'string' && Object.prototype.hasOwnProperty.call(defList, icon)) {
            return defList[icon as DefIconType]
        } else {
            return icon
        }
    }

    useEffect(() => {
        if (props.visible) {
            document.body.style.overflow = 'auto'
        }
    }, [props.visible])

    return (
        <Modal
            centered
            closable={false}
            visible={props.visible}
            className={classNames(style.exclamationModal, ...(props?.className ? [props.className] : []))}
            {...(props?.width ? { width: props.width } : { width: isMobile ? 360 : 400 })}
            footer={
                props?.footer || props?.footer === null ? (
                    props.footer
                ) : (
                    <Space
                        style={{ width: '100%' }}
                        spacing={12}
                    >
                        {showCancel && (
                            <Button
                                theme={'light'}
                                size={'large'}
                                style={{ width: '100%', marginLeft: 0 }}
                                onClick={() => {
                                    props.onCancel && props?.onCancel()
                                    props.setVisible(false)
                                }}
                            >
                                {props?.cancelText ?? t('Cancel')}
                            </Button>
                        )}
                        <Button
                            theme={'solid'}
                            size={'large'}
                            loading={props?.btnLoading}
                            style={{ width: '100%', marginLeft: 0 }}
                            onClick={() => {
                                props.onOk && props?.onOk()
                            }}
                        >
                            {props?.okText ?? t('Confirm')}
                        </Button>
                    </Space>
                )
            }
            zIndex={props?.zIndex ?? 1080}
        >
            {icon ? renderIcon(icon) : null}
            {props?.title ? <h5>{props?.title}</h5> : null}
            {props?.subTitle ? <p className={style.subTitle}>{props?.subTitle}</p> : null}
            {props?.children || null}
            {showClose && (
                <Close
                    onClick={() => {
                        props.onCancel && props?.onCancel()
                        props.setVisible(false)
                    }}
                    size={24}
                    className={style.close}
                />
            )}
        </Modal>
    )
}

export default ExclamationModal
