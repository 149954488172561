import { Button } from '@douyinfe/semi-ui'
import { ApiGoogleLoginCode, ApiGoogleLoginToken } from 'api/thirdLogin'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import { isTestEnv, locale } from 'config'
import { useWindowSize } from 'hooks'
import useBackUrl from 'hooks/useBackUrl'
import { useEffect, useRef, useState } from 'react'
import { WebGoogle } from 'react-dui-icons'
import { pixelReport, storage } from 'utils'
import { storeToken } from 'utils/token'

import style from './GoogleLoginBtn.module.scss'

interface GoogleLoginMethods {
    googleRegisterCb: (sId: string, idToken: string, email?: string) => void
}
// eslint-disable-next-line no-empty-pattern, prettier/prettier
const GoogleLoginButton = ({ googleRegisterCb }: GoogleLoginMethods): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null)
    const { isMobile } = useWindowSize()
    // const { hideLogin, openLogin } = useLogin()
    // const [isRegUser, setIsRegUser] = useState(0)
    const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false)
    const { aop } = useBackUrl(isMobile ? `/${locale}/` : `/${locale}/user`)

    const returnIdToken = async (user: any) => {
        // @ts-ignore
        console.log(user)
        let googleSid = ''
        let isReg = true
        let thirdEmail = ''
        await ApiGoogleLoginCode({ idToken: user.credential }).then(res => {
            console.log(res.sId)
            // setIsRegUser(res.isReguser)
            googleSid = res.sId
            thirdEmail = res.email ?? ''
            isReg = res.action === 'multi_bound' || res.action === 'register' || res.action === 'once_bound'
        })

        if (!isReg) {
            ApiGoogleLoginToken({ idToken: user.credential, sId: googleSid }).then(res => {
                if (res.accessToken) {
                    storage.setItem('recent-used-third-party', 'google')
                    storeToken(res)
                    Toast.success({ content: t('Web_Login_SuccessToast'), showClose: false })

                    if (window?.location?.pathname.includes('landing')) {
                        window?.location?.reload()
                    } else {
                        aop()
                    }
                }
            })
        } else {
            googleRegisterCb(googleSid, user.credential, thirdEmail)
        }
        // .catch(errcode => {
        //     /**
        //      * 350300: 第三方登录非法请求
        //      * 350302: 第三方登录失败
        //      * 350306: 第三方登录信息已过期，需要重新登录
        //      */
        //     if (errcode === 350306) {
        //         // Toast.fail(t('App_0824_C24') /** 第三方帐号登录信息已过期，请在第三方平台注销后重新登录 */)
        //         // openLogin()
        //     }
        // })
    }
    useEffect(() => {
        const scriptTag = document.createElement('script')
        scriptTag.src = 'https://accounts.google.com/gsi/client'
        scriptTag.async = true
        scriptTag.onload = () => {
            setScriptLoadedSuccessfully(true)
        }
        scriptTag.onerror = () => {
            setScriptLoadedSuccessfully(false)
        }
        containerRef.current && containerRef.current.appendChild(scriptTag)

        return () => {
            if (containerRef.current) {
                containerRef.current.innerHTML = ''
            }
            if ((window as any).google) {
                delete (window as any).google
            }
        }
    }, [])

    useEffect(() => {
        if (!scriptLoadedSuccessfully) {
            return
        }
        window.google?.accounts.id.initialize({
            client_id: isTestEnv ? '1073194479146-kfkrem3r6ndq1l5k5gidfgmnccbje1ht.apps.googleusercontent.com' : '67294459161-hfkoff2cp07dmdl6iq5uq596qlu72idj.apps.googleusercontent.com',
            callback: returnIdToken,
            prompt_parent_id: 'googleLogindiv',
            ux_mode: 'popup',
            ui_mode: 'card',
            // allowed_parent_origin: ['https://dev.digifinextest.com:1234', 'https://www.digifinextest.com'],
            scope: `openid profile email ${''}`
        })
        window.google?.accounts.id.renderButton(
            document.getElementById('googleLogindiv'),
            {
                theme: 'outline',
                size: 'large',
                width: 600,
                height: 48,
                click_listener: () => {
                    pixelReport('CLICK_GOOGLE_BTN')
                }
            } // customization attributes
        )
    }, [scriptLoadedSuccessfully])

    return (
        <div
            className={style.googleLogin}
            ref={containerRef}
        >
            <Button
                size={'large'}
                block
                theme={'borderless'}
                className={style.googleBtn}
            >
                <div className={style.googleLoginContent}>
                    <WebGoogle
                        className={style.googleLoginIcon}
                        size={18}
                    ></WebGoogle>{' '}
                    {isMobile ? 'Google' : t('Countinue with Google')}
                </div>
            </Button>
            <div
                className={style.btnDiv}
                id="googleLogindiv"
            ></div>
        </div>
    )
}
export default GoogleLoginButton
