import BigNumber from 'bignumber.js'
/**
 * 截取精度
 * @param num 输入数值
 * @param size 保留位数
 * @param mode mode 0-往两边 1-往中间 2-往右边 3-往左边 4-四舍五入 5-五舍六入 6-四舍六入五进偶数 7-四舍六入五往右边 8-四舍六入五往左边
 * @returns
 */
export const numberSize = (num: number | string | BigNumber, size: number | string, mode?: BigNumber.RoundingMode): string => {
    return new BigNumber(num).toFixed(Number(size), mode ?? 1)
}
