import React from 'react'

const NavFuturesBonus = /* #__PURE__ */ (function () {
    const component = function (props: any) {
        const { color, size, ...otherProps } = props
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="dui-icon"
                color={color}
                {...otherProps}
            >
                <path
                    d="M8.1334 3.68831C7.64945 2.73199 8.40192 1.61546 9.46997 1.70509L15.5154 2.21241C16.3392 2.28154 16.9144 3.05925 16.7394 3.86722L16.2992 5.89885C16.1627 6.52889 15.6053 6.97844 14.9607 6.97844H10.6403C10.1239 6.97844 9.6514 6.688 9.41824 6.22725L8.1334 3.68831Z"
                    fill="#FF9000"
                />
                <path
                    d="M3.83047 22.4999H20.7432L21.6351 20.6737C22.0915 17.478 22.0915 9.73858 12.0478 9.26032C3.83042 8.86901 -0.278447 17.9345 3.83047 22.4999Z"
                    fill="#3561FF"
                />
                <path
                    d="M14.8059 17.4673C14.8059 18.167 14.59 18.7289 14.1583 19.1532C13.7266 19.57 13.1461 19.8194 12.4166 19.9013V20.8726H11.6351V19.9124C10.9801 19.8678 10.4219 19.6891 9.96041 19.3765C9.50638 19.0565 9.18632 18.6285 9.00024 18.0925L10.7308 17.1212C10.8945 17.605 11.196 17.9027 11.6351 18.0144V16.6858L11.5346 16.6523C11.1848 16.5257 10.8945 16.4029 10.6638 16.2838C10.433 16.1647 10.1986 16.0122 9.96041 15.8261C9.72223 15.6326 9.54359 15.4018 9.4245 15.1339C9.31286 14.8585 9.25703 14.5421 9.25703 14.1848C9.25703 13.4852 9.48033 12.9269 9.92691 12.5101C10.3735 12.0858 10.9429 11.8439 11.6351 11.7844V10.8242H12.4166V11.8067C13.4885 11.9333 14.2477 12.4803 14.6942 13.448L12.9972 14.397C12.8856 14.0546 12.692 13.8313 12.4166 13.7271V15.0445H12.4278C12.465 15.0594 12.5692 15.1004 12.7404 15.1674C12.9191 15.2343 13.0344 15.279 13.0865 15.3013C13.1461 15.3237 13.254 15.372 13.4103 15.4465C13.5666 15.5135 13.6745 15.5693 13.7341 15.6139C13.7936 15.6512 13.8867 15.7144 14.0132 15.8038C14.1397 15.8856 14.229 15.9638 14.2811 16.0382C14.3332 16.1052 14.4002 16.1945 14.4821 16.3062C14.5714 16.4104 14.631 16.5183 14.6607 16.63C14.698 16.7416 14.7315 16.8719 14.7612 17.0207C14.791 17.1621 14.8059 17.311 14.8059 17.4673ZM11.1997 14.196C11.1997 14.3821 11.3448 14.5496 11.6351 14.6984V13.6824C11.3448 13.7569 11.1997 13.9281 11.1997 14.196ZM12.4166 18.0032C12.7144 17.9214 12.8632 17.7539 12.8632 17.5008C12.8632 17.2998 12.7144 17.1324 12.4166 16.9984V18.0032Z"
                    fill="white"
                />
            </svg>
        )
    }

    component.defaultProps = {
        color: '#0C0F0F',
        size: '24'
    }

    return component
})()

export default NavFuturesBonus
