/* eslint-disable prettier/prettier */
import { isPreEnv, isTestEnv } from 'config'

/** 获取当前webview的类型 */
export const getWebviewType = (): '' | 'iOS' | 'Android' => {
    if (window?.jsAndroid) {
        return 'Android'
    } else if (window?.webkit?.messageHandlers) {
        return 'iOS'
    } else {
        return ''
    }
}
export interface AppHandlerType {
    android: {
        jumpNative: (url: string) => void
        didAnswerForDual: () => void
        login: () => void
        getToken: () => string
        telegramLogin: (user: any) => void
    }
    ios: {
        getToken: (arg: Record<string, never>) => string
        login: (arg: Record<string, never>) => void
        didAnswerForDual: (arg: Record<string, never>) => void
        telegramLogin: (user: any) => void
    }
}

interface AppHelperType {
    ios: AppHandlerType['ios']
    android: AppHandlerType['android']
}

/**
 * App Webview中的JavaScript方法
 * 无参数时，iOS的方法似乎需要传空对象
 * 新增方法时，请在AppHandlerType中添加相应的类型
 */
export const AppHelper: AppHelperType = {
    ios: new Proxy(
        {},
        {
            get(_, name: keyof AppHandlerType['ios']) {
                const type = getWebviewType()
                if (type === 'iOS') {
                    return window?.webkit?.messageHandlers?.[name]?.postMessage.bind(window.webkit.messageHandlers[name])
                } else {
                    return () => {
                        (isTestEnv || isPreEnv) && console.error('AppHelper: 只能在app中调用AppHelper的方法')
                    }
                }
            }
        }
    ) as AppHandlerType['ios'],
    android: new Proxy(
        {},
        {
            get(_, name: keyof AppHandlerType['android']) {
                const type = getWebviewType()
                if (type === 'Android') {
                    return window?.jsAndroid?.[name].bind(window.jsAndroid)
                } else {
                    return () => {
                        (isTestEnv || isPreEnv) && console.error('AppHelper: 只能在app中调用AppHelper的方法')
                    }
                }
            }
        }
    ) as AppHandlerType['android']
}
