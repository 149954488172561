import { useTheme } from 'hooks'
import { Moon, Sun } from 'react-dui-icons'

import style from './ThemeSwitch.module.scss'

const ThemeSwitch = (): JSX.Element => {
    const [, setTheme] = useTheme()

    return (
        <div
            className={style.switchThem}
            onClick={(): void => setTheme()}
        >
            <Sun
                className={style.lightIcon}
                size="24"
                color="#ffffff"
            />
            <Moon
                className={style.darkIcon}
                size="24"
            />
        </div>
    )
}

export default ThemeSwitch
