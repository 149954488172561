import { cookieOptions } from 'config/common.config'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

import { ApiGetIp, ApiGetIpInfo } from '../../api/common'
import IpAlertModal from './IpAlertModal'

const IpAlert = (): JSX.Element => {
    const [visible, setVisible] = useState(false)
    const [ip, setIp] = useState<string>('')
    const [country, setCountry] = useState<string>('')

    const getInfo = async () => {
        const ipInfo = await ApiGetIpInfo()
        if (ipInfo.isPop) {
            const data = await ApiGetIp() // 弹窗提示，查询ip和国家用于展示
            setIp(data.ip)
            setCountry(data.country)
            setVisible(ipInfo.isPop)
        } else {
            setCookie('1')
        }
    }

    useEffect(() => {
        const expire = Cookies.get('SpecificIpAlert') === '1'
        if (expire) return
        getInfo()
    }, [])

    const onCloseFn = (): void => {
        setVisible(false)
    }

    const setCookie = (value: string): void => {
        Cookies.set('SpecificIpAlert', value, {
            ...cookieOptions,
            expires: 1
        })
    }

    const onConfirm = (): void => {
        setCookie('1')
        onCloseFn()
    }

    return (
        <IpAlertModal
            ip={ip}
            country={country}
            isShow={visible}
            button={t('Continue to Parobit')}
            onClick={onConfirm}
        />
    )
}

export default IpAlert
