const hasOwnProperty = Object.prototype.hasOwnProperty
const isSymbol = (obj: any): obj is symbol => Object.prototype?.toString?.call(obj) === '[object Symbol]'

interface ProxyObject {
    [key: string]: any
    [key: number]: any
}

/**
 * 监听 obj 上属性获取，如果为 undefined 则执行预设定方法
 * @param source 源 obj
 * @param noSuchMethod 调用此 obj 上不存在的属性时执行
 */
export function objectWithNoSuchMethod<T extends ProxyObject>(source: T = {} as T, noSuchMethod: Function = console.log): T {
    const obj = new Proxy(source, {
        get(target, prop): any {
            if (
                !hasOwnProperty.call(target, prop) &&
                process.env.NODE_ENV === 'production' // 开发环境禁用
            ) {
                noSuchMethod(target, prop)
                return prop
            }
            return target[isSymbol(prop) ? prop?.toString() : prop]
        }
    })
    return obj
}
