import { Button, Typography } from '@douyinfe/semi-ui'
import { Modal } from 'components/semi-ext'

import AlertSvg from './alert.svg'
import style from './IpAlert.module.scss'

interface IpAlertModalProps {
    isShow: boolean
    ip: string
    country: string
    button: string
    onClick?: Function
}

const IpAlertModal = ({ isShow, ip, country, button, onClick = () => {} }: IpAlertModalProps): JSX.Element => {
    const onConfirm = (): void => {
        onClick()
    }
    const { Paragraph, Title } = Typography
    return (
        <Modal
            className={style.ipAlert}
            visible={isShow}
            footer={null}
            closable={false}
            width={400}
            centered={true}
        >
            <div className={style.msgView}>
                <img
                    src={AlertSvg}
                    alt="alert"
                />
                <Title className={style.title}>
                    {t('ipAlertTitle')}
                    <br />
                    {ip}({country})
                </Title>
                <Paragraph className={style.content}>{t('ipAlertDesc')}</Paragraph>

                <Button
                    theme="solid"
                    type="primary"
                    key="console"
                    onClick={onConfirm}
                >
                    {button}
                </Button>
            </div>
        </Modal>
    )
}

export default IpAlertModal
