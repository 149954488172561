import { post } from 'utils'
export interface ApiTGbindAccountInterface {
    api: 'third/bind'
    method: 'post'
    params: {
        /** 第三方登录接口返回的标识 */
        regId: string
        /** 第三方登录接口返回的签名 */
        regSign: string
        /** 验证码 如果是登录后的绑定，则不需要传 */
        captcha?: string
        /** 手机/邮箱 如果是登录后的绑定，则不需要传 */
        account?: string
        countryCode?: number | string
    }
    return: {
        errcode?: number
        accessToken: string
        expires: number
        signSecret: string
        showUid: number
    }
}
export const ApiTGbindAccount = async (params: ApiTGbindAccountInterface['params']) => {
    return await post<ApiTGbindAccountInterface>('third/bind', { ...params })
}
