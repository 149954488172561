import loadable from '@loadable/component'

import { type RouterItem } from './index'

const commonPath = '/:locale'

export const activeRouter: Array<RouterItem> = [
    {
        name: 'holdingForEarningEvent',
        path: `${commonPath}/holding-for-earning-event`,
        component: loadable(() => import('../views/Act/HoldingForEarningEvent')),
        layout: { showHeader: false }
    },
    {
        name: 'points',
        path: `${commonPath}/active/points`,
        component: loadable(() => import('views/Act/Points/index'))
    },
    {
        name: 'trade',
        path: `${commonPath}/active/trade`,
        component: loadable(() => import('views/Act/Trade/index')),
        layout: { showHeader: false }
    },
    {
        name: 'tradeOrderList',
        path: `${commonPath}/active/trade/order-list`,
        component: loadable(() => import('views/Act/Trade/TradeOrderList')),
        layout: { showHeader: false }
    },
    {
        name: 'anniv',
        path: `${commonPath}/active/anniv`,
        component: loadable(() => import('views/Act/Anniv/Anniv')),
        layout: { showHeader: false }
    },
    {
        name: 'yearEndReport2023',
        path: `${commonPath}/active/YearEndReport/2023`,
        component: loadable(() => import('views/Act/YearEndReport/2023')),
        layout: { showHeader: false }
    },
    {
        name: 'MysteryBoxes',
        path: `${commonPath}/active/mystery-boxes`,
        component: loadable(() => import('views/Act/MysteryBoxes/MysteryBoxes'))
    }
]
