import type { ApiTokenCreate, ApiTokenRefresh } from 'api/token'
import { cookieOptions } from 'config/common.config'
import { AES, enc, mode, pad } from 'crypto-js'
import Cookies from 'js-cookie'

const { Hex, Base64: CryptoBase64, Utf8 } = enc
/** 储存token */
export const storeToken = (data: ApiTokenCreate['return']): void => {
    const { accessToken, signSecret, expires, showUid } = data
    const timestamp = Math.floor(Date.now() / 1000)

    const mAccessTime = (timestamp + expires)?.toString()
    const mAccess = encrypt(`{"access_token":"${accessToken}","access_key":"${signSecret}"}`)
    const mShowID = showUid

    Cookies.set('m_access_time', mAccessTime, { ...cookieOptions, expires: 1 })
    Cookies.set('m_access', mAccess, { ...cookieOptions, expires: 1 })
    Cookies.set('m_showID', mShowID?.toString(), { ...cookieOptions, expires: 1 })
}

/** 删除token */
export const clearToken = () => {
    Cookies.remove('m_access', cookieOptions)
    Cookies.remove('m_access_time', cookieOptions)
    Cookies.remove('m_showID', cookieOptions)
}

/** 刷新token */
export const updataToken = (data: ApiTokenRefresh['return']): void => {
    const { accessToken, expires } = data

    const timestamp = Math.floor(Date.now() / 1000)

    const mAccessTime = (timestamp + expires)?.toString()

    const { access_key: accessKey } = parseToken()
    const mAccess = encrypt(`{"access_token":"${accessToken}","access_key":"${accessKey}"}`)

    Cookies.set('m_access_time', mAccessTime, { ...cookieOptions, expires: 1 })
    Cookies.set('m_access', mAccess, { ...cookieOptions, expires: 1 })
}

/** 解析token加密数据 */
export function parseToken(): { access_token: string; access_key: string } {
    const defaultMAccess = { access_token: '', access_key: '' }
    const mAccess = Cookies.get('m_access')

    return mAccess === undefined || mAccess === '' ? defaultMAccess : JSON.parse(decrypt(mAccess))
}

/** 加密 */
function encrypt(word: string) {
    const srcs = enc.Utf8.parse(word)
    const key = Utf8.parse('tUYoAGpaX8K7GKdR')
    const iv = Utf8.parse('8741750136967789')
    const encrypted = AES.encrypt(srcs, key, { iv, mode: mode.CBC, padding: pad.Pkcs7 })
    return encrypted.ciphertext?.toString().toUpperCase()
}

/** 解密 */
export function decrypt(word: string) {
    const encryptedHexStr = Hex.parse(word)
    const srcs = CryptoBase64.stringify(encryptedHexStr)
    const key = Utf8.parse('tUYoAGpaX8K7GKdR')
    const iv = Utf8.parse('8741750136967789')
    const decryptMessage = AES.decrypt(srcs, key, { iv, mode: mode.CBC, padding: pad.Pkcs7 })
    return decryptMessage?.toString(Utf8)
}
