import { Button, Space } from '@douyinfe/semi-ui'
import { useInViewport } from 'ahooks'
import { locale } from 'config'
import { useEffect, useRef, useState } from 'react'
import { LongArrow } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'
import { checkLogin } from 'utils'

import style from './StartJourney.module.scss'

const StartJourney = (): JSX.Element => {
    const navigate = useNavigate()
    const ref = useRef<HTMLDivElement>(null)
    const [isInViewport] = useInViewport(ref)
    const [hasEnteredViewport, setHasEnteredViewport] = useState(false)

    const handleClick = () => {
        checkLogin() ? navigate(`/${locale}/markets`) : navigate(`/${locale}/register`)
    }

    useEffect(() => {
        if (isInViewport && !hasEnteredViewport) {
            setHasEnteredViewport(true)
        }
    }, [isInViewport, hasEnteredViewport])

    return (
        <div
            className={classNames({ [style.root]: true, [style.enter]: hasEnteredViewport })}
            ref={ref}
        >
            <h1>{t('paro:Start your crypto journey')}</h1>
            <h4>{t('paro:Create an account in one minute to enjoy a global, leading crypto trading experience!')}</h4>
            <Button
                theme="solid"
                size="large"
                className={style.btn}
                onClick={handleClick}
            >
                <Space>
                    <h4>{t('Start Now')}</h4>
                    <LongArrow />
                </Space>
            </Button>
        </div>
    )
}

export default StartJourney
