// 改版--首页置顶交易对
import { get } from 'utils'
export interface ApiBannerListItem {
    pic: string // 图片uri
    jumpUrl: string // 跳转链接
    color: string // 背景色
    type: number // 类型，前端用不到
}
export interface ApiBannerListType {
    api: 'banner/list'
    method: 'get'
    params: {
        isNewWeb: boolean // 是否新版web
    }
    return: {
        list: ApiBannerListItem[]
        bgcolor: string // banner背景色
        bgimage: string // banner背景图
        bgurl: string // banner背景图跳转链接（PC使用）
        status: 0 | 1 // banner背景图状态（PC使用），1-启用 0-禁用
    }
}

export const ApiBannerList = async (params: ApiBannerListType['params']) => {
    return await get<ApiBannerListType>('banner/list', { ...params })
}
