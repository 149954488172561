import { post } from '../../utils'
import { AssetType } from './ApiAssetLineChart'

export const enum timeType {
    OneDay = 1,
    OneWeek = 2,
    OneMonth = 3,
    SixMonth = 4
}
export interface IApiTotalListType {
    api: 'asset/v1/total_list'
    params: {
        timeType: timeType
        assetType: AssetType
    }
    method: 'post'
    return: {
        contract: string
        derive: string
        margin: string
        otc: string
        spot: string
        totalAsset: {
            change: string
            rate: string
            total: string
        }
    }
}

export const ApiTotalList = async (params: IApiTotalListType['params']) => {
    return await post<IApiTotalListType>('asset/v1/total_list', { ...params })
}
