import { Button, Space } from '@douyinfe/semi-ui'
import { useInViewport } from 'ahooks'
import { locale } from 'config'
import { useEffect, useRef, useState } from 'react'
import { LongArrow } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'
import { checkLogin, safeSprintf } from 'utils'

import style from './StartTrade.module.scss'

const StartTrade = (): JSX.Element => {
    const navigate = useNavigate()
    const ref = useRef<HTMLDivElement>(null)
    const [isInViewport] = useInViewport(ref)
    const [hasEnteredViewport, setHasEnteredViewport] = useState(false)

    const handleClick = () => {
        checkLogin() ? navigate(`/${locale}/markets`) : navigate(`/${locale}/register`)
    }

    useEffect(() => {
        if (isInViewport && !hasEnteredViewport) {
            setHasEnteredViewport(true)
        }
    }, [isInViewport, hasEnteredViewport])

    return (
        <div
            className={style.root}
            ref={ref}
        >
            <h1 className={hasEnteredViewport ? style.enter : ''}>{t('paro:Safety, Convenience, Innovation')}</h1>
            <h1
                className={hasEnteredViewport ? style.enter : ''}
                dangerouslySetInnerHTML={{ __html: safeSprintf(t('paro:It’s all here at %s'), '<span>Parobit</span>') }}
            />
            <Button
                theme="solid"
                size="large"
                className={classNames({ [style.btn]: true, [style.enter]: hasEnteredViewport })}
                onClick={handleClick}
            >
                <Space>
                    <h4>{t('Start Now')}</h4>
                    <LongArrow />
                </Space>
            </Button>
        </div>
    )
}

export default StartTrade
