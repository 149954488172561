import { post } from '../../utils'

export type Id = string | number
export interface ApiMessageReadAllType {
    api: 'message/read-all'
    params: {
        id?: Id
    }
    method: 'post'
    return: {}
}

export interface ApiMessageReadOneType {
    api: 'message/read-one'
    params: {
        id: Id
    }
    method: 'post'
    return: {}
}

export const ApiSetMessageReadAll = async (body: { id?: Id }) => {
    return await post<ApiMessageReadAllType>('message/read-all', body)
}
export const ApiSetMessageReadOne = async (body: { id: Id }) => {
    return await post<ApiMessageReadOneType>('message/read-one', body)
}
