// import { Popover } from '@douyinfe/semi-ui'
import { Popover } from '@douyinfe/semi-ui'
import { useEffect, useState } from 'react'
import { CustomerServiceFill } from 'react-dui-icons'

// import { CustomerServiceFill } from 'react-dui-icons'
import style from './PcOnline.module.scss'
import { zEHide, zEOpen, zEShow, zETag } from './tools'

// 文档地址：https://developer.zendesk.com/api-reference/widget/api/

interface PropType {
    visible?: boolean // 控制是否显示客服组件
    floatIcon?: boolean // 最否显示右下角悬浮icon
    onInit?: () => void
    onClick?: () => void
    bottom?: number // 自定义位置
}
export const PcOnline = ({ visible = true, floatIcon = false, onInit, onClick, bottom }: PropType): JSX.Element => {
    const [init, setInit] = useState<Boolean>(false) // 是否成功初始化
    useEffect(() => {
        if (!window.zE) {
            zETag(() => {
                setInit(true)
            })
        }
    }, [])

    useEffect(() => {
        if (!init) return
        if (visible) {
            zEShow()
        } else {
            zEHide()
        }
        if (init) {
            onInit?.()
        }
    }, [visible, init])

    return (
        <section>
            {init && floatIcon && (
                <div
                    onClick={
                        onClick
                            ? () => {
                                  onClick?.()
                              }
                            : () => {
                                  zEOpen()
                                  window?.gtag?.('event', 'click_contact_hover')
                              }
                    }
                    className={style.pcOnline}
                    style={{ bottom }}
                >
                    <Popover
                        position="left"
                        content={<article style={{ padding: 12 }}>{'chat with us 👋'}</article>}
                    >
                        <div>
                            <CustomerServiceFill size={24} />
                        </div>
                    </Popover>
                </div>
            )}
        </section>
    )
}
