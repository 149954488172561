import { Space } from '@douyinfe/semi-ui'
import { useInViewport } from 'ahooks'
import { useEffect, useRef, useState } from 'react'

import style from './OurStrengths.module.scss'

const OurStrengths = () => {
    const ref = useRef<HTMLDivElement>(null)
    const [isInViewport] = useInViewport(ref)
    const [hasEnteredViewport, setHasEnteredViewport] = useState(false)

    const DATA = [
        {
            title: t('paro:Security First, Worry-Free Protection'),
            desc: t('paro:Bank-grade security measures, multi-signature technology, and cold storage mechanisms ensure the absolute safety of user assets and data. A real-time risk control system provides 24/7 monitoring to protect every transaction.')
        },
        {
            title: t('paro:Efficient trading, fast matching'),
            desc: t('paro:Equipped with an advanced matching engine that supports processing millions of transactions per second, ensuring smooth and delay-free trading. Whether you are a beginner or an experienced user, you can enjoy a lightning-fast and efficient trading experience.')
        },
        {
            title: t('paro:Multiple markets, worldwide service'),
            desc: t('paro:Covering major global crypto assets and offering a wide range of trading pairs. Our platform supports multiple languages and device access, allowing you to stay on top of market trends and seize every investment opportunity, anytime, anywhere.')
        }
    ]

    useEffect(() => {
        if (isInViewport && !hasEnteredViewport) {
            setHasEnteredViewport(true)
        }
    }, [isInViewport, hasEnteredViewport])

    return (
        <section
            className={classNames({ [style.root]: true, [style.enter]: hasEnteredViewport })}
            ref={ref}
        >
            <h1>{t('paro:Our Strengths')}</h1>
            <Space className={style.list}>
                {DATA.map((item, index) => (
                    <Space
                        key={index}
                        vertical
                        className={style.item}
                    >
                        <div className={style.icon} />
                        <h3>{item.title}</h3>
                        <h5>{item.desc}</h5>
                    </Space>
                ))}
            </Space>
        </section>
    )
}

export default OurStrengths
