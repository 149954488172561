import { Button } from '@douyinfe/semi-ui'
import { Header } from 'components'
import { isPc } from 'utils'

import notImg from './assets/not_found.svg'
import style from './Notfound.module.scss'

export const NotFound = () => {
    return (
        <section className={style.root}>
            <Header />
            <div className={style.main}>
                <img
                    src={notImg}
                    alt=""
                />
                <h1>404</h1>
                <h5>{t('This mysterious space can no longer be found in the crypto world...')}</h5>
                <div>
                    <Button
                        theme="solid"
                        type="primary"
                        size={isPc() ? 'large' : 'default'}
                        onClick={() => {
                            window?.location?.replace(window?.location?.origin)
                        }}
                    >
                        {t('Go Back')}
                    </Button>
                </div>
            </div>
        </section>
    )
}
