import { Button } from '@douyinfe/semi-ui'
import { ValidateStatus } from '@douyinfe/semi-ui/lib/es/_base/baseComponent'
import { ApiTokenCreate, IGetAccountByInviteCode } from 'api'
import md5 from 'blueimp-md5'
import AgreementBox from 'components/AgreementBox/AgreementBox'
import GoogleLoginButton from 'components/GoogleLoginBtn/GoogleLoginBtn'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import TelegramLoginBtn from 'components/TelegramLoginBtn/TelegramLoginBtn'
import { locale } from 'config'
import useBackUrl from 'hooks/useBackUrl'
import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react'
import { ArrowDownFill } from 'react-dui-icons'
import { useLocation } from 'react-router'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useGoogleLoginParams from 'store/useGoogleLoginParams'
import useLoginData from 'store/useLoginData'
import UseTelegramRegisterParams from 'store/useTelegramRegisterParams'
import { pixelReport, post, safeSprintf, storage, verify } from 'utils'
import { storeToken } from 'utils/token'
import { api } from 'views/Auth/Register/api/api'
import ApiOclrLoginRegisterParams from 'views/Auth/Register/api/ApiOclrLoginRegisterParams'
import ThirdRegisterForm from 'views/Auth/Register/components/ThirdRegisterForm/ThirdRegisterForm'
import { ApiOclrSendCaptchaParams } from 'views/Auth/Register/types/oclrSendCaptcha'
import shallow from 'zustand/shallow'

import FormItem from '../FormItem/FormItem'
import FormTab from '../FormTab/FormTab'
import styles from './RegisterForm.module.scss'

export interface IRegisterFormProps {
    labelShow?: boolean
    isSide?: boolean
    setType?: (type: 'login' | 'reg') => void
    customBackUrl?: string // 自定义返回地址
    landingChannelCode?: string // 落地页渠道码
}

const RegisterForm: FC<IRegisterFormProps> = props => {
    const { isSide = false, setType } = props // 侧边栏模式
    const navigate = useNavigate()
    const location = useLocation()
    const presetAccount = location.state?.presetAccount || ''
    const [searchParams] = useSearchParams()

    const inviteCodeFromParams = searchParams.get('inviteCode')
    const inviteCodeFromStorage = storage.getItem('res_invite_code')
    const shouldShowInviteField = !(inviteCodeFromParams || inviteCodeFromStorage)
    // state
    const [tab, setTab] = useState<'e' | 'p'>(() => {
        if (verify(presetAccount, 'email')) {
            return 'e'
        }
        if (verify(presetAccount, 'phone')) {
            return 'p'
        }
        return 'e'
    })
    const [email, setEmail] = useState<string>('')
    const [emailVerification, setEmailVerification] = useState(false)
    const [password, setPassword] = useState<string>('')
    const [passwordVerification, setPasswordVerification] = useState(false)
    const [phone, setPhone] = useState<string>('')
    // 不带区号的纯净手机号
    const [purePhone, setPurePhone] = useState('')
    const [phoneVerification, setPhoneVerification] = useState(false)
    const [inviteCode, setInviteCode] = useState(() => {
        return inviteCodeFromParams || inviteCodeFromStorage || ''
    })
    /** 注册渠道码 url有带则取 无则读缓存  */
    const channelCode = new URLSearchParams(window.location.search).get('channelCode') ?? storage.getItem('channelCode', 'local', 'reg') ?? ''
    const [agreement, setAgreement] = useState(false)
    const [agreementTip, setAgreementTip] = useState(false)
    const [inviteShow, setInviteShow] = useState<boolean>(!!inviteCode)
    const [inviteStatus, setInviteStatus] = useState<ValidateStatus>('default')
    // 侧边栏模式下为刷新当前页
    const { aop, backUrl } = useBackUrl(isSide ? window?.location?.pathname : '')

    // 三个子表单
    const [activeBox, setActiveBox] = useState<'form' | 'ver' | 'third'>('form')
    const [verificationCode, setVerificationCode] = useState('')
    const [verificationCodeOk, setVerificationCodeOk] = useState(false)
    const [thirdType, setThirdType] = useState<'Telegram' | 'Google' | ''>('')
    const [thirdEmail, setThirdEmail] = useState('')
    const captchaRef = useRef<{ captchaClick(): any }>({
        // eslint-disable-next-line prettier/prettier
        captchaClick: () => {}
    })

    // store
    const loginData = useLoginData(state => state)
    const telegramRegisterParams = UseTelegramRegisterParams()
    const googleupdate = useGoogleLoginParams(state => state, shallow)
    const createBtnDisabled = useMemo(() => {
        // if (!agreement) {
        //     return true
        // }
        if (tab === 'e') {
            return !(email?.length >= 0 && password?.length >= 0 && emailVerification && passwordVerification)
        } else {
            return !(phone?.length >= 0 && password?.length >= 0 && phoneVerification && passwordVerification)
        }
    }, [agreement, tab, email, phone, password, emailVerification, phoneVerification, passwordVerification])
    const activeBtnDisabled = useMemo(() => {
        return !verificationCodeOk
    }, [verificationCode])
    const account = useMemo(() => {
        if (tab === 'e') {
            return emailVerification ? email : ''
        } else {
            if (phoneVerification && phone.indexOf('+') > -1) {
                return phone.split('+')[1]
            } else {
                return ''
            }
        }
    }, [phone, email, emailVerification, phoneVerification, tab])
    const verificationStyle: CSSProperties = useMemo((): CSSProperties => {
        return activeBox !== 'ver' ? { display: 'none' } : {}
    }, [activeBox])
    const verificationData = useMemo(() => {
        let data: ApiOclrSendCaptchaParams
        if (tab === 'e') {
            data = {
                captchaId: '',
                account: email,
                oclrMethod: 'email',
                oclrScene: 'register',
                cOtpType: 2
            }
        } else {
            const phoneArr = phone.split('+')
            data = {
                captchaId: '',
                areaCode: Number(phoneArr[0]),
                account: phoneArr[1],
                oclrMethod: 'phone',
                oclrScene: 'register',
                cOtpType: 1
            }
        }
        return data
    }, [phone, email])

    // func
    const checkInviteCode = () => {
        // google data
        window?.gtag?.('event', 'click_verity_invitation_code')
        // google data
        return new Promise<void>((resolve, reject) => {
            post<IGetAccountByInviteCode>('invite/inviter_account', {
                inviteCode
            })
                .then(data => {
                    if (data.account) {
                        setInviteStatus('success')
                        storage.setItem('res_invite_code', inviteCode)
                        storage.setItem('res_invite_account', data.account)
                        resolve()
                    } else {
                        setInviteStatus('error')
                        reject(new Error(''))
                    }
                })
                .catch(() => {
                    setInviteStatus('error')
                    if (inviteCode === storage.getItem('res_invite_code')) {
                        storage.delItem('res_invite_code')
                        storage.delItem('res_invite_account')
                    }
                    reject(new Error(''))
                })
        })
    }

    const checkSubmit = () => {
        if (!agreement) {
            setAgreementTip(true)
            return false
        }

        setAgreementTip(false)
        return true
    }

    const switchVerification = () => {
        if (!checkSubmit()) {
            return false
        }

        if (inviteCode) {
            checkInviteCode()
                .then(() => {
                    captchaRef.current.captchaClick()
                })
                .catch(() => setInviteShow(true))
        } else {
            captchaRef.current.captchaClick()
        }
        pixelReport('CLICK_CREATE_ACCOUNT')
        // 落地页点击发起注册
        if (window.location.pathname.includes('landing')) {
            window?.gtag?.('event', 'langdingpage_reg_click', { channel: props?.landingChannelCode ?? '' })
        }
    }
    const captchaCb = () => {
        setActiveBox('ver')
        // google data
        window?.gtag?.('event', 'click_signup_submit', {
            way: verificationData.oclrMethod
        })
        // google data
    }

    const activateAccount = () => {
        const data = {} as ApiOclrLoginRegisterParams
        data.oclrScene = 'register'
        if (channelCode !== '') {
            data.channelCode = channelCode
        }
        const sensorsParams = {} as {
            register_channel: string
            account_type: 'phone' | 'email'
            is_success: boolean
            reason_failure: string | number
            is_invited: boolean
            invited_by: string
        }
        if (tab === 'e') {
            data.account = email
            data.oclrMethod = 'email'
            data.captcha = verificationCode
            data.pwd = md5(password)
            sensorsParams.account_type = 'email'
        } else if (tab === 'p') {
            const phoneArr = phone.split('+')
            data.account = phoneArr[1]
            data.oclrMethod = 'phone'
            data.captcha = verificationCode
            data.areaCode = Number(phoneArr[0])
            data.pwd = md5(password)
            sensorsParams.account_type = 'phone'
        }

        if (inviteCode) {
            data.inviteCode = inviteCode
        }

        // google data
        window?.gtag?.('event', 'click_verify_sign_up', {
            verity_way: verificationData.oclrMethod
        })
        // google data

        // @ts-ignore
        post(api.oclrLoginRegister, data).then((res: any) => {
            if (res?.errcode) {
                const errCodeStr = String((res as any).errcode)
                if (errCodeStr === '200084' || errCodeStr === '200085' || errCodeStr === '200086') {
                    /** 200084: 账号或密码错误 200085: 验证码错误 200086: 谷歌验证码错误 */
                    const text = safeSprintf(t(errCodeStr, { ns: 'errCode' }), res.data.args[0])
                    Toast.error({ content: text, showClose: false })
                }
            } else {
                // @ts-ignore
                storeToken(res as any)
                Toast.success({ content: t('Account created successfully'), showClose: false })
                // 注册后展示telegram入口
                storage.setItem('after-register', { telegramEntrance: true })
                isSide ? window.location.reload() : props?.customBackUrl ? navigate(props?.customBackUrl) : aop()
            }
        })
    }

    const telegramRegisterCb = (userName: string, regId: string, regSign: string) => {
        // google data
        window?.gtag?.('event', 'click_signup_with_telegram')
        // google data
        telegramRegisterParams.update({ userName, regId, regSign })
        setActiveBox('third')
        setThirdType('Telegram')
    }
    const googleRegisterCb = (sId: string, idToken: string, email?: string) => {
        window?.gtag?.('event', 'click_signup_with_google')
        googleupdate.update(sId, idToken)
        setThirdEmail(email ?? '')
        setActiveBox('third')
        setThirdType('Google')
        console.log('googleRegcb', sId, idToken)
    }
    const telegramLoginCb = (token: ApiTokenCreate['return']) => {
        // google data
        window?.gtag?.('event', 'click_login_with_telegram')
        // google data
        storeToken(token)
        Toast.success({ content: t('Web_Login_SuccessToast'), showClose: false })
        aop()
    }
    // effect
    useEffect(() => {
        loginData.loadingThirdParty()
    }, [])
    return (
        <div className={classNames(styles.registerForm, isSide && styles.isSide)}>
            {activeBox === 'form' && (
                <div className={styles.registerBox}>
                    {props.labelShow && <div className={styles.registerFormTitle}>{t('Create an Account')}</div>}
                    <div className={styles.toLogin}>
                        {t('Already have account?')}
                        <span
                            className={styles.toLoginNav}
                            onClick={() => (isSide ? setType?.('login') : navigate(`/${locale}/login?backUrl=${props?.customBackUrl || backUrl}`))}
                        >
                            {t('Log in Now')}
                        </span>
                    </div>
                    <FormTab
                        defaultVal={tab}
                        options={[
                            { label: t('Email Address'), value: 'e' },
                            { label: t('Phone number'), value: 'p' }
                        ]}
                        onChange={v => setTab(v as 'e' | 'p')}
                    ></FormTab>
                    <FormItem
                        defaultVal={email}
                        show={tab === 'e'}
                        onChange={(v, b) => {
                            setEmail(v)
                            setEmailVerification(b)
                        }}
                        type={'email'}
                    ></FormItem>
                    <FormItem
                        show={tab === 'p'}
                        defaultVal={purePhone}
                        type={'phone'}
                        onPurePhoneChange={v => setPurePhone(v)}
                        onChange={(v, b) => {
                            setPhone(v)
                            setPhoneVerification(b)
                        }}
                    ></FormItem>
                    <FormItem
                        defaultVal={password}
                        type={'pwd'}
                        onChange={(v, b) => {
                            setPassword(v)
                            setPasswordVerification(b)
                        }}
                    ></FormItem>
                    {/* 自動帶入邀請碼時隱藏 */}
                    {shouldShowInviteField && (
                        <FormItem
                            defaultVal={inviteCode}
                            inviteStatus={inviteStatus}
                            // style={{ marginBottom: '48px' }}
                            label={
                                <span
                                    className={styles.inviteLabelBox}
                                    onClick={() => setInviteShow(pre => !pre)}
                                >
                                    {t('Invite code(Optional)')}
                                    {inviteShow ? (
                                        <ArrowDownFill
                                            size={16}
                                            className={classNames(styles.inviteArrow, styles.reserve)}
                                        />
                                    ) : (
                                        <ArrowDownFill
                                            size={16}
                                            className={styles.inviteArrow}
                                        />
                                    )}
                                </span>
                            }
                            showInp={inviteShow}
                            onChange={v => {
                                setInviteCode(v)
                                if (v?.length === 0) {
                                    setInviteStatus('default')
                                }
                            }}
                            type={'inviteCode'}
                        ></FormItem>
                    )}
                    <AgreementBox
                        checked={agreement}
                        onChange={b => setAgreement(b)}
                    ></AgreementBox>
                    {agreementTip && <p className={styles.errorTip}>{t('Please tick the box to confirm that you consent to the Terms of Use')}</p>}
                    <div className={styles.operationItem}>
                        <Button
                            size={'large'}
                            theme="solid"
                            type="primary"
                            disabled={createBtnDisabled}
                            onClick={switchVerification}
                            block
                        >
                            {t('Create Account')}
                        </Button>
                    </div>
                    <div className={styles.divider}>
                        <div className={styles.left}></div>
                        <div className={styles.text}>{t('Or')}</div>
                        <div className={styles.right}></div>
                    </div>
                    <div className={styles.thirdParty}>
                        <GoogleLoginButton googleRegisterCb={googleRegisterCb} />
                        <TelegramLoginBtn
                            agreement={agreement}
                            registerCb={telegramRegisterCb}
                            loginCb={telegramLoginCb}
                        ></TelegramLoginBtn>
                    </div>
                </div>
            )}
            <div
                className={styles.verificationBox}
                style={verificationStyle}
            >
                {props.labelShow && (
                    <div
                        style={{ marginBottom: '8px' }}
                        className={styles.registerFormTitle}
                    >
                        {t('Enter the verification code')}
                    </div>
                )}
                <div className={styles.sendMsgTip}>
                    {t('We hve sent a 6-digit verification code to ')}
                    <span className={styles.msgAccount}>{tab === 'e' ? email : `+${phone?.replace('+', ' ')}`}</span>
                </div>
                <FormItem
                    captchaCb={captchaCb}
                    captchaRef={captchaRef}
                    verificationData={verificationData}
                    account={account}
                    // style={{ marginBottom: '48px' }}
                    type={'verification'}
                    onChange={(val, valid) => {
                        setVerificationCode(val)
                        setVerificationCodeOk(valid)
                    }}
                ></FormItem>
                <Button
                    theme="solid"
                    type={'primary'}
                    disabled={activeBtnDisabled}
                    block
                    onClick={activateAccount}
                >
                    {t('Activate Account')}
                </Button>
            </div>
            {activeBox === 'third' && (
                <ThirdRegisterForm
                    thirdPreSetEmail={thirdEmail}
                    labelShow={props.labelShow}
                    type={thirdType}
                ></ThirdRegisterForm>
            )}
        </div>
    )
}

RegisterForm.defaultProps = {
    labelShow: true
}

export default RegisterForm
