import { UAParser } from 'ua-parser-js'

/** 判断是否是type=App app内嵌打开
 * @return {boolean}
 */
export const isApp = () => {
    const type = new URLSearchParams(window.location.search).get('type')
    if (type === 'App') {
        return true
    }

    const parser = new UAParser()
    const device = parser.getOS().name

    switch (device) {
        case 'iOS':
            return !!window?.webkit?.messageHandlers?.getToken?.postMessage
        case 'Android':
            return !!window?.jsAndroid?.getToken
    }

    return false
}

/**
 * 如果当前url存在type=App, 则在新的url中追加type=App
 * @param search 当前已存在的查询参数
 */
export const appendTypeApp = (search: AnyObj = {}): string => {
    const isTypeApp = new URLSearchParams(window.location.search).get('type') === 'App'
    const tmp = isTypeApp ? { ...search, type: 'App' } : search
    const url = new URLSearchParams(tmp)?.toString()
    return url ? `?${url}` : url
}

/**
 * 判断ios和安卓
 */
export const AndroidOrIos = (): 'IOS' | 'Android' | '' => {
    const parser = new UAParser()
    const device = parser.getOS().name

    switch (device) {
        case 'iOS':
            return 'IOS'
        case 'Android':
            return 'Android'
    }

    return ''
}
