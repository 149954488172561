import { post } from 'utils'
export interface ApiGoogleLoginCodeInterface {
    api: 'google_login/code'
    method: 'post'
    params: {
        idToken: string
    }
    return: {
        /** session id */
        sId: string
        /** 是否新用户 1=是 0=否 */
        action: 'login' | 'register' | 'once_bound' | 'multi_bound'
        email?: string
    }
}
export const ApiGoogleLoginCode = async (params: ApiGoogleLoginCodeInterface['params']) => {
    return await post<ApiGoogleLoginCodeInterface>('google_login/code', { ...params })
}
