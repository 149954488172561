export enum api {
    // eslint-disable-next-line no-unused-vars
    oclrSendCaptcha = 'oclr_send_captcha',
    // eslint-disable-next-line no-unused-vars
    oclrLoginRegister = 'oclr_login_register',
    // eslint-disable-next-line no-unused-vars
    inviteInviterAccount = 'invite/inviter_account',
    // eslint-disable-next-line no-unused-vars
    exlinkGetlink = 'exlink/getlink',
    // eslint-disable-next-line no-unused-vars
    scanLoginQrcodeGenerate = 'scan_login/qrcode/generate',
    // eslint-disable-next-line no-unused-vars
    scanLoginQrcodeCheck = 'scan_login/qrcode/check',
    // eslint-disable-next-line no-unused-vars
    scanLoginToken = 'scan_login/token',
    /** 第三方登录注册新用户 */
    // eslint-disable-next-line no-unused-vars
    thirdRegister = 'third/register'
}
