import classNames from 'classnames'
import { isPc } from 'utils'

import styles from './RegisterBanner.module.scss'

const RegisterBanner = (): JSX.Element => {
    return (
        <div className={classNames(styles.registerBanner, !isPc() && styles.registerBannerMobile)}>
            <img
                src={'/main_static/images/auth/banner.svg'}
                className={styles.pcBanner}
                alt="banner"
            />
            <div className={styles.text1}>{t('Welcome to Parobit!')}</div>
            <div className={styles.text2}>{t('start your crypto trading journey!')}</div>
        </div>
    )
}

export default RegisterBanner
