import { StorageType } from 'utils'

// 域名(判断是否是客户端使用的域名 统一在这个配置修改)
export const domain = window.location.hostname.split('.').slice(-2).join('.')

// 网站及本地存储名称
export const webName = 'parobit'

// Api请求前缀
export const apiPrefix = process.env.NODE_ENV === 'production' ? `https://api.${domain}/` : '/api/'

// 用户自定义上传图片网址前缀
export const imgOrigin = `https://static2.${domain}`

/** NFT市场接口 */
export const NFTUrl = 'gamefi/v1/'

/** NFT市场接口版本 */
export const NFTVersion = 'v1'

// 邀请码本地存储名称及位置
export const invCodeStorageInfo: { name: string; type: StorageType; projectName: string } = {
    name: 'inviteCode',
    type: 'local',
    projectName: 'reg' // 默认存储在 reg 项目下
}

// 合约防跨域请求接口域名
export const wwwPrefixDrv = '/'
