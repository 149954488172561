import { getCookie } from 'utils/cookie'

// 暗色/亮色cookie主题key
export const themeKey = 'd_theme'
// 旧仓库的key
export const themeKey2 = 'szb_bg'

// 初始主题
export const initialTheme = (getCookie(themeKey) || 'light') as Theme

export const colorReverseKey = 'is_color_reverse'
export const isRererse = () => localStorage.getItem(colorReverseKey) === '2'
