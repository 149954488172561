import { Button, Space } from '@douyinfe/semi-ui'
import { useInViewport, useRequest } from 'ahooks'
import { ApiContractHistoryKline, ApiContractList, ApiContractListItem, ApiRevisionRankInfo, IApiRevisionRankInfoItem, RevisionRankType } from 'api'
import { ApiGetMinsKline } from 'api/kline/ApiGetMinsKline'
import { locale } from 'config'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { LongArrow } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'
import { checkLogin } from 'utils'

import Hot from './components/Hot/Hot'
import Recommeded from './components/Recommeded/Recommeded'
import style from './TradingOpportunity.module.scss'

enum ITab {
    Recommended = 0,
    HotDerivatives = 1
}
export interface HotItem extends ApiContractListItem {
    kd?: string[]
}
const TradingOpportunity = (): JSX.Element => {
    const navigate = useNavigate()
    const ref = useRef<HTMLDivElement>(null)
    const [isInViewport] = useInViewport(ref)
    const [activeTab, setActiveTab] = useState<ITab>(ITab.Recommended)
    const [recommendedList, setRecommendedList] = useState<IApiRevisionRankInfoItem[]>([])
    const [hotList, setHotList] = useState<HotItem[]>([])
    const [minsKlineLoading, setMinsKlineLoading] = useState(false)
    const [hotKlineList, setHotKlineList] = useState<{ [key: string]: string[] }>({})
    const [hotKlineLoading, setHotKlineLoading] = useState(false)
    const [hasEnteredViewport, setHasEnteredViewport] = useState(false)

    const TAB = [
        {
            key: ITab.Recommended,
            label: t('Recommended')
        },
        {
            key: ITab.HotDerivatives,
            label: t('Hot Derivatives')
        }
    ]

    useRequest(() => ApiRevisionRankInfo({ rankType: RevisionRankType.Hot }), {
        onSuccess: res => {
            const list = res?.filter(item => +item.takeDown === 1)
            setRecommendedList(_.take(list, 6))
            getMinsKline(_.take(list, 6))
        }
    })

    useRequest(ApiContractList, {
        onSuccess: res => {
            setHotList(_.take(res.list, 6))
            getKlines(_.take(res.list, 6))
        }
    })

    // hot derivatives kline
    const getKlines = async (data: ApiContractListItem[]) => {
        setHotKlineLoading(true)
        try {
            data.forEach(async item => {
                const { o } = await ApiContractHistoryKline({
                    instrument_id: item.instrumentId,
                    kline_type: '30m',
                    from: String(dayjs().subtract(1, 'day').unix()),
                    to: String(dayjs().unix())
                })
                setHotKlineList(pre => {
                    return {
                        ...pre,
                        [item.instrumentId]: o
                    }
                })
            })
            setHotList(pre => {
                return pre.map(item => {
                    if (hotKlineList[item.instrumentId] !== undefined) {
                        item.kd = hotKlineList[item.instrumentId]
                    }
                    return item
                })
            })
        } catch (error) {
            console.log('error: ', error)
        } finally {
            setHotKlineLoading(false)
        }
    }

    // recommended kline
    const getMinsKline = async (data: IApiRevisionRankInfoItem[]) => {
        setMinsKlineLoading(true)
        try {
            const ids = data
                .map(i => {
                    if (i.currencyId && i.baseId) {
                        return `${i.currencyId}_${i.baseId}`
                    }
                })
                .filter(i => !!i)
                .join()
            if (ids) {
                const res = await ApiGetMinsKline({ pairs: ids })
                const kd = res.list
                setRecommendedList(pre => {
                    return pre.map(item => {
                        if (kd?.[`${item.currencyId}${item.baseId}`] !== undefined) {
                            item.kd = kd[`${item.currencyId}${item.baseId}`]
                        }
                        return item
                    })
                })
            }
        } catch (error) {
            console.log('error: ', error)
        } finally {
            setMinsKlineLoading(false)
        }
    }

    const handleClick = () => {
        checkLogin() ? navigate(`/${locale}/markets`) : navigate(`/${locale}/register`)
    }

    useEffect(() => {
        if (activeTab === ITab.HotDerivatives) {
            getKlines(hotList)
        }
    }, [activeTab])

    useEffect(() => {
        if (isInViewport && !hasEnteredViewport) {
            setHasEnteredViewport(true)
        }
    }, [isInViewport, hasEnteredViewport])

    return (
        <div
            className={classNames({ [style.root]: true, [style.enter]: hasEnteredViewport })}
            ref={ref}
        >
            <Space
                vertical
                align="start"
                className={style.left}
            >
                <h1>{t('Find Your Next Trading Opportunity')}</h1>
                <p>{t('paro:Simply and securely buy, sell, and manage hundreds of cryptocurrencies.')}</p>
                <Button
                    theme="solid"
                    size="large"
                    className={style.btn}
                    onClick={handleClick}
                >
                    <Space>
                        <h4>{t('paro:See more')}</h4>
                        <LongArrow />
                    </Space>
                </Button>
            </Space>
            <Space
                vertical
                align="start"
                className={style.right}
            >
                <Space className={style.tabs}>
                    {TAB.map(item => (
                        <div
                            className={classNames({ [style.tab]: true, [style.active]: activeTab === item.key })}
                            key={item.key}
                            onClick={() => setActiveTab(item.key)}
                        >
                            {item.label}
                        </div>
                    ))}
                </Space>
                {activeTab === ITab.Recommended ? (
                    <div className={style.list}>
                        {recommendedList.map(item => (
                            <Recommeded
                                key={item.currencyId}
                                {...item}
                                minsKlineLoading={minsKlineLoading}
                            />
                        ))}
                    </div>
                ) : (
                    <div className={style.list}>
                        {hotList.map(item => (
                            <Hot
                                key={item.instrumentId}
                                {...item}
                                hotKlineLoading={hotKlineLoading}
                            />
                        ))}
                    </div>
                )}
            </Space>
        </div>
    )
}

export default TradingOpportunity
