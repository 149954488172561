export const REGION_PHONE_LIST: IRegionCodeAll[] = [
    {
        regionMark: 'AF',
        regionName: 'Afghanistan',
        regionCode: '93',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AL',
        regionName: 'Albania',
        regionCode: '355',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'DZ',
        regionName: 'Algeria',
        regionCode: '213',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AS',
        regionName: 'American Samoa',
        regionCode: '1684',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AD',
        regionName: 'Andorra',
        regionCode: '376',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AO',
        regionName: 'Angola',
        regionCode: '244',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AI',
        regionName: 'Anguilla',
        regionCode: '1264',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AG',
        regionName: 'Antigua and Barbuda',
        regionCode: '1268',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AR',
        regionName: 'Argentina',
        regionCode: '54',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AM',
        regionName: 'Armenia',
        regionCode: '374',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AW',
        regionName: 'Aruba',
        regionCode: '297',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AU',
        regionName: 'Australia',
        regionCode: '61',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AT',
        regionName: 'Austria',
        regionCode: '43',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AZ',
        regionName: 'Azerbaijan',
        regionCode: '994',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BS',
        regionName: 'Bahamas',
        regionCode: '1242',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BH',
        regionName: 'Bahrain',
        regionCode: '973',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BD',
        regionName: 'Bangladesh',
        regionCode: '880',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BB',
        regionName: 'Barbados',
        regionCode: '1246',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BY',
        regionName: 'Belarus',
        regionCode: '375',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BE',
        regionName: 'Belgium',
        regionCode: '32',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BZ',
        regionName: 'Belize',
        regionCode: '501',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BJ',
        regionName: 'Benin',
        regionCode: '229',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BM',
        regionName: 'Bermuda',
        regionCode: '1441',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BT',
        regionName: 'Bhutan',
        regionCode: '975',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BO',
        regionName: 'Bolivia',
        regionCode: '591',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BQ',
        regionName: 'Bonaire Sint Eustatius and Saba',
        regionCode: '599',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BA',
        regionName: 'Bosnia and Herzegovina',
        regionCode: '387',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BW',
        regionName: 'Botswana',
        regionCode: '267',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BR',
        regionName: 'Brazil',
        regionCode: '55',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BN',
        regionName: 'Brunei',
        regionCode: '673',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BG',
        regionName: 'Bulgaria',
        regionCode: '359',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BF',
        regionName: 'Burkina Faso',
        regionCode: '226',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'BI',
        regionName: 'Burundi',
        regionCode: '257',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KH',
        regionName: 'Cambodia',
        regionCode: '855',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CM',
        regionName: 'Cameroon',
        regionCode: '237',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CA',
        regionName: 'Canada',
        regionCode: '1',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CV',
        regionName: 'Cape Verde',
        regionCode: '238',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KY',
        regionName: 'Cayman Islands',
        regionCode: '1345',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CF',
        regionName: 'Central African Republic',
        regionCode: '236',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TD',
        regionName: 'Chad',
        regionCode: '235',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CL',
        regionName: 'Chile',
        regionCode: '56',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CN',
        regionName: 'China',
        regionCode: '86',
        emoji: null,
        disable: 1
    },
    {
        regionMark: 'CO',
        regionName: 'Colombia',
        regionCode: '57',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KM',
        regionName: 'Comoros',
        regionCode: '269',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CK',
        regionName: 'Cook Islands',
        regionCode: '682',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CR',
        regionName: 'Costa Rica',
        regionCode: '506',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'HR',
        regionName: 'Croatia',
        regionCode: '385',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CU',
        regionName: 'Cuba',
        regionCode: '53',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CW',
        regionName: 'Curacao',
        regionCode: '599',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CY',
        regionName: 'Cyprus',
        regionCode: '357',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CZ',
        regionName: 'Czech Republic',
        regionCode: '420',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CG',
        regionName: 'Democratic Republic of the Congo',
        regionCode: '243',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'DK',
        regionName: 'Denmark',
        regionCode: '45',
        emoji: null,
        disable: 0
    },
    {
        regionMark: '',
        regionName: 'Diego Garcia',
        regionCode: '246',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'DJ',
        regionName: 'Djibouti',
        regionCode: '253',
        emoji: null,
        disable: 0
    },
    {
        regionMark: '',
        regionName: 'Dominica',
        regionCode: '1767',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'DM',
        regionName: 'Dominican Republic',
        regionCode: '1809',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'DM',
        regionName: 'Dominican Republic',
        regionCode: '1829',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'DM',
        regionName: 'Dominican Republic',
        regionCode: '1849',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TL',
        regionName: 'East Timor',
        regionCode: '670',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'EC',
        regionName: 'Ecuador',
        regionCode: '593',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'EG',
        regionName: 'Egypt',
        regionCode: '20',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SV',
        regionName: 'El Salvador',
        regionCode: '503',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GN',
        regionName: 'Equatorial Guinea',
        regionCode: '240',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ER',
        regionName: 'Eritrea',
        regionCode: '291',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'EE',
        regionName: 'Estonia',
        regionCode: '372',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ET',
        regionName: 'Ethiopia',
        regionCode: '251',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'FK',
        regionName: 'Falkland Islands',
        regionCode: '500',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'FO',
        regionName: 'Faroe Islands',
        regionCode: '298',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'FJ',
        regionName: 'Fiji',
        regionCode: '679',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'FI',
        regionName: 'Finland',
        regionCode: '358',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'FR',
        regionName: 'France',
        regionCode: '33',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GF',
        regionName: 'French Guiana',
        regionCode: '594',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PF',
        regionName: 'French Polynesia',
        regionCode: '689',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GA',
        regionName: 'Gabon',
        regionCode: '241',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GM',
        regionName: 'Gambia',
        regionCode: '220',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GE',
        regionName: 'Georgia',
        regionCode: '995',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'DE',
        regionName: 'Germany',
        regionCode: '49',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GH',
        regionName: 'Ghana',
        regionCode: '233',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GI',
        regionName: 'Gibraltar',
        regionCode: '350',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GR',
        regionName: 'Greece',
        regionCode: '30',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GL',
        regionName: 'Greenland',
        regionCode: '299',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GD',
        regionName: 'Grenada',
        regionCode: '1473',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GP',
        regionName: 'Guadeloupe',
        regionCode: '590',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GU',
        regionName: 'Guam',
        regionCode: '1671',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GT',
        regionName: 'Guatemala',
        regionCode: '502',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PG',
        regionName: 'Guinea',
        regionCode: '224',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GW',
        regionName: 'Guinea-Bissau',
        regionCode: '245',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GF',
        regionName: 'Guyana',
        regionCode: '592',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'HT',
        regionName: 'Haiti',
        regionCode: '509',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'HN',
        regionName: 'Honduras',
        regionCode: '504',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'HK',
        regionName: 'Hong Kong',
        regionCode: '852',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'HU',
        regionName: 'Hungary',
        regionCode: '36',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'IS',
        regionName: 'Iceland',
        regionCode: '354',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'IN',
        regionName: 'India',
        regionCode: '91',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ID',
        regionName: 'Indonesia',
        regionCode: '62',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'IR',
        regionName: 'Iran',
        regionCode: '98',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'IQ',
        regionName: 'Iraq',
        regionCode: '964',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'IE',
        regionName: 'Ireland',
        regionCode: '353',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'IL',
        regionName: 'Israel',
        regionCode: '972',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'IT',
        regionName: 'Italy',
        regionCode: '39',
        emoji: null,
        disable: 0
    },
    {
        regionMark: '',
        regionName: 'Ivory Coast',
        regionCode: '225',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'JM',
        regionName: 'Jamaica',
        regionCode: '1658',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'JM',
        regionName: 'Jamaica',
        regionCode: '1876',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'JP',
        regionName: 'Japan',
        regionCode: '81',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'JO',
        regionName: 'Jordan',
        regionCode: '962',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KZ',
        regionName: 'Kazakhstan',
        regionCode: '7',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KE',
        regionName: 'Kenya',
        regionCode: '254',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KI',
        regionName: 'Kiribati',
        regionCode: '686',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KP',
        regionName: "Korea (Democratic People's Republic of) ",
        regionCode: '850',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'XK',
        regionName: 'Kosovo (Republic of)',
        regionCode: '383',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KW',
        regionName: 'Kuwait',
        regionCode: '965',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KG',
        regionName: 'Kyrgyzstan',
        regionCode: '996',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LA',
        regionName: 'Laos',
        regionCode: '856',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LV',
        regionName: 'Latvia',
        regionCode: '371',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LB',
        regionName: 'Lebanon',
        regionCode: '961',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LS',
        regionName: 'Lesotho',
        regionCode: '266',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LR',
        regionName: 'Liberia',
        regionCode: '231',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LY',
        regionName: 'Libya',
        regionCode: '218',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LI',
        regionName: 'Liechtenstein',
        regionCode: '423',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LT',
        regionName: 'Lithuania',
        regionCode: '370',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LU',
        regionName: 'Luxembourg',
        regionCode: '352',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MO',
        regionName: 'Macau',
        regionCode: '853',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MK',
        regionName: 'Macedonia',
        regionCode: '389',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MG',
        regionName: 'Madagascar',
        regionCode: '261',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MW',
        regionName: 'Malawi',
        regionCode: '265',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MY',
        regionName: 'Malaysia',
        regionCode: '60',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MV',
        regionName: 'Maldives',
        regionCode: '960',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ML',
        regionName: 'Mali',
        regionCode: '223',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MT',
        regionName: 'Malta',
        regionCode: '356',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MH',
        regionName: 'Marshall Islands (Republic of the)',
        regionCode: '692',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MQ',
        regionName: 'Martinique',
        regionCode: '596',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MR',
        regionName: 'Mauritania',
        regionCode: '222',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MU',
        regionName: 'Mauritius',
        regionCode: '230',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'YT',
        regionName: 'Mayotte',
        regionCode: '262',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MX',
        regionName: 'Mexico',
        regionCode: '52',
        emoji: null,
        disable: 0
    },
    {
        regionMark: '',
        regionName: 'Micronesia',
        regionCode: '691',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MD',
        regionName: 'Moldova',
        regionCode: '373',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MC',
        regionName: 'Monaco',
        regionCode: '377',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MN',
        regionName: 'Mongolia',
        regionCode: '976',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ME',
        regionName: 'Montenegro',
        regionCode: '382',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MS',
        regionName: 'Montserrat',
        regionCode: '1664',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MA',
        regionName: 'Morocco',
        regionCode: '212',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MZ',
        regionName: 'Mozambique',
        regionCode: '258',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MM',
        regionName: 'Myanmar',
        regionCode: '95',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NA',
        regionName: 'Namibia',
        regionCode: '264',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NR',
        regionName: 'Nauru',
        regionCode: '674',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NP',
        regionName: 'Nepal',
        regionCode: '977',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NL',
        regionName: 'Netherlands',
        regionCode: '31',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NC',
        regionName: 'New Caledonia',
        regionCode: '687',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NZ',
        regionName: 'New Zealand',
        regionCode: '64',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NI',
        regionName: 'Nicaragua',
        regionCode: '505',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NE',
        regionName: 'Niger',
        regionCode: '227',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NG',
        regionName: 'Nigeria',
        regionCode: '234',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NU',
        regionName: 'Niue',
        regionCode: '683',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MP',
        regionName: 'Northern Mariana Islands (Commonwealth of the)',
        regionCode: '1670',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'NO',
        regionName: 'Norway',
        regionCode: '47',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'OM',
        regionName: 'Oman',
        regionCode: '968',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PK',
        regionName: 'Pakistan',
        regionCode: '92',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PW',
        regionName: 'Palau',
        regionCode: '680',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PS',
        regionName: 'Palestinian Territory',
        regionCode: '970',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PA',
        regionName: 'Panama',
        regionCode: '507',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PG',
        regionName: 'Papua New Guinea',
        regionCode: '675',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PY',
        regionName: 'Paraguay',
        regionCode: '595',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PE',
        regionName: 'Peru',
        regionCode: '51',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PH',
        regionName: 'Philippines',
        regionCode: '63',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PL',
        regionName: 'Poland',
        regionCode: '48',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PT',
        regionName: 'Portugal',
        regionCode: '351',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PR',
        regionName: 'Puerto Rico',
        regionCode: '1787',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PR',
        regionName: 'Puerto Rico',
        regionCode: '1939',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'QA',
        regionName: 'Qatar',
        regionCode: '974',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CG',
        regionName: 'Republic Of The Congo',
        regionCode: '242',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'RE',
        regionName: 'Reunion Island',
        regionCode: '262',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'RO',
        regionName: 'Romania',
        regionCode: '40',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'RU',
        regionName: 'Russia',
        regionCode: '7',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'RW',
        regionName: 'Rwanda',
        regionCode: '250',
        emoji: null,
        disable: 0
    },
    {
        regionMark: '',
        regionName: 'Saint Helena, Ascension and Tristan da Cunha',
        regionCode: '247',
        emoji: null,
        disable: 0
    },
    {
        regionMark: '',
        regionName: 'Saint Helena, Ascension and Tristan da Cunha',
        regionCode: '290',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KN',
        regionName: 'Saint Kitts and Nevis',
        regionCode: '1869',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LC',
        regionName: 'Saint Lucia',
        regionCode: '1758',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'PM',
        regionName: 'Saint Pierre and Miquelon',
        regionCode: '508',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'VC',
        regionName: 'Saint Vincent and The Grenadines',
        regionCode: '1784',
        emoji: null,
        disable: 0
    },
    {
        regionMark: '',
        regionName: 'Samoa',
        regionCode: '685',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SM',
        regionName: 'San Marino',
        regionCode: '378',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ST',
        regionName: 'Sao Tome and Principe',
        regionCode: '239',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SA',
        regionName: 'Saudi Arabia',
        regionCode: '966',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SN',
        regionName: 'Senegal',
        regionCode: '221',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'RS',
        regionName: 'Serbia',
        regionCode: '381',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SC',
        regionName: 'Seychelles',
        regionCode: '248',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SL',
        regionName: 'Sierra Leone',
        regionCode: '232',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SG',
        regionName: 'Singapore',
        regionCode: '65',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'MF',
        regionName: 'Sint Maarten (Dutch Part)',
        regionCode: '1721',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SK',
        regionName: 'Slovakia',
        regionCode: '421',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SI',
        regionName: 'Slovenia',
        regionCode: '386',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SB',
        regionName: 'Solomon Islands',
        regionCode: '677',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SO',
        regionName: 'Somalia',
        regionCode: '252',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ZA',
        regionName: 'South Africa',
        regionCode: '27',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'KR',
        regionName: 'South Korea',
        regionCode: '82',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SS',
        regionName: 'South Sudan',
        regionCode: '211',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ES',
        regionName: 'Spain',
        regionCode: '34',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'LK',
        regionName: 'Sri Lanka',
        regionCode: '94',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SD',
        regionName: 'Sudan',
        regionCode: '249',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SR',
        regionName: 'Suriname',
        regionCode: '597',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SZ',
        regionName: 'Swaziland',
        regionCode: '268',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SE',
        regionName: 'Sweden',
        regionCode: '46',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'CH',
        regionName: 'Switzerland',
        regionCode: '41',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'SY',
        regionName: 'Syria',
        regionCode: '963',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TW',
        regionName: 'Taiwan',
        regionCode: '886',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TJ',
        regionName: 'Tajikistan',
        regionCode: '992',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TZ',
        regionName: 'Tanzania',
        regionCode: '255',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TH',
        regionName: 'Thailand',
        regionCode: '66',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TG',
        regionName: 'Togo',
        regionCode: '228',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TK',
        regionName: 'Tokelau',
        regionCode: '690',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TO',
        regionName: 'Tonga',
        regionCode: '676',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TT',
        regionName: 'Trinidad and Tobago',
        regionCode: '1868',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TN',
        regionName: 'Tunisia',
        regionCode: '216',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TR',
        regionName: 'Turkey',
        regionCode: '90',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TM',
        regionName: 'Turkmenistan',
        regionCode: '993',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TC',
        regionName: 'Turks and Caicos Islands',
        regionCode: '1649',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'TV',
        regionName: 'Tuvalu',
        regionCode: '688',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'UG',
        regionName: 'Uganda',
        regionCode: '256',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'UA',
        regionName: 'Ukraine',
        regionCode: '380',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'AE',
        regionName: 'United Arab Emirates',
        regionCode: '971',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'GB',
        regionName: 'United Kingdom',
        regionCode: '44',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'US',
        regionName: 'United States',
        regionCode: '1',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'VI',
        regionName: 'United States Virgin Islands',
        regionCode: '1340',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'UY',
        regionName: 'Uruguay',
        regionCode: '598',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'UZ',
        regionName: 'Uzbekistan',
        regionCode: '998',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'VU',
        regionName: 'Vanuatu',
        regionCode: '678',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'VA',
        regionName: 'Vatican City State',
        regionCode: '379',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'VE',
        regionName: 'Venezuela',
        regionCode: '58',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'VN',
        regionName: 'Vietnam',
        regionCode: '84',
        emoji: null,
        disable: 0
    },
    {
        regionMark: '',
        regionName: 'Virgin Islands, British',
        regionCode: '1284',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'WF',
        regionName: 'Wallis and Futuna',
        regionCode: '681',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'YE',
        regionName: 'Yemen',
        regionCode: '967',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ZM',
        regionName: 'Zambia',
        regionCode: '260',
        emoji: null,
        disable: 0
    },
    {
        regionMark: 'ZW',
        regionName: 'Zimbabwe',
        regionCode: '263',
        emoji: null,
        disable: 0
    }
]
