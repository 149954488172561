/** 多语言 */
// import './index'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface I18nProps {
    children: React.ReactNode
}

const I18n = ({ children }: I18nProps): JSX.Element => {
    /** 加入全局多语言 */
    // const { namespaces } = useNameSpaces()
    const { t } = useTranslation()
    window.t = t
    /** 加入全局多语言END */

    // 打开翻译模式
    const openCrowdin = () => {
        const script1 = document.createElement('script')
        script1.type = 'text/javascript'
        script1.src = 'https://cdn.crowdin.com/jipt/jipt.js'
        const demo = document.getElementsByTagName('head')[0]
        demo && demo?.appendChild(script1)

        const script2 = document.createElement('script')
        script2.type = 'text/javascript'
        script2.innerHTML = `
            const _jipt = []
            _jipt.push(['project', 'parobit'])
        `
        demo && demo?.appendChild(script2)
    }

    useEffect(() => {
        if (window.location.pathname.split('/')[1] === 'ca-ca' && window.location.hostname.split('.').slice(-2).join('.') === 'btceth01.com') {
            openCrowdin()
        }
    }, [])

    // useEffect(() => {
    //     i18next.setDefaultNamespace(namespaces as any)
    // }, [namespaces])

    return <>{children}</>
}

export default I18n
