import { useLanguageList } from 'hooks'
// import { useEffect, useState } from 'react'
import { Check } from 'react-dui-icons'

// import { ApiLang } from '../../../api'
import { locale } from '../../../config'
// import { post, storage } from '../../../utils'
import { setCookie } from '../../../utils/cookie'
import { Link } from '../../Link'
import style from './LangList.module.scss'

export const LangList = (): JSX.Element => {
    const {
        languageList: langList,
        currentLanguage: curName
    } = useLanguageList()

    return (
        <div className={style.root}>
            <div className={style.title}>{t('Language')}</div>
            <div className={classNames(style.content, style.scrollbar)}>
                <Link
                    to={window.location.pathname}
                    className={classNames(style.lang, style.active)}
                >
                    <span className={style.active}>{curName?.name}</span>
                    <Check size={16} />
                </Link>

                {langList?.map(
                    ({ code, name }) =>
                        locale !== code && (
                            <Link
                                className={classNames(style.lang)}
                                href={window.location.pathname.replace(locale, code)}
                                onClick={(): void => {
                                    setCookie('lang', code, 365)

                                    window.location.href = window.location.pathname.replace(locale, code)
                                }}
                                key={code}
                            >
                                <span>{name}</span>
                                {locale === code && <Check size={16} />}
                            </Link>
                        )
                )}
            </div>
        </div>
    )
}
