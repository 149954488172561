import { ApiRateList } from 'api/rate'
import { locale } from 'config'
import { useEffect, useState } from 'react'
import { Check } from 'react-dui-icons'
import { extraLang, fetchRateList, storage } from 'utils'
import { getCookie, setCookie } from 'utils/cookie'

import style from './LangList.module.scss'

export const RateList = (): JSX.Element => {
    const [rateList, setRateList] = useState<ApiRateList['return']>([])
    const [curFiat, setCurFiat] = useState<ApiRateList['return'][0]>({
        lang: '',
        abbr: '',
        name: '',
        symbol: '',
        rate: '',
        usdtRate: ''
    })

    useEffect(() => {
        let rateList = storage.getItem('rateList', 'session', 'common') || {}
        const fiat = getCookie('currency')
        const rate = getCookie('rate')
        const usdtRate = getCookie('usdtRate')
        if (Object.values(rateList)?.length === 0 || fiat === '' || rate === '' || !usdtRate) {
            fetchRateList((res: ApiRateList['return'], usdtRateList: { [key: string]: string }) => {
                setRateList(res)
                const list: { [key: string]: ApiRateList['return'][0] } = {}
                res?.map(item => {
                    list[item?.abbr] = { ...item, usdtRate: usdtRateList[item?.abbr.toLowerCase()] ?? '0' }
                })
                rateList = list
            })
            return
        }
        setRateList(Object.values(rateList))
        setFiat(rateList)
    }, [])

    const setFiat = (rateList: { [key: string]: ApiRateList['return'][0] }): void => {
        let fiat = getCookie('currency')
        if (fiat === '' || !rateList?.[fiat]) {
            fiat = extraLang[locale] ? extraLang[locale] : getLocaleFiat(Object.values(rateList))?.abbr
        }
        const curFiat = rateList?.[fiat]
        setCurFiat(curFiat)
        setCookie('currency', fiat, 1)
        setCookie('rate', curFiat?.rate, 1)
        setCookie('usdtRate', String(curFiat?.usdtRate), 1)
    }

    const getLocaleFiat = (rateList: ApiRateList['return']): ApiRateList['return'][0] => {
        return rateList.filter(item => item.lang === locale)[0]
    }

    const onChangeCurrency = (data: ApiRateList['return'][0]): void => {
        const currency = getCookie('currency')
        if (currency === data?.abbr) return
        setCookie('currency', data?.abbr, 1)
        setCookie('rate', data?.rate, 1)
        setCookie('usdtRate', String(data?.usdtRate), 1)
        window.location.reload()
    }

    // 下拉菜单内容区
    return (
        <div className={style.root}>
            <div className={style.title}>{t('Currency')}</div>
            <div className={style.content}>
                <div className={classNames(style.addr, style.active)}>
                    <span className={style.active}>{`${curFiat?.abbr}-${curFiat?.symbol}`}</span>
                    <Check size={16} />
                </div>
                {rateList?.map(
                    item =>
                        curFiat?.abbr !== item?.abbr && (
                            <div
                                className={classNames(style.addr, curFiat?.abbr === item?.abbr ? style.active : '')}
                                key={`${item?.abbr}${item.lang}`}
                                onClick={() => onChangeCurrency(item)}
                            >
                                <span>{`${item?.abbr} - ${item?.symbol}`}</span>
                                {curFiat?.abbr === item?.abbr && <Check size={16} />}
                            </div>
                        )
                )}
            </div>
        </div>
    )
}
