/**
 * zustand 使用文档：https://github.com/pmndrs/zustand
 */
import { ApiCardDeliverInfoType } from 'api/card/ApiCardDeliverInfo'
import { ApiCardInfoNewType } from 'api/card/ApiCardInfoNew'
import { ApiCardList, ApiCardListInfoType } from 'api/card/ApiCardList'
import { ApiCardTradeListItemType } from 'api/card/ApiCardTradeListNew'
import { ApiRechargeCurrencyListItemType } from 'api/card/ApiRechargeCurrencyList'
import { locale } from 'config'
import { checkLogin } from 'utils'
import { isApp } from 'utils/appUtil'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

export type CardStatusType = 'apply' | 'cardFee' | 'deliver' | 'activate' | 'detail' | 'verify'
export interface workBeachState {
    checkForm: boolean
    setCheckForm: (flag: boolean) => void
    applyId: number
    cardStatus: CardStatusType
    upgradeStatus: number // 卡升级状态，0 未升级，1 已缴费，2 已升级
    setCardStatus: (status: CardStatusType) => void
    needRefresh: boolean
    setNeedRefresh: (flag: boolean) => void
    activeCard: ApiCardListInfoType
    setActiveCard: (cardInfo: ApiCardListInfoType) => void
    cardList: ApiCardListInfoType[] | []
    // 申请时间，拒绝时间，被放到cardlist接口里了
    applyTime: number
    reviewTime: number
    setCardList: (cardList: ApiCardListInfoType[]) => void
    queryCardList: () => void // 多数情况下通过重新查询cardlist来更新状态
    setApplyId: (applyId: number) => void
    setUpgradeStatus: (status: number) => void
    cardFee: string
    setCardFee: (cardFee: string) => void
    czUrl: string
    setCzUrl: (czUrl: string) => void
    isApp: boolean
    isNewCard: boolean
    basePath: string
    setBasePath: (basePath: string) => void
    deliverInfo: ApiCardDeliverInfoType['return']
    setDeliverInfo: (deliverInfo: ApiCardDeliverInfoType['return']) => void
    cardInfo: ApiCardInfoNewType['return']
    setCardInfo: (cardInfo: ApiCardInfoNewType['return']) => void
    // 消费列表。缓存先放这
    tradeList: ApiCardTradeListItemType[]
    setTradeList: (tradeList: ApiCardTradeListItemType[]) => void
    // 项目币列表
    coinList: ApiRechargeCurrencyListItemType[]
    setCoinList: (coinList: ApiRechargeCurrencyListItemType[]) => void
}
const path = window.location.pathname?.split('/')[3]?.toLocaleLowerCase()

const cardStore = create<workBeachState, any>(
    devtools(
        set => ({
            checkForm: false,
            applyId: 0,
            cardStatus: 'apply',
            upgradeStatus: 0,
            needRefresh: false,
            activeCard: {
                applyId: 0,
                dCardId: '',
                cardType: 0,
                status: 0,
                applyStatus: 0,
                remark: '',
                cardFee: 0,
                quota: '0',
                quotaCurrency: '0',
                cardFacade: ''
            },
            cardList: [],
            applyTime: 0,
            reviewTime: 0,
            cardFee: '100',
            czUrl: '',
            isApp: isApp(),
            isNewCard: path === 'newcard',
            basePath: path === 'newcard' ? `/${locale}/newCard/` : `/${locale}/card/`,
            deliverInfo: {
                country: '',
                city: '',
                line: '',
                zip: '',
                shipping: [],
                phoneNumber: '',
                name: ''
            },
            cardInfo: {
                dCardId: '',
                pan: '',
                status: 0,
                quota: 0
            },
            tradeList: [],
            coinList: [],
            setCheckForm: (flag: boolean) => {
                set({
                    checkForm: flag
                })
            },
            setCardStatus: (status: CardStatusType) => {
                set({
                    cardStatus: status
                })
            },
            setNeedRefresh: (flag: boolean) => {
                set({
                    needRefresh: flag
                })
            },
            setActiveCard: (cardInfo: ApiCardListInfoType) => {
                set({
                    activeCard: cardInfo
                })
            },
            setCardList: (cardList: ApiCardListInfoType[]) => {
                set({
                    cardList
                })
            },
            queryCardList: () => {
                if (checkLogin()) {
                    ApiCardList().then(resp => {
                        set({
                            applyTime: resp?.applyTime,
                            reviewTime: resp?.reviewTime,
                            cardList: resp?.cardList
                        })
                    })
                }
            },
            setApplyId: (applyId: number) => {
                set({
                    applyId
                })
            },
            setUpgradeStatus: (status: number) => {
                set({
                    upgradeStatus: status
                })
            },
            setCardFee: (cardFee: string) => {
                set({
                    cardFee
                })
            },
            setCzUrl: (czUrl: string) => {
                set({
                    czUrl
                })
            },
            setBasePath: (basePath: string) => {
                set({
                    basePath
                })
            },
            setDeliverInfo: (deliverInfo: ApiCardDeliverInfoType['return']) => {
                set({
                    deliverInfo
                })
            },
            setCardInfo: (cardInfo: ApiCardInfoNewType['return']) => {
                set({
                    cardInfo
                })
            },
            setTradeList: (tradeList: ApiCardTradeListItemType[]) => {
                set({
                    tradeList
                })
            },
            setCoinList: (coinList: ApiRechargeCurrencyListItemType[]) => {
                set({
                    coinList
                })
            }
        }),
        { anonymousActionType: 'cardStore', enabled: import.meta.env.DEV }
    )
)

export default cardStore
