import create from 'zustand'
// import { post } from 'utils'
// import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface IState {
    sId: string
    idToken: string
    update: (sId: string, idToken: string) => any
}

const useGoogleLoginParams = create<IState, any>(
    devtools(
        set => {
            return {
                sId: '',
                idToken: '',
                update(sId: string, idToken: string) {
                    set(() => ({ idToken, sId }))
                }
            }
        },
        { name: 'useGoogleLoginParams', enabled: import.meta.env.DEV }
    )
)

export default useGoogleLoginParams
