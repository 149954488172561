import { ApiFuturesTransferCurrencyType, IApiFuturesAssetType, IApiMarginAssestType, IApiOtcAssestType, IApiSpotAssestType, IApiTotalListType, IApiWealthAssestType, timeType } from 'api/assets'
import create from 'zustand'

type Store = {
    hidden: boolean // 左上角显示隐藏
    spotAsset: IApiSpotAssestType['return']
    marginAsset: IApiMarginAssestType['return']
    wealthAsset: IApiWealthAssestType['return']
    otcAsset: IApiOtcAssestType['return']
    futuresAsset: IApiFuturesAssetType['return']
    totalList: IApiTotalListType['return']
    totalTimeType: timeType // 左上角时间范围
    swapIdList: ApiFuturesTransferCurrencyType['return']
    topTransferModalVisible: {
        // 右上角Transfer弹窗
        type: 'spot' | 'margin' | 'wealth' | 'otc' | 'futures'
        visible: boolean
    }
    totalCurrency: string // 总资产显示单位
    hideSmall: boolean // 是否隐藏<1usd资产

    setHidden: (hidden: boolean) => void
    setSpotAsset: (list: IApiSpotAssestType['return']) => void
    setMarginAsset: (list: IApiMarginAssestType['return']) => void
    setWealthAsset: (list: IApiWealthAssestType['return']) => void
    setOtcAsset: (list: IApiOtcAssestType['return']) => void
    setFuturesAsset: (list: IApiFuturesAssetType['return']) => void
    setTotalList: (list: IApiTotalListType['return']) => void
    setTotalTimeType: (timeType: timeType) => void
    setSwapIdList: (list: ApiFuturesTransferCurrencyType['return']) => void
    setTopTransferModalVisible: (type: 'spot' | 'margin' | 'wealth' | 'otc' | 'futures', visible: boolean) => void
    setTotalCurrency: (currency: string) => void
    setHideSmall: (hideZreo: boolean) => void
}
export const useMyBalanceStore = create<Store>(set => ({
    hidden: false,
    spotAsset: {} as IApiSpotAssestType['return'],
    marginAsset: {} as IApiMarginAssestType['return'],
    wealthAsset: {} as IApiWealthAssestType['return'],
    otcAsset: {} as IApiOtcAssestType['return'],
    futuresAsset: {} as IApiFuturesAssetType['return'],
    totalList: {} as IApiTotalListType['return'],
    totalTimeType: timeType.OneWeek,
    swapIdList: [] as ApiFuturesTransferCurrencyType['return'],
    topTransferModalVisible: {
        type: 'margin',
        visible: false
    },
    totalCurrency: 'USDT',
    hideSmall: true,

    setHidden: hidden => set({ hidden }),
    setSpotAsset: list => set({ spotAsset: list }),
    setMarginAsset: list => set({ marginAsset: list }),
    setWealthAsset: list => set({ wealthAsset: list }),
    setOtcAsset: list => set({ otcAsset: list }),
    setFuturesAsset: list => set({ futuresAsset: list }),
    setTotalList: list => set({ totalList: list }),
    setTotalTimeType: timeType => set({ totalTimeType: timeType }),
    setSwapIdList: list => set({ swapIdList: list }),
    setTopTransferModalVisible: (type, visible) => {
        set({ topTransferModalVisible: { type, visible } })
    },
    setTotalCurrency: currency => set({ totalCurrency: currency }),
    setHideSmall: hideSmall => set({ hideSmall })
}))
