import { Space } from '@douyinfe/semi-ui'
import { useInViewport } from 'ahooks'
import { useEffect, useRef, useState } from 'react'

import style from './WhyChoose.module.scss'

const WhyChoose = (): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null)
    const [isInViewport] = useInViewport(ref)
    const [hasEnteredViewport, setHasEnteredViewport] = useState(false)

    const DATA = [
        {
            title: t('paro:Ultra-fast trading engine'),
            desc: t('paro:Processes millions of orders per second, ensuring you never miss a market opportunity.')
        },
        {
            title: t('paro:Top-tier security protection'),
            desc: t('paro:With multi-layer encryption and cold storage systems, your assets are fully secured.')
        },
        {
            title: t('paro:Diverse Trading Pairs'),
            desc: t('paro:Offering hundreds of popular digital assets, including BTC, ETH, stablecoins, and more, to fulfill all your trading requirements.')
        },
        {
            title: t('paro:7/24h Customer Support'),
            desc: t('paro:Wherever you are, our professional team is always ready to assist you.')
        }
    ]

    useEffect(() => {
        if (isInViewport && !hasEnteredViewport) {
            setHasEnteredViewport(true)
        }
    }, [isInViewport, hasEnteredViewport])

    return (
        <div
            className={classNames({ [style.root]: true, [style.enter]: hasEnteredViewport })}
            ref={ref}
        >
            <h1>{t('paro:Why Choose us')}</h1>
            <Space className={style.list}>
                {DATA.map((item, index) => (
                    <Space
                        key={index}
                        vertical
                        className={style.item}
                    >
                        <div className={style.icon} />
                        <h3>{item.title}</h3>
                        <h4>{item.desc}</h4>
                    </Space>
                ))}
            </Space>
        </div>
    )
}

export default WhyChoose
