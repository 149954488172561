import { Badge } from '@douyinfe/semi-ui'
import { ApiGetMessageUnreadCount } from 'api/notification'
import { DropDownContainer } from 'components/semi-ext/DropDownContainer/DropDownContainer'
import { useEffect, useState } from 'react'
import { Bell } from 'react-dui-icons'
import { checkLogin } from 'utils'

import { MessageDropDown } from '../MessageDropDown/MessageDropDown'
import style from './Notification.module.scss'

export const Notification = (): JSX.Element => {
    const [count, setCount] = useState(0)
    /**
     * 获取用户未读消息数
     */
    const fetchMsgCount = async (): Promise<void> => {
        const { count } = await ApiGetMessageUnreadCount()
        setCount(count)
    }
    useEffect(() => {
        if (checkLogin()) {
            fetchMsgCount()
        }
    }, [])
    return (
        <div
            className={style.root}
            onClick={() => window?.gtag('event', 'home_notify')}
        >
            <DropDownContainer
                content={<MessageDropDown fetchMsgCount={fetchMsgCount} />}
                trigger="hover"
                zIndex={1080}
            >
                {count > 0 ? (
                    <Badge
                        count={count}
                        overflowCount={99}
                        type="danger"
                    >
                        <Bell size={24} />
                    </Badge>
                ) : (
                    <Bell size={24} />
                )}
            </DropDownContainer>
        </div>
    )
}
