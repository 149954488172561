import { Area, Chart, Line } from 'bizcharts'
import { locale } from 'config'
import { useTheme } from 'hooks'
import { Suspense } from 'react'
import { isChinese } from 'utils'

import { ChartTemplate } from './ChartTemplate'
export interface MarketChartFigureChartProps {
    status: 0 | 1 // 跌 涨
    chartData?: (string | number)[] // 分时图数据
    loading?: boolean
    height?: number
    width?: number
    lineSize?: number
}

const FigureChart = ({ status, chartData = [], loading, height = 41, width = 100, lineSize = 1 }: MarketChartFigureChartProps): JSX.Element => {
    const [theme] = useTheme()

    const isChina = isChinese(locale)
    const actionArrs = isChina ? ['success', 'danger'] : ['danger', 'success']
    const action = actionArrs[status] as 'success' | 'danger'

    const chartThemHooks = {
        light: {
            danger: {
                bg: 'l (90) 0:rgba(235,73,51,1) 1:rgba(255,255,255,0.08)',
                line: '#EB4933'
            },
            success: {
                bg: 'l (90) 0:rgba(33, 204, 163, 1) 1:rgba(255,255,255,0.08)',
                line: '#21CCA3'
            }
        },
        dark: {
            danger: {
                bg: 'l (90) 0:rgba(235,73,51,0.8) 1:rgba(255,255,255,0)',
                line: '#EB4933'
            },
            success: {
                bg: 'l (90) 0:rgba(33, 204, 163, 0.8) 1:rgba(255,255,255,0)',
                line: '#21CCA3'
            }
        }
    }

    const data = [...chartData].reverse()?.map((value, time) => ({ time, value: +value }))
    const cols = { time: { type: 'linear', tickInterval: chartData.length } }
    return loading ? (
        <ChartTemplate
            height={height}
            width={width}
        />
    ) : (
        <Suspense fallback={<ChartTemplate />}>
            <Chart
                height={height}
                pure
                width={width}
                data={data}
                scale={cols}
                padding={0}
                forceFit
            >
                <Area
                    position="time*value"
                    color={chartThemHooks[theme][action].bg}
                />
                <Line
                    position="time*value"
                    size={lineSize}
                    color={chartThemHooks[theme][action].line}
                />
            </Chart>
        </Suspense>
    )
}
export default FigureChart
