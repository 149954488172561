import React from 'react'

const Spot = /* #__PURE__ */ (function () {
    const component = function (props: any) {
        const { color, size, ...otherProps } = props
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                className="dui-icon"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color={color}
                {...otherProps}
            >
                <path
                    d="M6.5 3V3C4.9536 3 3.7 4.2536 3.7 5.8V6.37974C3.7 6.42749 3.6443 6.45358 3.60761 6.42301L2.5 5.5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M17.5 21V21C19.0464 21 20.3 19.7464 20.3 18.2V17.6203C20.3 17.5725 20.3557 17.5464 20.3924 17.577L21.5 18.5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <circle
                    cx="14.5"
                    cy="9.5"
                    r="5.75"
                    stroke="currentColor"
                    strokeWidth="1.5"
                />
                <circle
                    cx="9.5"
                    cy="14.5"
                    r="5.75"
                    stroke="currentColor"
                    strokeWidth="1.5"
                />
            </svg>
        )
    }

    component.defaultProps = {
        color: '#272727',
        size: '24'
    }

    return component
})()

export default Spot
