import { Modal } from 'components/semi-ext/Modal'
import { locale } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { Close } from 'react-dui-icons'
import { isPc, storage } from 'utils'

import type { ApiTcListType } from './api/ApiTcList'
import { ApiTcList } from './api/ApiTcList'
import style from './TelegramEntranceModal.module.scss'

const TelegramEntranceModal = () => {
    const [visible, setVisible] = useState(true)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [entrance, setEntrance] = useState<ApiTcListType['return']>()
    const afterRegister = !!storage?.getItem('after-register')?.telegramEntrance

    const getImageUrl = (name: string) => {
        return new URL(`./image/${name}.png`, import.meta.url).href
    }

    const fetchTcList = async () => {
        const data = await ApiTcList()
        setEntrance(data)
    }

    const handleClose = () => {
        setVisible(false)
        storage.setItem('after-register', { telegramEntrance: false })
    }

    const handleImageLoad = () => {
        setImageLoaded(true)
    }

    const modalVisible = useMemo(() => {
        return visible && !!entrance && afterRegister
    }, [visible, entrance, afterRegister])

    const imageUrl = useMemo(() => {
        return `modal${isPc() ? '-pc-' : '-'}${['tr-tk', 'ko-kr'].includes(locale) ? locale : 'en-ww'}`
    }, [locale, isPc()])

    useEffect(() => {
        fetchTcList()
    }, [])

    return (
        <div className={style.root}>
            <Modal
                header={null}
                footer={null}
                visible={modalVisible}
                maskClosable={false}
                className={style.telegramEntranceModal}
                centered
            >
                {imageLoaded && (
                    <>
                        <img src={getImageUrl(imageUrl)} />
                        <section className={style.btnGroup}>
                            <a
                                href={`https://${entrance?.areaCommunity.communityUrl?.replace(/https?:\/\//, '')}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {entrance?.areaCommunity.communityName}
                            </a>
                            <a
                                href={`https://${entrance?.functionCommunity.communityUrl?.replace(/https?:\/\//, '')}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {entrance?.functionCommunity.communityName}
                            </a>
                        </section>
                        {modalVisible && !isPc() && (
                            <div onClick={handleClose}>
                                <Close size={16} />
                            </div>
                        )}
                    </>
                )}
                {/* 监听图片加载 */}
                <img
                    src={getImageUrl(imageUrl)}
                    onLoad={handleImageLoad}
                    style={{ display: 'none' }}
                />
            </Modal>
            {modalVisible && isPc() && (
                <div
                    className={style.closeBtn}
                    onClick={handleClose}
                >
                    <Close size={32} />
                </div>
            )}
        </div>
    )
}

export default TelegramEntranceModal
