// 触达弹窗
import { post } from 'utils'

export const enum PopupLocation{
    AppIndex = 1,
    AppTrade = 2,
    AppOtc = 3,
    AppAsset = 4,
    AppMine = 5,
    PcIndex = 6,
    PcMine = 7,
}

export const enum PopupType{
    SingleText = 1,
    SingleImage,
    DoubleText,
    DoubleImage,
    NoButton
}

export const enum PopupShowType{
    Once = 1,
    EveryDay
}

export interface PopupItem {
    touchPopupId: number
    isLogin: number
    clientType: number
    location: PopupLocation
    popupType: PopupType
    buttonLeftUrl: string
    buttonRightUrl: string
    imageUrl: string;
    showType: PopupShowType
    image: string
    title: string
    abstract: string
    buttonLeft: string
    buttonRight: string
}

export interface ApiPopupFetchType {
    api: 'popup/fetch'
    method: 'post'
    return: {
        list: PopupItem[]
    }
}

export const ApiPopupFetch = async () => {
    return await post<ApiPopupFetchType>('popup/fetch')
}
