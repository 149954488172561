import { get } from 'utils'

export interface ApiIp {
    api: 'ip'
    method: 'get'
    return: {
        ip: string
        country: string
    }
}

export interface ApiIpInfo {
    api: 'user/ip_info'
    method: 'get'
    return: {
        city: string
        code: string
        ip: string
        isPop: boolean
    }
}

export const ApiGetIp = async () => {
    return await get<ApiIp>('ip')
}

export const ApiGetIpInfo = async () => {
    return await get<ApiIpInfo>('user/ip_info')
}
