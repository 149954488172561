import { Spin } from '@douyinfe/semi-ui'

import style from './Loading.module.scss'

export const Loading = (props: { block?: boolean }): JSX.Element => {
    return (
        <section className={classNames(style.loading, props?.block && style.block)}>
            <i className={style.bar1}></i>
            <i className={style.bar2}></i>
            <i className={style.bar3}></i>
            <i className={style.bar4}></i>
            <i className={style.bar5}></i>
        </section>
    )
}

export const SmallLoading = (): JSX.Element => {
    return (
        <section className={style.smallLoading}>
            <Spin />
        </section>
    )
}
