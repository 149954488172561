import { themeKey, themeKey2 } from 'config/theme.config'
import { useEffect } from 'react'
import themeStore from 'store/theme'
import { setCookie } from 'utils/cookie'

/**
 * 切换明暗主题的
 */
export const useTheme = (): [Theme, (them?: Theme) => void] => {
    const [theme, setThemeData] = themeStore(state => [state.theme, state.setThemeData])

    // 设置主题
    const setTheme: (them?: Theme) => void = them => {
        const themeValue = them || (theme === 'light' ? 'dark' : 'light')

        if (themeValue !== theme) {
            setThemeData(themeValue)
        }
    }

    useEffect(() => {
        if (document.body.classList.contains(theme)) {
            return
        }

        setCookie(themeKey, theme, 365)
        setCookie(themeKey2, theme === 'light' ? 'white' : 'black', 365)

        /** 主站 */
        document.body.setAttribute('data-theme', `parobit_${theme === 'light' ? 'Light' : 'Dark'}`)
        /** semi */
        document.body.setAttribute('theme-mode', theme)

        document.body.setAttribute('class', theme === 'light' ? 'white' : 'black')
    }, [theme])

    return [theme, setTheme]
}
