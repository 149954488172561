import { post } from 'utils'

export interface InternalListWithoutVerifyItem {
    depositId: string;
    currencyId: string;
    num: string;
    addTime: string;
    currencyMark: string;
    verifyEndTime: number;
}

export interface IApiDepositInternalListWithoutVerify {
    api: 'deposit/internal_list/without_verify'
    method: 'post'
    return: {
        list: InternalListWithoutVerifyItem[]
    }
}

export const ApiDepositInternalListWithoutVerify = async () => {
    return post<IApiDepositInternalListWithoutVerify>('deposit/internal_list/without_verify')
}
