// eslint-disable-next-line simple-import-sort/imports
import { locale } from 'config/locale.config'
import i18next from 'i18next'
import { storage } from 'utils'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

/** crowdin CDN */
import OtaClient from '@crowdin/ota-client'

import LocalStorageBackend from './i18next-localstorage-backend'
// import { langVersion } from './langVersion'
/**
 * 文档：https://react.i18next.com/latest/i18next-instance
 */
// import { apiPrefix } from 'config/domain.config'
import { initNs } from './ns.json'

const client = new OtaClient(import.meta.env.VITE_DISTRIBYTION_HASH)

const initialize = () => {
    const storageName = `crowdin_${import.meta.env.VITE_CROWDIN_PATH}_version`
    const defaultVersion = storage.getItem(storageName) || '0'
    client.getManifestTimestamp().then((timeStamp: number | string) => {
        storage.setItem(storageName, String(timeStamp))
    })
    return i18next
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            lng: locale,
            load: 'languageOnly', // 'currentOnly',
            lowerCaseLng: true,
            fallbackLng: 'en',
            ns: initNs,
            defaultNS: initNs,
            fallbackNS: initNs,
            debug: false, // import.meta.env.DEV
            react: {
                useSuspense: true
            },
            nsSeparator: ':',
            keySeparator: ':',
            detection: {
                order: ['path', 'cookie', 'localStorage', 'navigator'],
                caches: ['cookie'],
                lookupQuerystring: 'lang',
                lookupLocalStorage: 'lang',
                lookupCookie: 'lang',
                lookupFromPathIndex: 0
            },
            backend: {
                backends: [
                    LocalStorageBackend, // primary backend
                    HttpApi, // fallback backend
                    HttpApi // fallback backend
                ],
                backendOptions: [
                    {
                        // expiration
                        expirationTime: 7 * 24 * 60 * 60 * 1000,
                        // 分发的清单时间戳
                        defaultVersion
                    },
                    {
                        /* options for secondary backend */
                        // loadPath: `/main_static/${import.meta.env.VITE_CROWDIN_PATH}/{{lng}}/{{ns}}.json`
                        loadPath: `https://distributions.crowdin.net/${import.meta.env.VITE_DISTRIBYTION_HASH}/content/public/main_static/${import.meta.env.VITE_CROWDIN_PATH}/{{lng}}/{{ns}}.json?timestamp=${new Date().getTime()}`
                    },
                    // {
                    //     /* options for fallback backend */
                    //     loadPath: `/main_static/main/{{lng}}/{{ns}}.json?version=${new Date().getTime()}`
                    // },
                    {
                        /* options for fallback backend */
                        loadPath: `/main_static/main_origin/{{lng}}/{{ns}}.json?version=${new Date().getTime()}`
                    }
                ]
            }
        })
}

initialize()

export default i18next
export const t = i18next.t.bind(i18next)
