import { ApiGoogleLoginCode, ApiGoogleLoginToken } from 'api/thirdLogin'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import { isTestEnv, locale } from 'config'
import { useWindowSize } from 'hooks'
import useBackUrl from 'hooks/useBackUrl'
import { useEffect, useRef, useState } from 'react'
import useGoogleLoginParams from 'store/useGoogleLoginParams'
import { storage } from 'utils'
import { storeToken } from 'utils/token'

import style from './GoogleLoginBtn.module.scss'

// google一键登录，https://developers.google.com/identity/gsi/web/guides/features?hl=zh-cn
const GoogleLoginButton = (): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false)
    const googleLoginParams = useGoogleLoginParams(state => state)
    const { isMobile } = useWindowSize()

    const { aop } = useBackUrl(isMobile ? `/${locale}/` : `/${locale}/user`)
    const returnIdToken = async (user: any) => {
        // @ts-ignore
        console.log(user)
        let googleSid = ''
        let isReg = true
        let thirdEmail = ''
        await ApiGoogleLoginCode({ idToken: user.credential }).then(res => {
            console.log(res.sId)
            // setIsRegUser(res.isReguser)
            googleSid = res.sId
            thirdEmail = res.email ?? ''
            isReg = res.action === 'multi_bound' || res.action === 'register'
        })

        if (!isReg) {
            ApiGoogleLoginToken({ idToken: user.credential, sId: googleSid }).then(res => {
                if (res.accessToken) {
                    storage.setItem('recent-used-third-party', 'google')
                    storeToken(res)
                    Toast.success({ content: t('Web_Login_SuccessToast'), showClose: false })
                    aop()
                }
            })
        } else {
            console.log?.(googleSid, user.credential, thirdEmail)
            googleLoginParams.update(googleSid, user.credential)
        }
    }
    useEffect(() => {
        const scriptTag = document.createElement('script')
        scriptTag.src = 'https://accounts.google.com/gsi/client'
        scriptTag.async = true
        scriptTag.onload = () => {
            setScriptLoadedSuccessfully(true)
        }
        scriptTag.onerror = () => {
            setScriptLoadedSuccessfully(false)
        }
        containerRef.current && containerRef.current.appendChild(scriptTag)
        return () => {
            if (containerRef.current) {
                containerRef.current.innerHTML = ''
            }
            if ((window as any).google) {
                delete (window as any).google
            }
        }
    }, [])
    useEffect(() => {
        if (!scriptLoadedSuccessfully) {
            return
        }
        window.google?.accounts.id.initialize({
            client_id: isTestEnv ? '1073194479146-kfkrem3r6ndq1l5k5gidfgmnccbje1ht.apps.googleusercontent.com' : '67294459161-hfkoff2cp07dmdl6iq5uq596qlu72idj.apps.googleusercontent.com',
            callback: returnIdToken,
            // prompt_parent_id: 'googleLogindiv',
            ux_mode: 'popup',
            ui_mode: 'card',
            itp_support: false,
            // auto_select: true,
            // allowed_parent_origin: ['https://dev.digifinextest.com:1234', 'https://www.digifinextest.com'],
            scope: `openid profile email ${''}`
        })
        setTimeout(() => {
            window.google.accounts.id.prompt()
        }, 2000)
    }, [scriptLoadedSuccessfully])
    return (
        <div
            className={style.googleLogin}
            ref={containerRef}
        ></div>
    )
}
export default GoogleLoginButton
