// 改版--首页币种分时图
import { get } from 'utils'

export interface ApiContractHistoryKlineType {
    api: 'contract/historyKline'
    method: 'get'
    params: {
        instrument_id: string
        kline_type: string
        from: string
        to: string
    }
    return: {
        t: number[] // time 时间
        v: string[] // volume 成交量
        h: string[] // high 最高价
        o: string[] // open 开盘价
        l: string[] // low 最低价
        c: string[] // close 收盘价
    }
}

export const ApiContractHistoryKline = async (params: ApiContractHistoryKlineType['params']) => {
    return await get<ApiContractHistoryKlineType>('contract/historyKline', { ...params })
}
