import { ISecondaryVerification } from 'views/Auth/Login/types/ISecondaryVerification'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface IState {
    secondCaptchaData?: ISecondaryVerification
    update: (data: ISecondaryVerification) => any
}

const useSecondaryVerification = create<IState, any>(
    devtools(
        set => {
            return {
                secondCaptchaData: undefined,
                update: async data => {
                    set({ secondCaptchaData: data })
                }
            }
        },
        { name: 'useSecondaryVerification', enabled: import.meta.env.DEV }
    )
)

export default useSecondaryVerification
