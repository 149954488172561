import { post } from 'utils'

export interface ApiUserInfo {
    api: 'user/info'
    method: 'post'
    return: {
        /** 用户展示uid */
        showUid: string
        /** 用户手机 */
        phone: string
        /** 用户区号 不存在则为空字符串 */
        countryCode: string
        /** 用户邮箱 */
        email: string
        userName: string
        /** 性别 0=未知，1=男，2=女 */
        gender: 0 | 1 | 2
        /** 实名认证状态 0=未认证，1=等待审核，2=通过，3=驳回认证 */
        userProve: 0 | 1 | 2 | 3
        /** 当 user_prove = 3 时，这里有驳回原因 */
        userProveInfo: string
        /** 谷歌验证状态 0=关闭认证，1=开启验证，2=申请关闭中 */
        gaOpen: 0 | 1 | 2
        /** 登陆开启谷歌验证状态 0=关闭，1=开启 */
        gaLogin: '0' | '1'
        /** 证件类型 1=国内，2=国外 */
        cardtype: 1 | 2
        /** 注册类型 1=手机，2=邮箱,3=用户名，4=第三方登录 */
        regType: 1 | 2 | 3 | 4
        name: string
        nickname: string
        lastLoginTime: number
        lastLoginIp: string
        /** 商家类型 0=普通用户 1=初级认证商家 2=高级认证商家 */
        businessType: 0 | 1 | 2
        withdrawWhitelistStatus: number
        /** kyc等级 0未认证 1=等级1 2=等级2 3=等级3 */
        kycLevel: number
        /** 姓 */
        firstName: string
        /** 证件号码 */
        idDocNum: string
        /** kyb流程状态 0=待审核 1=审核通过 2=审核驳回 */
        kybStatus: number
        /** 是否在kyb流程 0=不在 1=在  */
        isInKyb: number
        /** 是否可以领取体验金 0=可领取 1= 不可领 */
        hyExperienceStatus: 0 | 1
        /** 代理商平台用户类型 0=普通用户 1=代理商 2=经纪人 */
        agentPlatformUserType: 0 | 1 | 2
        /** 账户类型 1=主账户 2=子账户 */
        accountType: 1 | 2
        /** 是否可以设置密码 true=可以 false=不可以 */
        isSetPwd: boolean
        /** 邀请人show_uid，为空时表示无邀请人 */
        invitor: string
        vipLevel: number
        /** 创建子账户数量配置信息 account_type=1才展示改字段 */
        subaccountInfo: Array<{
            /** 子账户数量 */
            subaccountNum: number
            /** 可创建子账户数量 */
            availableSubaccountNum: number
        }>
        /** 防钓鱼码 */
        phishingCode: string
        rejectPlatType: number
        withdrawWhitelistSecurityStatus: number
        userProveNationality?: string
    }
}
export const ApiGetUserInfo = async () => {
    return await post<ApiUserInfo>('user/info')
}
