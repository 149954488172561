import { Button } from '@douyinfe/semi-ui'
import Refresh from 'components/Icons/icons/Refresh'
import { QRCodeWithLogo } from 'components/QRCodeWithLogo/QRCodeWithLogo'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import { downloadLink } from 'config'
import useBackUrl from 'hooks/useBackUrl'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { sprintf } from 'sprintf-js'
import { post } from 'utils'
import { storeToken } from 'utils/token'
import { api } from 'views/Auth/Register/api/api'

import styles from './LoginQR.module.scss'

// 旧文件复制而来
const LoginQr = (props: { backUrl?: string }): JSX.Element => {
    const [code, setCode] = useState('')
    const { back } = useBackUrl(props?.backUrl || '')
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState<-1 | 0 | 1 | 2 | 3>(-1)
    const expireRef = useRef(0)
    const intervalRef = useRef(0)

    const tipText = useMemo(() => {
        if (status === 3) {
            return t('Refresh QR code') /** 二维码已过期 */
        } else if (status === 1 || status === 2) {
            return t('The QR code has expired') /** 在应用程序上确认 */
        } else {
            return t('The QR code has expired') /** 使用二维码登录 */
        }
    }, [status])

    const downSpan = useMemo(() => {
        const span = document.createElement('span')
        span.textContent = 'Parobit mobile app'
        span.classList.add(styles.loginQrTipDownloadApp)
        span.setAttribute('onclick', `(function (){window.open('${downloadLink}')})()`)
        return span
    }, [styles.loginQrTipDownloadApp])

    const resetIntervalRef = () => {
        clearInterval(intervalRef.current)
        intervalRef.current = 0
    }

    const checkStatus = (qrcode: string) => {
        if (intervalRef.current) {
            resetIntervalRef()
        }
        intervalRef.current = window.setInterval(() => {
            // @ts-ignore
            post(api.scanLoginQrcodeCheck, {
                qrcode,
                t: Date.now()
            }).then((res: any) => {
                setStatus(res.status)
            })
        }, 5 * 1000)
    }

    const generateCode = () => {
        setLoading(true)
        // @ts-ignore
        post(api.scanLoginQrcodeGenerate, {
            t: Date.now()
        })
            .then((res: any) => {
                expireRef.current = Date.now() + 90 * 1000
                setCode(res.qrcode)
                setStatus(0)
                checkStatus(res.qrcode)
            })
            .catch(e => {
                console.error(e)
                setStatus(-1)
                setTimeout(() => {
                    setStatus(3)
                }, 3 * 1000)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useLayoutEffect(() => {
        if (Date.now() >= expireRef.current) {
            generateCode()
        } else {
            checkStatus(code)
        }
        return () => {
            resetIntervalRef()
        }
    }, [])
    useLayoutEffect(() => {
        if (status === 1) {
            // @ts-ignore
            post(api.scanLoginToken, {
                qrcode: code
            }).then((res: any) => {
                storeToken(res)
                Toast.success({ content: t('Log in successfully'), showClose: false })
                back()
            })
            expireRef.current = 0
            resetIntervalRef()
        } else if (status === 3) {
            expireRef.current = 0
            resetIntervalRef()
        }
    }, [status, code])
    return (
        <div className={styles.loginQr}>
            <div className={styles.qrBox}>
                <QRCodeWithLogo
                    size={220}
                    className={styles.qrcode}
                    value={code}
                />
                {status === 0 && !loading ? null : (
                    <div className={styles.refreshBox}>
                        {status === 3 && (
                            <Button
                                theme="solid"
                                type={'primary'}
                                onClick={() => generateCode()}
                                icon={
                                    <Refresh
                                        size={24}
                                        color={'#FFFFFF'}
                                    />
                                }
                            ></Button>
                        )}
                        <div className={styles.refreshBoxTip}>{tipText}</div>
                    </div>
                )}
            </div>
            <div className={styles.loginQrTitle}>{t('Log in with QR code')}</div>
            <div
                className={styles.loginQrTip}
                dangerouslySetInnerHTML={{ __html: sprintf(t('Scan this code with %s'), downSpan.outerHTML) }}
            ></div>
        </div>
    )
}

export default LoginQr
