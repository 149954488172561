import { AutoComplete, Dropdown, Input, useFormApi } from '@douyinfe/semi-ui'
import { ValidateStatus } from '@douyinfe/semi-ui/lib/es/_base/baseComponent'
import { useCountDown } from 'ahooks'
import { ApiThirdCaptcha } from 'api/thirdLogin'
import classNames from 'classnames'
import Captcha, { CaptchaMethods } from 'components/Captcha/Captcha'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import { CSSProperties, FC, MouseEvent, ReactNode, Ref, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { CheckCircle, CloseCircleOutlinedFill, Delete, NavDownFill, Search, Unradio } from 'react-dui-icons'
import { useLocation } from 'react-router'
import useLoginData from 'store/useLoginData'
import { getRegExp, post, verify } from 'utils'
import { api } from 'views/Auth/Register/api/api'
import { ApiOclrSendCaptchaParams } from 'views/Auth/Register/types/oclrSendCaptcha'

import { EMAILS } from '../../common/const'
import { REGION_PHONE_LIST } from '../../common/phone'
import styles from './FormItem.module.scss'

interface IFormItemProps {
    label?: ReactNode
    defaultVal?: string
    show?: boolean
    showInp?: boolean
    append?: ReactNode
    showLabel?: boolean
    hideErrorTip?: boolean
    type?: 'email' | 'txt' | 'pwd' | 'phone' | 'verification' | 'inviteCode' | 'otp'
    size?: 'small' | 'default' | 'large'
    verificationData?: ApiOclrSendCaptchaParams
    pwdType?: 'register' | 'login'
    inviteStatus?: ValidateStatus
    valiStatus?: ValidateStatus
    account?: string
    style?: CSSProperties
    className?: string
    onChange?: (val: string, valid: boolean) => any
    // 不带区号手机号码更新
    onPurePhoneChange?: (val: string) => any
    allRegion?: boolean // 手机区号是否展示全量，目前信用卡业务用到
    placeholder?: string
    prefix?: ReactNode
    suffix?: ReactNode
    captchaCb?: (id: string, account: string, isNewAccount: boolean) => any
    captchaRef?: Ref<{ captchaClick: () => any; reCount?: () => any }>
    disabled?: boolean
    onClearVerAccount?: () => any
    thirdType?: 'Telegram' | 'Google' | undefined | ''
    thirdPreSetEmail?: string
    withoutOclrcb?: boolean // 不调api.oclrSendCaptcha接口
    readonly?: boolean
    showClear?: boolean
    id?: string
}

const FormItem: FC<IFormItemProps> = props => {
    const size = props.size
    const loginData = useLoginData()
    const location = useLocation()
    const presetAccount = location.state?.presetAccount || ''
    // state
    const [val, setVal] = useState(props.defaultVal || '')
    const [inpStatus, setInpStatus] = useState<'focus' | 'none'>('none')
    // 手机号前缀
    const [phonePrefix, setPhonePrefix] = useState('')
    // 手机号前缀搜索
    const [phonePreKey, setPhonePreKey] = useState('')
    const [emailOption, setEmailOption] = useState<string[]>([])
    const [validateStatus, setValidateStatus] = useState<ValidateStatus>('default')
    const [targetDate, setTargetDate] = useState<number>()
    const [countdown] = useCountDown({
        targetDate
    })
    const formApi = useFormApi()
    useEffect(() => {
        if (props?.defaultVal) {
            setVal(props?.defaultVal ?? '')
        }
    }, [props?.defaultVal])

    // memo
    const FILTER_PHONE_PRE_LIST = useMemo(() => {
        const PHONE_LIST = props?.allRegion ? REGION_PHONE_LIST : loginData.phoneCode.filteredAll
        if (!phonePreKey) {
            return PHONE_LIST
        }
        return PHONE_LIST.filter(item => {
            return item.regionCode.toLowerCase().indexOf(phonePreKey.toLowerCase()) > -1 || item.regionName.toLowerCase().indexOf(phonePreKey.toLowerCase()) > -1 || item.regionMark.toLowerCase().indexOf(phonePreKey.toLowerCase()) > -1
        })
    }, [phonePreKey, loginData.phoneCode.filteredAll])

    const label = useMemo(() => {
        if (props.type) {
            switch (props.type) {
                case 'inviteCode':
                    return t('Invite code(Optional)')
                case 'email':
                    return t('Email')
                case 'pwd':
                    return t('Password')
                case 'phone':
                    return t('Phone')
                case 'verification':
                    return t('Verification Code')
                default:
                    return 'Input'
            }
        } else {
            return props.label
        }
    }, [props.label, props.type, t])

    // ref
    const phoneAfter = useRef<HTMLDivElement | null>(null)
    const pwdAfter = useRef<HTMLDivElement | null>(null)
    const captchaRef = useRef<CaptchaMethods>({
        click() {}
    })

    // func
    const captchaCallback = (id: string) => {
        // @ts-ignore
        const countryCode = props.verificationData?.areaCode ? { countryCode: String(props.verificationData?.areaCode) } : {}
        if (props.thirdType) {
            ApiThirdCaptcha({ captchaId: id, account: props.account ?? '', ...countryCode, thirdType: props.thirdType === 'Google' ? 3 : props.thirdType === 'Telegram' ? 1 : 2 }).then(res => {
                props.captchaCb?.(id, props.account || '', res.checkResult !== 2)
                setTargetDate(Date.now() + 60 * 1000)
            })

            return
        }

        if (props?.withoutOclrcb) {
            // 不调 oclrSendCaptcha， 默认传回 isNewAccount = true
            props.captchaCb?.(id, props.account || '', true)
            setTargetDate(Date.now() + 60 * 1000)
            return
        }
        // @ts-ignore
        post(api.oclrSendCaptcha, {
            ...props.verificationData,
            captchaId: id,
            ...{ account: props.account }
        }).then((res: any) => {
            if (res?.errcode) {
                return
            }
            const isNewUser = /\.\.$/.test(res.existing)
            if (props.verificationData?.oclrScene === 'register') {
                if (!isNewUser) {
                    Toast.warning({ content: t('App_0108_D3'), showClose: false })
                    return
                }
            } else {
                if (isNewUser) {
                    Toast.warning({ content: t('200005', { ns: 'errCode' }), showClose: false })
                    return
                }
            }

            props.captchaCb?.(id, props.account || '', isNewUser)
            console.info('recount')
            // setCountDown(60 * 1000)
            setTargetDate(Date.now() + 60 * 1000)
        })
    }
    const reCountDown = (e?: MouseEvent<HTMLSpanElement>) => {
        e?.stopPropagation()
        setTimeout(() => captchaRef.current.click?.())
    }
    const onSearch = (val: any) => {
        const tmp = String(val || '')
        let newData = EMAILS?.map(domain => {
            if (tmp?.length > 1 && tmp.indexOf('@') > -1) {
                const pre = tmp.split('@')[0]
                return `${pre}${domain}`
            } else {
                return `${tmp}${domain}`
            }
        })
        if (!tmp) {
            newData = []
        }
        setEmailOption(newData)
    }
    const setResultVal = (value: string | number) => {
        value = String(value).trim()
        setVal(value)
    }

    useImperativeHandle(props.captchaRef, () => {
        return {
            captchaClick: () => {
                reCountDown()
            },
            reCount: () => {
                // setCountDown(0)
            }
        }
    })

    // ReactNode
    const verAccountClear = useMemo(() => {
        if (!props.disabled) {
            return null
        }
        return (
            <Delete
                onClick={e => {
                    e.stopPropagation()
                    setResultVal('')
                    props.onClearVerAccount?.()
                }}
                className={styles.deleteVerAccountIcon}
                size={14}
            ></Delete>
        )
    }, [props.disabled, props.onClearVerAccount])
    const pwErrs = useMemo<ReactNode>(() => {
        const arr: ReactNode[] = []
        if (!val) {
            return arr
        }

        function doo(b: boolean) {
            return b ? (
                <CheckCircle
                    size={12}
                    className={classNames(styles.errorTipIconSuccess, styles.errorTipIcon)}
                ></CheckCircle>
            ) : (
                <Unradio
                    size={12}
                    className={classNames(styles.errorTipIconClose, styles.errorTipIcon)}
                ></Unradio>
            )
        }

        // 8-20个字符
        arr.push(
            <div
                className={styles.errorTip}
                key={'password'}
            >
                {doo(val?.length >= 8 && val?.length <= 20)}
                <span className={styles.errorTipTxt}>{t('8-20 Characters')}</span>
            </div>
        )
        // 至少一个数字
        arr.push(
            <div
                className={styles.errorTip}
                key={'number'}
            >
                {doo(/.*[0-9].*/.test(val))}
                <span className={styles.errorTipTxt}>{t('At least 1 number')}</span>
            </div>
        )
        // 至少一个字母
        arr.push(
            <div
                className={styles.errorTip}
                key={'letter'}
            >
                {doo(/.*[A-Za-z].*/.test(val))}
                <span className={styles.errorTipTxt}>{t('At least 1 letter')}</span>
            </div>
        )
        return arr
    }, [val])
    useEffect(() => {
        if (props.type === 'email') {
            console.log(props.thirdPreSetEmail, 'props.thirdPreSetEmail1')
        }

        if (props.thirdPreSetEmail && props.thirdPreSetEmail !== '') {
            setVal(props.thirdPreSetEmail)
        }
    }, [props.thirdPreSetEmail])
    const PhoneDropdown = useMemo(
        () => (
            <Dropdown
                clickToHide={true}
                trigger={props?.disabled ? 'custom' : 'click'}
                position={'bottomLeft'}
                stopPropagation={true}
                getPopupContainer={() => phoneAfter.current || document.body}
                className={styles.phoneDropdown}
                render={
                    <Dropdown.Menu>
                        <Dropdown.Title>
                            <Input
                                value={phonePreKey}
                                onClear={() => setPhonePreKey('')}
                                onInput={e => setPhonePreKey(String(e.currentTarget.value).trim())}
                                onClick={e => e.stopPropagation()}
                                className={styles.phoneFilterInp}
                                showClear={true}
                                prefix={
                                    <Search
                                        className={styles.phoneFilterInpSearch}
                                        size={13}
                                    ></Search>
                                }
                            ></Input>
                        </Dropdown.Title>
                        {FILTER_PHONE_PRE_LIST?.map(item => (
                            <Dropdown.Item
                                onClick={() => setPhonePrefix(String(item.regionCode))}
                                key={`${item.regionCode} ${item.regionMark}`}
                            >
                                <div className={styles.regionPhoneItem}>
                                    <span>{item.regionName}</span>
                                    <span className={styles.regionPhoneItemPhone}>+{item.regionCode}</span>
                                </div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                }
            >
                <span className={styles.phonePrefix}>
                    +{phonePrefix}
                    <NavDownFill
                        size={12}
                        className={styles.phonePrefixIcon}
                    ></NavDownFill>
                </span>
            </Dropdown>
        ),
        [phonePrefix, FILTER_PHONE_PRE_LIST]
    )

    const InviteCodeTip = () => {
        if (props.inviteStatus === 'default' || props.hideErrorTip) {
            return null
        }
        return props.inviteStatus === 'error' && val?.length > 0 ? (
            <div className={classNames(styles.inviteCodeTip, styles.inviteCodeError)}>
                <CloseCircleOutlinedFill
                    size={12}
                    className={classNames(styles.errorTipIconClose, styles.errorTipIcon)}
                ></CloseCircleOutlinedFill>
                {t('Invalid invitation code')}
            </div>
        ) : null
    }

    const formInput = (() => {
        const type = props.type || 'txt'
        switch (type) {
            case 'email':
                return [
                    <AutoComplete
                        disabled={props.disabled}
                        value={val}
                        key={1}
                        size={size}
                        validateStatus={validateStatus}
                        onBlur={() => setInpStatus('none')}
                        onFocus={() => setInpStatus('focus')}
                        className={styles.formInput}
                        placeholder={props.placeholder || t('Email Address')}
                        onClear={() => {
                            setResultVal('')
                        }}
                        onChange={(v: string | number) => setResultVal(String(v))}
                        data={emailOption}
                        onSearch={onSearch}
                        showClear={true}
                        suffix={props.suffix || verAccountClear}
                    ></AutoComplete>,
                    !props.hideErrorTip && validateStatus === 'error' && (
                        <div
                            className={styles.errorTip}
                            key={2}
                        >
                            <CloseCircleOutlinedFill
                                size={12}
                                className={styles.errorTipIconClose}
                            ></CloseCircleOutlinedFill>
                            {t('Incorrect email format')}
                        </div>
                    )
                ]
            case 'otp':
                return (
                    <Input
                        disabled={props.disabled}
                        value={val}
                        prefix={props.prefix}
                        suffix={props.suffix}
                        placeholder={props.placeholder || ''}
                        size={size}
                        showClear={true}
                        onClear={() => setResultVal('')}
                        onInput={e => {
                            const v = e.currentTarget.value
                            if (v?.length > 6) {
                                return
                            }
                            setResultVal(v.replace(/\D/g, ''))
                        }}
                    ></Input>
                )
            case 'txt':
                return (
                    <Input
                        disabled={props.disabled}
                        value={val}
                        prefix={props.prefix}
                        suffix={props.suffix}
                        placeholder={props.placeholder || ''}
                        size={size}
                        showClear={true}
                        onClear={() => setResultVal('')}
                        onInput={e => setResultVal(e.currentTarget.value)}
                    ></Input>
                )
            case 'pwd':
                return [
                    <Dropdown
                        key={1}
                        trigger={'custom'}
                        visible={!props.hideErrorTip && validateStatus === 'error' && inpStatus === 'focus'}
                        position={'bottomLeft'}
                        getPopupContainer={() => pwdAfter.current || document.body}
                        render={<div className={styles.passwordDropdown}>{pwErrs}</div>}
                    >
                        <Input
                            disabled={props.disabled}
                            value={val}
                            size={size}
                            key={1}
                            validateStatus={validateStatus}
                            className={styles.formInput}
                            mode="password"
                            onFocus={() => setInpStatus('focus')}
                            onBlur={() => setInpStatus('none')}
                            placeholder={props.placeholder || t('8-20 Characters')}
                            onClear={() => setResultVal('')}
                            onInput={e => {
                                const v = e.currentTarget.value
                                if (v?.length > 20) {
                                    return
                                }
                                setResultVal(v)
                            }}
                            showClear={true}
                            type={'password'}
                        ></Input>
                    </Dropdown>,
                    <div
                        key={3}
                        className={styles.phoneAfter}
                        ref={pwdAfter}
                    ></div>
                ]
            case 'phone':
                return [
                    <Input
                        disabled={props.disabled}
                        value={val}
                        size={size}
                        key={1}
                        inputMode="numeric"
                        placeholder={props.placeholder || t('Phone number')}
                        onClear={() => setResultVal('')}
                        onInput={e => setResultVal(e.currentTarget.value)}
                        onFocus={() => setInpStatus('focus')}
                        onBlur={() => setInpStatus('none')}
                        validateStatus={props?.valiStatus || validateStatus}
                        onKeyPress={e => e.stopPropagation()}
                        className={classNames(styles.formInput, styles.phoneInp, props.className)}
                        prefix={PhoneDropdown}
                        suffix={props.suffix ? props?.suffix : verAccountClear}
                        readOnly={props.readonly ?? false}
                        showClear={props?.showClear ?? true}
                    ></Input>,
                    !props.hideErrorTip && validateStatus === 'error' && (
                        <div
                            className={styles.errorTip}
                            key={2}
                        >
                            <CloseCircleOutlinedFill
                                size={12}
                                className={styles.errorTipIconClose}
                            ></CloseCircleOutlinedFill>
                            {t('invaild phone number')}
                        </div>
                    ),
                    <div
                        key={3}
                        className={styles.phoneAfter}
                        ref={phoneAfter}
                    ></div>
                ]
            case 'verification':
                return (
                    <Input
                        disabled={props.disabled}
                        value={val}
                        prefix={props.prefix}
                        suffix={
                            countdown === 0 ? (
                                props.account ? (
                                    <span
                                        className={styles.countDownBtn}
                                        onClick={reCountDown}
                                    >
                                        {t('send')}
                                    </span>
                                ) : null
                            ) : (
                                <span className={styles.countNum}>{Math.round(countdown / 1000)} S</span>
                            )
                        }
                        placeholder={props.placeholder || t('Enter the verification code')}
                        size={size}
                        showClear={true}
                        onClear={() => setResultVal('')}
                        onInput={e => {
                            const v = e.currentTarget.value
                            if (v?.length > 6) {
                                return
                            }
                            setResultVal(v.replace(/\D/g, ''))
                        }}
                    ></Input>
                )
            case 'inviteCode':
                return [
                    <Input
                        disabled={props.disabled}
                        key={1}
                        validateStatus={props.inviteStatus}
                        value={val}
                        prefix={props.prefix}
                        placeholder={props.placeholder || t('Invite code')}
                        size={size}
                        showClear={true}
                        onClear={() => {
                            setResultVal('')
                            setValidateStatus('default')
                        }}
                        onInput={e => {
                            if (e.currentTarget.value.trim()?.length > 20) {
                                return
                            }
                            setResultVal(e.currentTarget.value)
                        }}
                    ></Input>,
                    <InviteCodeTip key={2}></InviteCodeTip>
                ]
        }
    })()

    // effect
    useEffect(() => {
        if (props.type === 'phone') {
            loginData.getPhoneCode()
        }
    }, [props.type])

    useEffect(() => {
        if (loginData.phoneCode.all?.length > 0 && phonePrefix?.length === 0 && loginData.phoneCode.recommend?.length > 0) {
            const item = loginData.phoneCode.recommend[0]
            setPhonePrefix(item.regionCode)
        }
    }, [loginData.phoneCode.all, loginData.phoneCode.recommend, phonePrefix])

    useEffect(() => {
        if (presetAccount) {
            if ((verify(presetAccount, 'email') && props.type === 'email') || (verify(presetAccount, 'phone') && props.type === 'phone')) {
                setResultVal(presetAccount)
            }
        }
    }, [])

    // todo put it in effect temporarily
    useEffect(() => {
        if (!props.show) {
            return
        }
        let tValidate = false
        let tValidateStatus: ValidateStatus = 'default'
        let tVal = val
        switch (props.type) {
            case 'txt':
                tValidate = true
                break
            case 'pwd':
                // 兼容旧登录
                if (props.pwdType === 'login') {
                    if (val?.length > 0) {
                        tValidate = true
                        tValidateStatus = 'success'
                    } else {
                        tValidate = false
                        tValidateStatus = 'default'
                    }
                    break
                }
                tValidate = getRegExp('password').test(val)
                if (tVal?.length === 0) {
                    break
                }
                if (val?.length > 0) {
                    if (tValidate) {
                        tValidateStatus = 'success'
                    } else {
                        tValidateStatus = 'error'
                    }
                } else {
                    tValidateStatus = 'default'
                }
                break
            case 'phone':
                tValidate = getRegExp('phone').test(val) && phonePrefix?.length !== 0
                tVal = `${phonePrefix}+${val}`
                if (tVal?.length === 0) {
                    break
                }
                if (val?.length > 0) {
                    if (tValidate) {
                        tValidateStatus = 'success'
                    } else {
                        tValidateStatus = 'error'
                    }
                } else {
                    tValidateStatus = 'default'
                }
                break
            case 'email':
                tValidate = getRegExp('email').test(val)
                if (tVal?.length === 0) {
                    break
                }
                if (val?.length > 0) {
                    if (tValidate) {
                        tValidateStatus = 'success'
                    } else {
                        tValidateStatus = 'error'
                    }
                } else {
                    tValidateStatus = 'default'
                }
                break
            case 'verification':
                tValidate = getRegExp('code').test(val)
                break
            case 'inviteCode':
                tValidateStatus = props.inviteStatus || 'default'
                break
        }
        setValidateStatus(tValidateStatus)
        props.onPurePhoneChange?.(val)
        props?.id && formApi?.setValue(props?.id, tVal)
        props.onChange?.(tVal, tValidate)
    }, [props.type, val])

    return props.show ? (
        <div
            className={classNames(styles.formItem, props.className)}
            style={{ ...props.style }}
        >
            {props.showLabel && <div className={styles.formLabel}>{props.label || label}</div>}
            {props.showInp && formInput}
            {props.append}
            {props.type === 'verification' && (
                <Captcha
                    ref={captchaRef}
                    account={String(props.account)}
                    callback={captchaCallback}
                ></Captcha>
            )}
        </div>
    ) : null
}

FormItem.defaultProps = {
    showLabel: true,
    showInp: true,
    show: true,
    size: 'large',
    prefix: null,
    suffix: null,
    pwdType: 'register'
}

export default FormItem
