/**
 * 这是一个 i18next 缓存层，用于使用localforage作为存储层的浏览器。它将从 IndexedDB、webSQL 或 localStorage 加载和缓存资源
 * 根据 i18next-localstorage-backend 进行的一个改版，增加 IndexedDB、webSQL
 */
import localforage from 'localforage'

interface CacheOptions {
    /** 数据库名称（前缀是使用 localStorage 作为存储驱动） */
    name?: string
    /** 存储语言的 */
    prefix?: string
    /** 数据库的描述，供开发者使用 */
    description?: string
    /** 仅限 WebSQL，数据库大小以字节为单位 */
    size?: number
    /** 过期时间 */
    expirationTime?: number
    /** 数据库模式版本，仅在 IndexedDB 和 WebSQL 中使用 */
    version?: number
    /** 适用于所有语言的版本，可以使用选项 `versions` 覆盖 */
    defaultVersion?: string
    /** 语言版本 */
    versions?: Record<string, number>
    /** 要使用的驱动程序，如果提供了多个，则使用第一个可用的 */
    driver?: string | string[]
}

type ReadCallback = (err: any, data: any) => void

function getDefaults(): CacheOptions {
    return {
        name: 'MULTILINGUAL_DB',
        prefix: 'lang_',
        description: '',
        size: 4980736,
        expirationTime: 7 * 24 * 60 * 60 * 1000,
        version: 1.0,
        defaultVersion: undefined,
        versions: {},
        driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE]
    }
}

class Cache {
    public static type = 'backend'

    public services: any

    public options!: Required<CacheOptions>

    public storage: any

    public storages: Record<string, any> = {}

    constructor(services: any, options: CacheOptions = {}) {
        this.init(services, options)
    }

    init(services: any, options = {}): void {
        this.services = services
        this.options = { ...getDefaults(), ...this.options, ...options }

        // this.storage = localforage.createInstance(this.options)
    }

    read(language: string, namespace: string, callback: ReadCallback): void {
        const nowMS = new Date().getTime()

        if (!this.storages[language]) {
            this.storages[language] = localforage.createInstance({ ...this.options, storeName: `${this.options.prefix}${language}` })
        }

        this.storages[language].getItem(`${language}-${namespace}`).then((local: any) => {
            const version = this.getVersion(language)
            if (local && local.i18nStamp && local.i18nStamp + this.options.expirationTime > nowMS && version === local.i18nVersion) {
                delete local.i18nVersion
                delete local.i18nStamp
                return callback(null, local)
            }
            return callback(null, null)
        })
    }

    save(language: string, namespace: string, data: any): Promise<void> {
        data.i18nStamp = new Date().getTime()

        // language version (if set)
        const version = this.getVersion(language)
        if (version) {
            data.i18nVersion = version
        }

        // save
        return this.storages[language] && this.storages[language].setItem(`${language}-${namespace}`, data)
    }

    getVersion(language: string) {
        return this.options.versions[language] || this.options.defaultVersion
    }
}

export default Cache
