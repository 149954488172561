import './CustomToast.module.scss'

import { Spin } from '@douyinfe/semi-ui'
import { Toast, ToastShowProps } from 'antd-mobile'
import { AlertCircle, CheckCircle, CloseCircle } from 'react-dui-icons'

// 继承自semi的参数，这些是无效的，只是为了迁移时方便，兼容之前的格式
type CustomToastShowProps = ToastShowProps & {
    showClose?: boolean
    id?: string
}
// 自定义toast，跟信用卡业务的一样，但不混用
// 改用antd-mobile，semi在移动端支持不好
export const CustomToast = {
    info: (props: CustomToastShowProps) => {
        Toast.show({
            maskClickable: false,
            duration: 3000,
            ...props
        })
    },
    success: (props: CustomToastShowProps) => {
        Toast.show({
            maskClickable: false,
            duration: 3000,
            ...props,
            icon: (
                <CheckCircle
                    size={48}
                    style={{ color: 'var(--color-success-default)' }}
                />
            )
        })
    },
    warning: (props: CustomToastShowProps) => {
        Toast.show({
            maskClickable: false,
            duration: 3000,
            ...props,
            icon: (
                <AlertCircle
                    size={48}
                    style={{ color: 'var(--color-warning-default)' }}
                />
            )
        })
    },
    error: (props: CustomToastShowProps) => {
        Toast.show({
            maskClickable: false,
            duration: 3000,
            ...props,
            icon: (
                <CloseCircle
                    size={48}
                    style={{ color: 'var(--color-danger-default)' }}
                />
            )
        })
    },
    loading: (props: CustomToastShowProps) => {
        Toast.show({
            ...props,
            maskClickable: false,
            icon: <Spin size="large" />
        })
    },
    close: () => {
        Toast.clear()
    }
}

// 自定义用
export const CommonToastCustom = (props: CustomToastShowProps) => {
    return Toast.show({
        ...props
    })
}
