import React from 'react'

const UpArrow = /* #__PURE__ */ (function () {
    const component = function (props: any) {
        const { color, size, ...otherProps } = props
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color={color}
                {...otherProps}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.2661 6.04492L20.2661 4.04492L18.2661 4.04492L5.26611 4.04492V8.04492L13.4377 8.04492L2.3203 19.1623L5.14873 21.9907L16.2661 10.8733L16.2661 19.0449L20.2661 19.0449L20.2661 6.04492Z"
                    fill={color}
                />
            </svg>
        )
    }

    component.defaultProps = {
        color: '#21CCA3',
        size: '24'
    }

    return component
})()

export default UpArrow
