import { post } from 'utils'
export interface ApiThirdCaptchaInterface {
    api: 'third/check_send_captcha'
    method: 'post'
    params: {
        captchaId: string
        countryCode?: string
        /** 第三方类型 1是电报，2是apple,3是google */
        thirdType: 1 | 2 | 3
        account: string
    }
    return: {
        /** 1是可以注册新用户，2是可以绑定原有账号 */
        checkResult: 1 | 2
        /** 是否新用户 1=是 0=否 */
        isSend: boolean
    }
}
export const ApiThirdCaptcha = async (params: ApiThirdCaptchaInterface['params']) => {
    return await post<ApiThirdCaptchaInterface>('third/check_send_captcha', { ...params })
}
