import { Button, Dropdown, Skeleton, Space } from '@douyinfe/semi-ui'
import { useRequest } from 'ahooks'
import { ApiGetAssetsTotal } from 'api/assets/ApiGetAssetsTotal'
import Option from 'components/Icons/icons/Option'
import P2PTrading from 'components/Icons/icons/P2PTrading'
import Spot from 'components/Icons/icons/Spot'
import { domain, locale } from 'config'
import { useCheckSubAccount } from 'hooks'
import React, { useState } from 'react'
import { Deposit2, Eye, EyeOff, Withdraw } from 'react-dui-icons'
import { useNavigate } from 'react-router-dom'
import { formatNum } from 'utils'
import { RMBToUSD } from 'utils/rate'
import { useMyBalanceStore } from 'views/User/MyBalance/store'

import style from './Balance.module.scss'

export const Balance = (): JSX.Element => {
    // 子账户新增业务：屏蔽 充币/提币 按钮
    const [total, setTotal] = useState('')
    const navigator = useNavigate()
    const isSub = useCheckSubAccount()
    const { hidden, setHidden } = useMyBalanceStore()

    const { loading } = useRequest(ApiGetAssetsTotal, {
        onSuccess: res => {
            setTotal(res.totalUsdtEstimation)
        }
    })

    const redirect = (path: string) => {
        window.location.href = `https://www.${domain}${path}`
    }

    return (
        <section className={style.root}>
            <div
                className={style.overview}
                onClick={e => {
                    e.stopPropagation()
                    navigator(`/${locale}/user/overview`)
                }}
            >
                <Space
                    spacing={8}
                    className={style.title}
                >
                    <span>{t('Assets Overview')}</span>
                    {hidden ? (
                        <EyeOff
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()
                                setHidden(!hidden)
                            }}
                        />
                    ) : (
                        <Eye
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()
                                setHidden(!hidden)
                            }}
                        />
                    )}
                </Space>
                <Space spacing={4}>
                    <Skeleton
                        loading={loading}
                        placeholder={<Skeleton.Title style={{ width: 200, height: 24, marginBottom: 2 }} />}
                    >
                        <h4>{hidden ? '******' : formatNum(total, 2)}</h4>
                    </Skeleton>
                    <span>USDT</span>
                </Space>
                <Space>
                    <Skeleton
                        loading={loading}
                        placeholder={<Skeleton.Title style={{ width: 160, height: 18 }} />}
                    >
                        <span>{hidden ? '******' : `≈${RMBToUSD(total, 2, 'USDT')}`}</span>
                    </Skeleton>
                </Space>
            </div>
            <div className={style.tip}>{t('*data maybe delayed.')}</div>
            {!isSub && (
                <div className={style.group}>
                    <Button
                        className={style.btn}
                        style={{ marginRight: '8px' }}
                        onClick={() => navigator(`/${locale}/deposit`)}
                    >
                        <Deposit2 size={16} />
                        {t('Deposit') /** 充币 */}
                    </Button>
                    <Button
                        className={style.btn}
                        onClick={() => navigator(`/${locale}/withdraw`)}
                    >
                        <Withdraw size={16} />
                        {t('Withdraw') /** 提币 */}
                    </Button>
                </div>
            )}

            <Dropdown.Menu>
                <Dropdown.Divider />
                <Dropdown.Title>{t('Account')}</Dropdown.Title>
                <Dropdown.Item onClick={() => navigator(`/${locale}/user/mybalance/spot`)}>
                    <Space>
                        <Spot size={24} />
                        <p>{t('Spot Account')}</p>
                    </Space>
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={() => navigator(`/${locale}/n/user/mybalance/margin`)}>{t('Margin Account')}</Dropdown.Item>
                <Dropdown.Item onClick={() => navigator(`/${locale}/n/user/mybalance/wealth`)}>{t('Wealth Account')}</Dropdown.Item> */}
                <Dropdown.Item onClick={() => navigator(`/${locale}/user/mybalance/otc`)}>
                    <Space>
                        <P2PTrading size={24} />
                        <p>{t('P2P Account')}</p>
                    </Space>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigator(`/${locale}/user/mybalance/futures`)}>
                    <Space>
                        <Option size={24} />
                        <p>{t('Futures Account')}</p>
                    </Space>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => redirect(`/${locale}/user/log`)}>{t('Financial Log')}</Dropdown.Item>
            </Dropdown.Menu>
        </section>
    )
}
