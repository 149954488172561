import React from 'react'

const P2PTrading = /* #__PURE__ */ (function () {
    const component = function (props: any) {
        const { color, size, ...otherProps } = props
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color={color}
                {...otherProps}
            >
                <path
                    d="M3 16V16.0702C3 18.7928 5.20717 21 7.92984 21V21C7.96548 21 7.99163 20.9665 7.98298 20.9319L7.5 19"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <circle
                    cx="17.25"
                    cy="14.4951"
                    r="2.25"
                    stroke="currentColor"
                    strokeWidth="1.5"
                />
                <path
                    d="M12.8202 20.8738C13.2036 18.5123 15.0812 16.7998 17.25 16.7998C19.4188 16.7998 21.2964 18.5123 21.6798 20.8738C21.6826 20.8909 21.6805 20.9 21.6786 20.9058C21.6762 20.9131 21.6705 20.925 21.6569 20.9393C21.6279 20.9698 21.5728 20.9998 21.5 20.9998H13C12.9272 20.9998 12.8721 20.9698 12.8431 20.9393C12.8295 20.925 12.8238 20.9131 12.8214 20.9058C12.8195 20.9 12.8174 20.8909 12.8202 20.8738Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21 10V10C21 7.23858 18.7614 5 16 5V5L16.5 7"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle
                    cx="7.25"
                    cy="4.74512"
                    r="2.25"
                    stroke="currentColor"
                    strokeWidth="1.5"
                />
                <path
                    d="M2.82021 11.1238C3.20357 8.76233 5.08116 7.0498 7.25 7.0498C9.41884 7.0498 11.2964 8.76233 11.6798 11.1238C11.6826 11.1409 11.6805 11.15 11.6786 11.1558C11.6762 11.1631 11.6705 11.175 11.6569 11.1893C11.6279 11.2198 11.5728 11.2498 11.5 11.2498H3C2.92716 11.2498 2.87211 11.2198 2.8431 11.1893C2.8295 11.175 2.82377 11.1631 2.8214 11.1558C2.81949 11.15 2.81745 11.1409 2.82021 11.1238Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )
    }

    component.defaultProps = {
        color: '',
        size: '24'
    }

    return component
})()

export default P2PTrading
