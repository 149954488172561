import FingerprintJS from '@fingerprintjs/fingerprintjs'
import md5 from 'blueimp-md5'
import { cookieOptions } from 'config/common.config'
import { locale } from 'config/locale.config'
import { enc, HmacSHA1 } from 'crypto-js'
import { Base64 } from 'js-base64'
import Cookies from 'js-cookie'
import { sortBy } from 'lodash'
import qs from 'qs'

import { checkLogin } from './checkLogin'
import { parseToken } from './token'
import { getSystem } from './tools'

const { Hex } = enc

export interface AuthHeaders {
    lang: string
    'os-type': 'PCWEB' | 'MOBIWEB' | 'IOS' | 'ANDROID'
    'device-uuid': string
    nonce: string
    timestamp: string
    sign?: string
    'access-token'?: string
    'show-uid'?: string
    dcver: '1.0'
    dcsc: string
    dcts: string
}

/**
 * 获取Auth
 * @param data 需要加密的数据
 */

export async function getAuth(data: Record<string, any>): Promise<AuthHeaders> {
    const timestamp = String(Math.floor(Date.now() / 1000))
    const osType = getSystem() ? 'MOBIWEB' : 'PCWEB'
    const deviceUuid = Cookies.get(Base64.encode('DGUUID')) ?? (await generateUUID())

    // 第二期 deny cc
    const dcver = '1.0'
    const dcts = timestamp
    const dcsc = md5(deviceUuid + dcts + 'UKIH2uxyXZDbZYbF9jFq4KSrei9cxEGV')

    // 设置headers
    const nonce = Math.random()?.toString(36)?.slice(2)
    const headers: AuthHeaders = {
        lang: locale,
        'os-type': osType,
        'device-uuid': deviceUuid,
        nonce,
        timestamp,
        dcver,
        dcts,
        dcsc
    }

    // 未登录情况默认走未授权通道
    if (!checkLogin()) {
        return headers
    }

    // 携带access-token
    const { access_token: accessToken, access_key: accessKey } = parseToken() // eslint-disable-line

    // 签名
    const showUid = Cookies.get('m_showID')
    const extendData = {
        nonce,
        timestamp,
        show_uid: showUid // eslint-disable-line
    }
    const sign = cryptoData({ ...data, ...extendData }, accessKey)
    return {
        ...headers,
        sign,
        'access-token': accessToken,
        'show-uid': showUid
    }
}

/** 调用js指纹，生成唯一uuid并写入cookie */
async function generateUUID() {
    const DGUUID = Base64.encode('DGUUID')
    let uuid = Cookies.get(DGUUID)

    if (!uuid) {
        const fp = await FingerprintJS.load({ monitoring: false })
        const result = await fp.get()

        // 上面两步是异步过程，此时可能已经从app拿到cookie
        const currentUuid = Cookies.get(DGUUID)
        if (!currentUuid) {
            /** 前24位为指纹id 后8位为随机数md5大写 */
            const DGUUIDCookie = result.visitorId.substring(0, 24) + md5(Math.random()?.toString(36)?.substr(2)).slice(2, 10).toUpperCase()
            Cookies.set(DGUUID, DGUUIDCookie, { ...cookieOptions, expires: 365 })
            uuid = DGUUIDCookie
        } else {
            uuid = currentUuid
        }
    }

    return uuid
}

/**
 * 对请求数据进行加密
 * @param {Object} data [公共对象]
 * @param {String} key [access_key]
 */
function cryptoData(data: AnyObj, key: string) {
    const strArrs = qs.stringify(data, { encode: false }).split('&')
    const str = sortBy(strArrs, item => item.split('=')[0]).join('&')
    return HmacSHA1(str, key)?.toString(Hex)
}
