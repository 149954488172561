import { SideSheet } from '@douyinfe/semi-ui'
import { useState } from 'react'
import { Menu as MenuIcon } from 'react-dui-icons'

import ThemeSwitch from '../ThemeSwitch/ThemeSwitch'
import style from './HeaderMenu.module.scss'
import HeaderMenuSide from './HeaderMenuSide'

const HeaderMenu = (props: { navMenu?: any[] }): JSX.Element => {
    const { navMenu = [] } = props
    const [visible, setVisible] = useState(false)
    const change = () => {
        setVisible(!visible)
    }

    const titleContent = (
        <div className={style.title}>
            <ThemeSwitch />
        </div>
    )

    return (
        <section className={style.root}>
            <MenuIcon onClick={() => change()} />

            <SideSheet
                title={titleContent}
                className={style.navDrawer}
                visible={visible}
                style={{ backgroundColor: 'var(--color-bg-1)' }}
                onCancel={() => change()}
                width={'320px'}
                zIndex={1080}
            >
                <HeaderMenuSide navMenu={navMenu} />
            </SideSheet>
        </section>
    )
}

export default HeaderMenu
