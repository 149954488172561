import { cookieOptions } from 'config/common.config'
import Cookies from 'js-cookie'

/**
 * 设置cookie
 * @param key string
 * @param value string
 * @param expires 默认为 不传临时存储 其他传天 number
 */
export const setCookie = (key: string, value: string, expires?: number) => {
    const expiresTime = new Date(Number(new Date()) + 10 * 1000)
    Cookies.set(key, value, {
        ...cookieOptions,
        expires: expires || expiresTime
    })
}

let getCookieId: NodeJS.Timeout | undefined
let getCookieTemp = {} as Record<any, string>
/** 获取存储在cookie中的currency，在2秒内多次调用将返回缓存值 */
export const getCookie = (key: string) => {
    // 如果不在倒计时，或正在倒计时，但该key没有缓存值，则从cookie中获取
    if (!getCookieId || !getCookieTemp[key]) {
        getCookieTemp[key] = Cookies.get(key) || ''
    }

    if (!getCookieId) {
        getCookieId = setTimeout(() => {
            getCookieId = undefined
            getCookieTemp = {}
        }, 2000)
    }

    return getCookieTemp[key]
}
