import { IconHome } from '@douyinfe/semi-icons'
import React from 'react'

export type RouterItem = {
    name: string
    path: string
    children?: Array<RouterItem>
    component?: Function
    hidden?: boolean
    meta?: {
        title: string
        icon?: JSX.Element
        /** 允许访问的权限 如 代理商/子帐号 等，如只有一级则传 【'user'】 */
        permission?: Array<string>
    }
}

const asyncRouters: Array<RouterItem> = [
    {
        path: '/user',
        name: 'user',
        component: React.lazy(() => import('views/User')),
        meta: { title: '个中心', icon: <IconHome />, permission: ['user'] }
    }
]

export { asyncRouters }
