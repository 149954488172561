// 首页获取所有版本号和链接接口
import { get } from 'utils'

interface ApiVersionData {
    versionNum: string
    downloadUrl: string
}

export interface ApiVersionAllType {
    api: 'version/all'
    method: 'get'
    return: {
        android: ApiVersionData
        win: ApiVersionData
        mac: ApiVersionData
    }
}

export const ApiVersionAll = async () => {
    return await get<ApiVersionAllType>('version/all')
}
