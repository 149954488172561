import { ApiGetLang, ApiRateList, LangItem } from 'api'
import { ApiLang } from 'api/common'
import { locale } from 'config'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { checkLogin, fetchRateList, langList as staticLangList, storage } from 'utils'
import { getCookie, setCookie } from 'utils/cookie'

export const useLanguageList = (): {
    languageList: LangItem[]
    rateList: ApiRateList['return']
    currentLanguage: LangItem | undefined
    currentFiat: ApiRateList['return'][number]
    onFiatChange: (fiat: ApiRateList['return'][number]) => void
} => {
    const [languageList, setLanguageList] = useState<LangItem[]>((storage.getItem('langList', 'session', 'common') as LangItem[]) || [])
    const [rateList, setRateList] = useState<ApiRateList['return']>([])
    const [currentFiat, setCurrentFiat] = useState<ApiRateList['return'][number]>({
        lang: '',
        abbr: '',
        name: '',
        symbol: '',
        rate: '',
        usdtRate: ''
    })
    const sessionLogin = storage.getItem('loginStatus', 'session')

    const formatList = (list: LangItem[]) => {
        return list?.map(item => ({
            ...item,
            name: item.name + (item.isGoogle === 1 ? '*' : '')
        }))
    }

    const onError = () => {
        const sessoion = storage.getItem('langList', 'session', 'common')
        if (sessoion?.length === 0) {
            setLanguageList(staticLangList)
        } else {
            setLanguageList(sessoion)
        }
    }

    const onFiatChange = (fiat: ApiRateList['return'][number]): void => {
        const currency = getCookie('currency')
        const sessionRateList = storage.getItem('rateList', 'session', 'common')
        if (currency === fiat?.abbr) return
        setCookie('currency', fiat?.abbr)
        setCookie('rate', fiat.rate, 1)
        setCookie('usdtRate', sessionRateList[fiat?.abbr]?.usdtRate ?? 0, 1)

        window.location.reload()
    }

    const getLanguageList = async () => {
        try {
            const res: ApiLang['return'] = await ApiGetLang()
            if ((res as LangItem[])?.length || !_.isEmpty(res)) {
                const list = formatList(res as LangItem[])
                storage.setItem('langList', list, 'session', 'common')
                setLanguageList(list)

                // 白名单语言选中退出后跳回en-ww
                const cur = (res as LangItem[]).find(({ code }) => code === locale)
                if (!cur && locale !== 'ca-ca') {
                    window.location.href = window.location.href.replace(locale, 'en-ww')
                }
            } else {
                onError()
            }
        } catch {
            onError()
        }
    }

    const currentLanguage = useMemo(() => {
        return languageList?.find(item => {
            return item.code === locale
        })
    }, [languageList, location?.pathname])

    useEffect(() => {
        getLanguageList()
        fetchRateList((res: ApiRateList['return']) => {
            setRateList(_.uniqBy(res, 'abbr'))
        })
    }, [])

    useEffect(() => {
        storage.setItem('loginStatus', checkLogin(), 'session')
        if (sessionLogin !== checkLogin() || languageList?.length === 0) {
            getLanguageList()
            fetchRateList((res: ApiRateList['return']) => {
                setRateList(_.uniqBy(res, 'abbr'))
            })
        }
    }, [sessionLogin, languageList, checkLogin()])

    useEffect(() => {
        const fiat = getCookie('currency')
        if (rateList?.length) {
            const cur = rateList.filter(item => item?.abbr === fiat)?.length ? rateList.filter(item => item?.abbr === fiat)[0] : rateList.filter(item => item?.lang === locale)[0]

            setCurrentFiat(cur)
        }
    }, [rateList])

    return { languageList, rateList, currentFiat, currentLanguage, onFiatChange }
}
