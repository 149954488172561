import { DropDownContainer } from 'components'
import { ReactNode } from 'react'
import { User as UserIcon } from 'react-dui-icons'

import { User } from './User'

export interface UserCenterDropDownPropsData {
    name: string
    to: string
    icon?: ReactNode
}

export const UserDropDown = (): JSX.Element => {
    return (
        <DropDownContainer
            content={<User />}
            trigger="hover"
            zIndex={1080}
        >
            <UserIcon size={24} />
        </DropDownContainer>
    )
}
