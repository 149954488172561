import { webName } from 'config/domain.config'
import { useCallback, useEffect, useState } from 'react'

export type StorageType = 'local' | 'session'
type StorageFullType = 'localStorage' | 'sessionStorage'

const defaultType = 'local'
const actionHooks = { local: 'localStorage', session: 'sessionStorage' }

/**
 * 获取全部数据
 * @param type locale localStorage存储 session sessionStorage存储
 * @param dataType all 返回整张存储数据 current 返回当前项目存储数据
 * @param projectName 覆盖默认的projectName
 */
const getData: (type: StorageType, dataType?: 'all' | 'current', projectName?: string) => any = (type, dataType = 'current', curName = webName) => {
    const action = actionHooks[type] as StorageFullType
    const webData = JSON.parse(window[action].getItem(webName) || '{}')
    return dataType === 'current' ? webData[curName] || {} : webData
}

/**
 * 获取name存储数据
 * @param name 存储名
 * @param type locale localStorage存储 session sessionStorage存储
 * @param projectName 覆盖默认的projectName
 */
const getItem: <T = any>(name: string, type?: StorageType, projectName?: string) => T | void = (name, type = defaultType, curName = webName) => getData(type, 'current', curName)[name]

/**
 * 存储数据
 * @param name 存储名
 * @param data 存储数据
 * @param type locale localStorage存储 session sessionStorage存储
 * @param projectName 覆盖默认的projectName
 */
const setItem: (name: string, data: any, type?: StorageType, projectName?: string) => AnyObj = (name, data, type = defaultType, curName = webName) => {
    const action = actionHooks[type] as StorageFullType

    const webData = getData(type, 'all')
    const projectData = { ...webData[curName], [name]: data }

    const str = JSON.stringify({ ...webData, [curName]: projectData })
    window[action].setItem(webName, str)

    return projectData
}

/**
 * 删除数据
 * @param name 存储名
 * @param type locale localStorage存储 session sessionStorage存储
 * @param projectName 覆盖默认的projectName
 */
const delItem: (name: string, type?: StorageType, projectName?: string) => any = (name, type = defaultType, curName = webName) => {
    const action = actionHooks[type] as StorageFullType

    const { [curName]: projectData, ...otherData } = getData(type, 'all')
    const { [name]: removeData, ...otherProjectData } = projectData || {}
    const data = { ...otherData, [curName]: otherProjectData }

    window[action].setItem(webName, JSON.stringify(data))
    return removeData
}

export const storage = { getItem, setItem, delItem, getData }

function isCustomEvent<T>(event: Event): event is CustomEvent<T> {
    return 'detail' in event
}

export const useLocalStorage = <T extends {}>(name: string, initData: T, type: StorageType = 'local'): [T, (data: T, noUpdate?: boolean) => void] => {
    const data = storage.getItem(name, type)
    if (data === undefined) {
        storage.setItem(name, initData, type)
    }

    const [state, setState] = useState<T>(data === undefined ? initData : data)

    const setData = useCallback(
        (data: T, noUpdate = true): void => {
            storage.setItem(name, data, type)
            window.dispatchEvent(
                new CustomEvent<{ name: string; data: T }>('storagechange', {
                    detail: {
                        name,
                        data
                    }
                })
            )
            if (noUpdate) {
                setState(data)
            }
        },
        [name, type]
    )

    useEffect(() => {
        const storageChangeHandler = (e: StorageEvent) => {
            if (e.newValue === name && e.newValue !== e.oldValue) {
                const newDataStr = e.storageArea?.getItem(e.newValue)
                if (newDataStr) {
                    setState(JSON.parse(newDataStr))
                }
            }
        }
        const customStorageChangeHandler = (e: Event) => {
            if (isCustomEvent<{ name: string; data: T }>(e) && e.detail.name === name) {
                setState(e.detail.data)
            }
        }

        window.addEventListener('storage', storageChangeHandler)
        window.addEventListener('storagechange', customStorageChangeHandler)

        return () => {
            window.removeEventListener('storage', storageChangeHandler)
            window.removeEventListener('storagechange', customStorageChangeHandler)
        }
    }, [name])

    return [state, setData]
}
