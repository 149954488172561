import { Modal as SemiModal } from '@douyinfe/semi-ui'
import { ModalReactProps } from '@douyinfe/semi-ui/lib/es/modal'
import { useEffect } from 'react'

export const Modal = ({ children, ...props }: ModalReactProps): JSX.Element => {
    // 避免body有滚动条的情况下抖一下
    useEffect(() => {
        if (props.visible) {
            document.body.style.overflow = 'auto'
        }
    }, [props.visible])

    return <SemiModal {...props}>{children}</SemiModal>
}
