import loadable from '@loadable/component'
import { locale } from 'config'

import { type RouterItem } from './index'

const commonPath = '/:locale'
// 流程还没通
export const userRouter: Array<RouterItem> = [
    {
        name: 'user',
        path: `${commonPath}/user`,
        component: loadable(() => import('views/User/index')),
        children: [
            {
                name: 'index',
                path: '',
                redirectTo: `/${locale}/user/overview`
            },
            {
                name: 'overview',
                path: 'overview',
                component: loadable(() => import('views/User/Overview/Overview'))
            },
            {
                name: 'myBalance',
                path: 'mybalance/*',
                component: loadable(() => import('views/User/MyBalance/Mybalance'))
            },
            {
                name: 'log',
                path: 'log',
                component: loadable(() => import('views/User/Log/Log'))
            },
            {
                name: 'subaccount',
                path: 'subaccount/*',
                component: loadable(() => import('views/User/SubAccount/SubAccount'))
            },
            {
                name: 'security',
                path: 'security',
                component: loadable(() => import('views/User/Security/Security'))
            },
            {
                name: 'accountConnections',
                path: 'security/accountConnections',
                component: loadable(() => import('views/User/Security/pages/AccountConnections/AccountConnections'))
            },
            {
                name: 'phoneAuthentication',
                path: 'security/phoneAuthentication',
                component: loadable(() => import('views/User/Security/pages/PhoneAuthentication/PhoneAuthentication'))
            },
            {
                name: 'emailAuthentication',
                path: 'security/emailAuthentication',
                component: loadable(() => import('views/User/Security/pages/EmailAuthentication/EmailAuthentication'))
            },
            {
                name: 'googleAuthentication',
                path: 'security/googleAuthentication',
                component: loadable(() => import('views/User/Security/pages/GoogleAuthentication/GoogleAuthentication'))
            },
            {
                name: 'changePassword',
                path: 'security/changePassword',
                component: loadable(() => import('views/User/Security/pages/ChangePassword/ChangePassword'))
            },
            {
                name: 'generalPage',
                path: 'security/realNameVerification',
                component: loadable(() => import('views/User/Security/pages/RealNameVerificaty/pages/PersonalVerify/PersonalVerify'))
            },
            {
                name: 'keyStep',
                path: 'security/realNameVerification/kyc/:step',
                // component: loadable(() => import('views/User/Security/pages/RealNameVerificaty/pages/RealNameVerify/RealNameVerifyPage'))
                component: loadable(() => import('views/User/Security/pages/RealNameVerificaty/pages/GeneralPage/GeneralPage1'))
            },
            {
                name: 'bubmitSuccess',
                path: 'security/realNameVerification/success/:step',
                component: loadable(() => import('views/User/Security/pages/RealNameVerificaty/pages/SubmitSuccess/SubmitSuccess'))
            },
            {
                name: 'device',
                path: 'security/device',
                component: loadable(() => import('views/User/Security/pages/Device/Device'))
            },
            {
                name: 'antiPishingCode',
                path: 'security/antiPhisingCode',
                component: loadable(() => import('views/User/Security/pages/AntiPhishingCode/AntiPhishingCode'))
            },
            {
                name: 'address',
                path: 'security/address',
                component: loadable(() => import('views/User/Security/pages/Address/Address'))
            },
            {
                name: 'deleteAccount',
                path: 'security/deleteAccount',
                component: loadable(() => import('views/User/Security/pages/DeleteAccount/DeleteAccount'))
            },
            {
                name: 'api',
                path: 'api',
                component: loadable(() => import('views/User/Api/Api'))
            },
            {
                name: 'orders',
                path: 'orders/*',
                component: loadable(() => import('views/User/Orders/Orders'))
            },
            {
                name: 'audit',
                path: 'others/audit',
                component: loadable(() => import('views/User/Others/Audit/Audit'))
            },
            {
                name: 'queryData',
                path: 'others/queryData',
                component: loadable(() => import('views/User/Others/QueryData/QueryData'))
            },
            {
                name: 'VipProgram',
                path: 'others/vip',
                component: loadable(() => import('views/User/Others/Vip/Vip'))
            },
            {
                name: 'notification',
                path: 'notification',
                component: loadable(() => import('views/User/Notification/Notification'))
            },
            {
                name: 'notification',
                path: 'notification/:id',
                component: loadable(() => import('views/User/Notification/NotificationDetail'))
            }
        ]
    }
]
