import { locale } from 'config'
import _ from 'lodash'

import { getLocale } from './tools'

const returnLink = (locale: string, cnLink: string, enLink: string, krLink?: string) => {
    krLink = krLink ?? cnLink
    if (['zh-cn'].includes(locale)) {
        return cnLink
    }
    if (['ko-kr'].includes(locale)) {
        return krLink
    }
    return enLink
}

const _returnLink = _.partial(returnLink, getLocale())

export const fixZendeskLang = () => {
    let lang = 'en-us'
    switch (locale) {
        case 'zh-cn':
        case 'zh-hk':
            lang = 'zh-tw'
            break
        case 'ko-kr':
            lang = 'zh-tw'
            break
        default:
            break
    }
    return lang
}

export const zendesk: { [key: string]: string } = {
    helpCenter: _returnLink('https://parobit.zendesk.com/hc/zh-tw', 'https://parobit.zendesk.com/hc/en-us', 'https://parobit.zendesk.com/hc/ko'),
    privacyPolicy: _returnLink('https://parobit.zendesk.com/hc/en-us/articles/40534829617177', 'https://parobit.zendesk.com/hc/en-us/articles/40534829617177', 'https://parobit.zendesk.com/hc/ko/articles/40534829617177'),
    noticeBoard: _returnLink('https://parobit.zendesk.com/hc/zh-tw', 'https://parobit.zendesk.com/hc/en-us', 'https://parobit.zendesk.com/hc/en-us'),
    policies: _returnLink('https://parobit.zendesk.com/hc/en-us/articles/40534744821017', 'https://parobit.zendesk.com/hc/en-us/articles/40534744821017'),
    fee: _returnLink('https://parobit.zendesk.com/hc/en-us/articles/360000328422', 'https://parobit.zendesk.com/hc/en-us/articles/360000328422'),
    antiLaundry: _returnLink('https://parobit.zendesk.com/hc/en-us/articles/40534931024281', 'https://parobit.zendesk.com/hc/en-us/articles/40534931024281'),
    feedback: _returnLink('https://parobit.zendesk.com/hc/zh-cn/requests/new', 'https://parobit.zendesk.com/hc/en-us/requests/new')
}
