import { cookieOptions } from 'config/common.config'
import Cookies from 'js-cookie'
import { UAParser } from 'ua-parser-js'

import { AppHelper } from './appHelper'
import { clearCookies } from './checkLogin'

/**
 * H5嵌入APP端时，自动设置cookies
 */
export const setMobileCookies = (): void => {
    const type = new URLSearchParams(window.location.search).get('type')

    if (type?.toLocaleLowerCase() !== 'app') {
        return
    }
    console.log('hereh her eher erher ')

    // const fields = ['m_showID', 'm_access']
    // // 防止内嵌H5内登录后，从其他项目跳回本地项目后，又把Cookies清掉（特殊情况->新项目还没有上线自己的登录注册页）
    // if (fields.every(cookieName => Cookies.get(cookieName) !== undefined && Cookies.get(cookieName) !== '')) {
    //     return
    // }
    const parser = new UAParser()
    const device = parser.getOS().name

    // let AndToken: AnyObj = {}
    if (device === 'iOS' && AppHelper.ios) {
        AppHelper?.ios?.getToken?.({})
        window.setToken = (json: string): void => {
            const iosToken = JSON.parse(json || '{}')
            updateToken(iosToken)
        }
    }

    if (device === 'Android' && AppHelper?.android) {
        if (AppHelper?.android?.getToken()) {
            const androidToken = JSON.parse(AppHelper.android.getToken() || '{}')
            updateToken(androidToken)
        }
    }

    function updateToken(token: Record<string, string>): void {
        Object.keys(token).forEach(function (key) {
            Cookies.set(key, token[key], cookieOptions)
        })
        if (!token?.m_access || !token.m_showID) {
            clearCookies()
        }
        window.dispatchEvent(new CustomEvent('cookiechange'))
    }
}
