import { Button } from '@douyinfe/semi-ui'
import { ApiTokenCreate } from 'api'
import { CustomToast as Toast } from 'components/semi-ext/CustomToast/CustomToast'
import { TelegramUser } from 'components/TelegramLogin/TelegramLogin'
import { useWindowSize } from 'hooks'
import { FC } from 'react'
import { Telegram } from 'react-dui-icons'
import useLoginData from 'store/useLoginData'
import { pixelReport, post, storage } from 'utils'

import styles from './TelegramLoginBtn.module.scss'

export interface ITelegramLoginBtnProps {
    registerCb(userName: string, regId: string, regSign: string): any

    loginCb(token: ApiTokenCreate['return']): any

    agreement?: boolean
}

const TelegramLoginBtn: FC<ITelegramLoginBtnProps> = props => {
    const loginData = useLoginData(state => state)
    const { isMobile } = useWindowSize()
    const telegramLoginCallback = (user: TelegramUser) => {
        if (!user) {
            return
        }
        let userName = ''
        if (user.first_name || user.last_name) {
            const firstName = user.first_name ?? ''
            const lastName = user.last_name ?? ''
            userName = `${firstName} ${lastName}`
        } else {
            userName = user.username ?? 'unknown'
        }
        const subDomain = window.location.hostname.split('.')[0]
        // @ts-ignore
        post('third/telegram', {
            ...user,
            domainType: subDomain === 'dev' ? 'www' : subDomain
        }).then((res: any) => {
            storage.setItem('recent-used-third-party', 'telegram')
            if ('accessToken' in res) {
                props.loginCb(res)
            } else if ('newUser' in res) {
                props.registerCb(userName, res.regId, res.regSign)
            }
        })
    }
    const onClick = () => {
        if (!props.agreement) {
            Toast.warning({ content: t('You need to accept our terms and privacy policy to create an account '), showClose: false })
            return
        }
        pixelReport('CLICK_TG_BTN')
        const Telegram = (window as any).Telegram
        if (Telegram) {
            Telegram.Login.auth(
                {
                    bot_id: loginData.thirdParty?.teleBotId
                },
                (user: TelegramUser) => telegramLoginCallback(user)
            )
        } else {
            Toast.warning({ content: t('100063', { ns: 'errCode' }), showClose: false })
        }
    }
    return loginData.thirdParty && loginData.thirdParty.ipAllow && loginData.thirdParty.teleBotId ? (
        <Button
            size={'large'}
            block
            theme={'borderless'}
            className={styles.telegramLoginBtn}
            onClick={onClick}
        >
            <div className={styles.telegramLoginBtnContent}>
                <Telegram
                    className={styles.telegramLoginBtnContentIcon}
                    size={18}
                ></Telegram>{' '}
                {isMobile ? 'Telegram' : t('Countinue with Telegram')}
            </div>
        </Button>
    ) : null
}

export default TelegramLoginBtn
