import { Spin } from '@douyinfe/semi-ui'

import loading from './asset/loading_purple.gif'
import greenLoading from './asset/loading2.gif'

interface loadingProps {
    them?: 'purple' | 'green'
    style?: React.CSSProperties
}

export const DgLoading = ({ them = 'green', style = {} }: loadingProps): JSX.Element => {
    const loadingIcon: { [key: string]: string } = {
        purple: loading,
        green: greenLoading
    }
    const antIcon = (
        <img
            src={loadingIcon[them]}
            style={{ fontSize: 60 }}
        />
    )

    const mergedStyle: React.CSSProperties = {
        position: 'fixed',
        zIndex: 900,
        top: '0',
        left: '0',
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        margin: '-0.6rem',
        ...style
    }

    return (
        <div style={mergedStyle}>
            <Spin
                style={{ boxShadow: '0 0 15px #44444410', borderRadius: '10px', overflow: 'hidden' }}
                indicator={antIcon}
            />
        </div>
    )
}
