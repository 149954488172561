import { ApiFiatList, ApiRateList } from 'api/rate'
import BigNumber from 'bignumber.js'
import { locale } from 'config'
import { formatNum, get, storage } from 'utils'
import { getCookie, setCookie } from 'utils/cookie'

let isFetching = false

export const extraLang: { [key: string]: string } = {
    // 指定国家汇率
    'zh-hk': 'USD',
    'zh-cn': 'USD'
}

/* 存储汇率 */
export const fetchRateList = async (fn?: Function): Promise<number> => {
    isFetching = true
    const [rateListRes, fiatListRes] = await Promise.all([get<ApiRateList>('rate/rate_list'), get<ApiFiatList>('rate/fiat_list')])

    const rateList: { [key: string]: ApiRateList['return'][0] } = {}
    let currency = ''
    let rate = ''
    let usdtRate = ''

    rateListRes.forEach(item => {
        if (item.lang === locale && currency === '') {
            currency = item?.abbr
            rate = item?.rate
            usdtRate = fiatListRes.usdt2Fiat[item?.abbr.toLowerCase()] ?? '0'
        }

        rateList[item?.abbr] = { ...item, usdtRate: fiatListRes.usdt2Fiat[item?.abbr.toLowerCase()] ?? '0' }
    })

    if (extraLang?.[locale]) {
        const cur = rateList?.[extraLang[locale]]
        rate = cur?.rate
        currency = cur?.abbr
        usdtRate = cur?.usdtRate as string
    }
    const cookieCurrency = getCookie('currency')
    if (cookieCurrency && cookieCurrency !== '') {
        currency = cookieCurrency
    }
    setCookie('currency', currency, 1)
    setCookie('rate', rate, 1)
    setCookie('usdtRate', usdtRate, 1)
    storage.setItem('rateList', rateList, 'session', 'common')
    fn && fn(rateListRes, fiatListRes.usdt2Fiat)
    isFetching = false
    return +usdtRate
}

let getRateId: NodeJS.Timeout | undefined
let getRateTemp = 7.0

/** 获取本地存汇率字段，在2秒内多次调用将返回缓存值 */
const getRate = (): number => {
    if (!getRateId) {
        getRateId = setTimeout(() => {
            getRateId = undefined
        }, 60 * 1000 * 10)
        let result = 1
        const cookieRate = getCookie('usdtRate')
        if (cookieRate) {
            result = +cookieRate
        }

        const currency = getCookie('currency')
        const localRate = storage.getItem('rateList', 'session', 'common') || {}
        if (localRate[currency] && localRate[currency]?.usdtRate) {
            result = +localRate[currency]?.usdtRate
        }

        if (!isFetching && (window?.USDTList?.exprationTime ?? 0) < Date.now()) {
            fetchRateList()
        }

        getRateTemp = result

        return result
    } else {
        return getRateTemp
    }
}

/**
 * 计价货币转换为汇率
 * @param num 需要转换的值
 * @param len 显示位数
 * @param coin 当前转化的币种 默认为 'USDT'
 */
export const RMBToUSD: (num: number | string, len?: number | string, coin?: string) => string = (num, len = 2, coin = 'USDT') => {
    if (isNaN(Number(num))) return '--'
    const usdtRate = getRate() // 获取接口汇率
    const currency = getCookie('currency') || 'USD'
    const localRate = storage.getItem('rateList', 'session', 'common') || {}
    let symbol = '$'

    // console.log('coin', coin)

    if (localRate[currency]) {
        symbol = localRate[currency]?.symbol
    }

    // console.log('symbol', symbol)

    if (coin === 'CNY') {
        return (
            symbol +
            new BigNumber(+num)
                .div(+(localRate.CNY?.usdtRate ?? 6.4))
                .times(+usdtRate)
                .toFixed(+len, BigNumber.ROUND_DOWN)
        )
    } else {
        return (
            symbol +
            new BigNumber(+num)
                .times(window?.USDTList?.[coin] && window?.USDTList?.[coin] !== '' ? window?.USDTList?.[coin] : 1)
                .times(+usdtRate)
                .toFixed(Number(len), BigNumber.ROUND_DOWN)
        )
    }
}

/**
 * 计价货币转换为汇率
 * @param num 需要转换的值
 * @param len 显示位数
 * @param coin 当前转化的币种 默认为 'USDT'
 */
export const RMB2USD: (num: number | string, len?: number | string, coin?: string) => string = (num, len = 2, coin = 'USDT') => {
    if (isNaN(Number(num))) return '--'
    const usdtRate = getRate() // 获取接口汇率
    const currency = getCookie('currency') || 'USD'
    const localRate = storage.getItem('rateList', 'session', 'common') || {}
    let symbol = '$'

    if (localRate[currency]) {
        symbol = localRate[currency]?.symbol
    }

    if (coin === 'CNY') {
        return (
            symbol +
            formatNum(new BigNumber(+num)
                .div(+(localRate.CNY?.usdtRate ?? 6.4))
                .times(+usdtRate)
                .toFixed(+len, BigNumber.ROUND_DOWN), Number(len))
        )
    } else {
        return (
            symbol +
            formatNum(new BigNumber(+num)
                .times(window?.USDTList?.[coin] && window?.USDTList?.[coin] !== '' ? window?.USDTList?.[coin] : 1)
                .times(+usdtRate)
                .toFixed(Number(len), BigNumber.ROUND_DOWN), Number(len))
        )
    }
}

export const rateList = {
    USD: {
        abbr: 'USD',
        lang: 'tr-tk',
        name: 'Türkçe',
        rate: '6.87780000',
        symbol: '$',
        usdtRate: '0.999991'
    },
    HKD: {
        abbr: 'HKD',
        lang: 'zh-hk',
        name: '繁體中文',
        rate: '0.87615342',
        symbol: 'HK$',
        usdtRate: '7.860000'
    },
    IDR: {
        abbr: 'IDR',
        lang: 'id-id',
        name: 'Indonesia',
        rate: '0.00046054',
        symbol: 'Rp',
        usdtRate: '14465.880000'
    },
    EUR: {
        abbr: 'EUR',
        lang: 'es-es',
        name: 'Español',
        rate: '7.51068814',
        symbol: '€',
        usdtRate: '0.950909'
    },
    RUB: {
        abbr: 'RUB',
        lang: 'ru-ru',
        name: 'Pусский',
        rate: '0.08469153',
        symbol: '₽',
        usdtRate: '68.330000'
    },
    VND: {
        abbr: 'VND',
        lang: 'vi-vn',
        name: 'Tiếng Việt',
        rate: '0.00029323',
        symbol: '₫',
        usdtRate: '23677.000000'
    },
    THB: {
        abbr: 'THB',
        lang: 'th-th',
        name: 'ภาษาไทย',
        rate: '0.20157679',
        symbol: '฿',
        usdtRate: '34.400000'
    }
}
