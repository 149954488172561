import { get } from '../../utils'

export interface ApiMessageUnreadCountType {
    api: 'message/unread-count'
    method: 'get'
    return: {
        count: number // 未读消息数
    }
}

export const ApiGetMessageUnreadCount = async () => {
    return await get<ApiMessageUnreadCountType>('message/unread-count')
}
